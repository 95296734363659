import { useMsal } from "@azure/msal-react";

export default function useLoggedInUser() {
    const { instance } = useMsal();
    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {

        const idTokenClaims = activeAccount.idTokenClaims;

        // Check for roles in the claims (roles could be in `roles`, `role`, or `groups` depending on your Azure AD setup)
        const roles = idTokenClaims?.roles || idTokenClaims?.role || [];
        return { user:
                { name: activeAccount.name, username: activeAccount.username, loggedIn: true, roles: roles }
        }
    } else {
        return { user: { name: '', username: '', loggedIn: false, roles: [] } }
    }

}
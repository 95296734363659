import React from 'react'
import styled from 'styled-components'
import Logic from './Logic'

export default function FilterLogic({filter, configStateSetter, employeeFilter, filterIndex, colors ,reportPage, sheetPage}) {

  return (
    <ConditionDiv>
        {filter.fields.map((field, fieldIndex) => {
            return (
                <FiltersDiv>
            <Logic field={field} fieldIndex={fieldIndex} employeeFilter={employeeFilter} conditionIndex={filterIndex} colors={colors} reportPage={reportPage} sheetPage={sheetPage} configStateSetter={configStateSetter}/>
                <FilterText colors={colors}>and</FilterText>
            </FiltersDiv>
       ) 
       })}
    </ConditionDiv>
  )
}
const ConditionDiv = styled.div`

`

const FiltersDiv = styled.div`
display: flex;
flex-direction: column;

`
const FilterText = styled.div`
margin: 10px auto 5px auto;
color: ${props => props.theme.colors.primary};
`
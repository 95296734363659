import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import styled from 'styled-components';

export default function CheckboxField({setOption, checked, label, attName, index, margin}) {
  const themeContext = useContext(ThemeContext)
    const handleCheck = (checked) => {
        setOption(checked, index, attName)
    }

    return (
        <CheckboxDiv margin={margin} >
          
          <Checkbox
          checked={checked}
          onChange={e => handleCheck(e.target.checked)}
           sx={{
              color: themeContext.colors.contrastBlue,
              transition: 'color 0.4s ease-out',
              '&.Mui-checked': {
                color: themeContext.colors.contrastBlue,
              },
            }}
           />
           {label}
      </CheckboxDiv>
    )

}

const CheckboxDiv = styled.div`
    text-align: left;
    color: ${props => props.theme.colors.primary};
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: ${props => props.margin ? props.margin : ''};
`
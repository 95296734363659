import React, {useState} from 'react'
import styled from 'styled-components'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import BasicButton from '../SharedComponents/BasicButton'

export default function CopyFields({sheets, copyFieldsFromSheet, showCopyFields}) {
    const [fromSheet, setFromSheet] = useState({label: "Select sheet", value: ""})
    const [toSheet, setToSheet] = useState({label: "Select sheet", value: ""})
    
    const updateSheetToCopyFrom = (value) => {
        setFromSheet(value)
    }

    const updateSheetToCopyTo = (value) => {
        setToSheet(value)
    }

    const sheetOptions = () => {
        const sheetOptionsArr = []
        sheets.map((sheet, index) => {
            sheetOptionsArr.push({label: sheet.name, value: index})
        })

        return sheetOptionsArr
    }

    
    if (!showCopyFields) {
        return null
    }
  return (
    <Container>
        <HeaderDiv>Copy fields</HeaderDiv>
        <SelectField width="180px" label="From sheet" options={sheetOptions()} fieldValue={fromSheet} handleSelect={updateSheetToCopyFrom} margin="0 auto 0 auto" />
        <SelectField width="180px" label="To sheet" options={sheetOptions()} fieldValue={toSheet} handleSelect={updateSheetToCopyTo} margin="20px auto 0 auto" />

        <BasicButton background="primary" label="Copy" width="100px" height="35px" margin="auto auto 20px auto" handleClick={() => copyFieldsFromSheet(fromSheet.value, toSheet.value)} />
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 15%;
    left: 39%;
    width: 30%;
    height: 27%;
    z-index: 1100000;
    background: ${props => props.theme.colors.grey};
    border-radius: 30px;
`
const HeaderDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    text-align: center;
    margin: 10px auto 30px auto;
    font-size: 1.5em;
    display: inline;
`
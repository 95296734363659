import React, { useState } from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import { useNavigate } from "react-router-dom";
import { useHookstate } from '@hookstate/core';
import { importSchemaState } from '../../States/importSchemaState';
import SelectField from '../SharedComponents/SelectlFieldToSplit';
import BasicButton from '../SharedComponents/BasicButton';
import useIntegrationStates from '../../hooks/useIntegrationStates';

export default function MapImportSchema() {
    const {configStateGetter, configStateSetter, c1StateGetter, custotmerStateGetter, originalTemplateGetter, integrationType} = useIntegrationStates()
    const colors = colorScheme.night
    const importSchemaStateSetter = useHookstate(importSchemaState)
    const importSchemaStateGetter = importSchemaStateSetter.get()
    const initialTemplatePage = integrationType === "deltareport" ? { label: "Report 1", value: 0 } : { label: Object.keys(originalTemplateGetter.selectedFields)[0], value: Object.keys(originalTemplateGetter.selectedFields)[0] }
    const [templatePage, setTemplatePage] = useState(initialTemplatePage)
    const intitialFieldSchema = integrationType === "deltareport" ? { label: "c1headers", value: "c1headers" } : { label: Object.keys(originalTemplateGetter.fields[templatePage.value])[0], value: Object.keys(originalTemplateGetter.fields[templatePage.value])[0] }
    const [fieldSchema, setFieldSchema] = useState(intitialFieldSchema)
    const [excelSheet, setExcelSheet] = useState(Object.keys(importSchemaStateGetter.data)[0])
    let dataFromExcel = importSchemaStateGetter.data[excelSheet]
    let headersFromExcel = importSchemaStateGetter.fieldsFromExcel[excelSheet]
    const templatePages = []
    const fieldSchemas = []
    let hasMultipleFieldsSchemas = true
    if (integrationType !== "deltareport") {
        hasMultipleFieldsSchemas = Array.isArray(originalTemplateGetter.fields[templatePage.value])
    }
    if (integrationType !== "deltareport") {
        Object.keys(originalTemplateGetter.selectedFields).map(item => { templatePages.push({ label: item, value: item }) })
        Object.keys(originalTemplateGetter.fields[templatePage.value]).map(item => { fieldSchemas.push({ label: item, value: item }) })
    } else {
        templatePages.push({ label: "Report 1", value: 0 })
        templatePages.push({ label: "Report 2", value: 1 })
        templatePages.push({ label: "Report 3", value: 2 })
        templatePages.push({ label: "Report 4", value: 3 })
    }

    const navigate = useNavigate()
    const fieldOptions = importSchemaStateGetter.fieldOptions
    const schemaFields = JSON.parse(JSON.stringify(headersFromExcel))
    const sheetOptions = Object.keys(importSchemaStateGetter.data).map(item => { return { label: item, value: item } })

    const updateField = (choice, index, attName) => {
        if (choice) {
            const fieldIndex = schemaFields.findIndex(x => x.label === choice.label);
            schemaFields[fieldIndex].field = attName
            importSchemaStateSetter.fieldsFromExcel[excelSheet].set(schemaFields)
        }
        const fieldIndex = schemaFields.findIndex(x => x.label === choice.label);
        schemaFields[fieldIndex].field = attName
        importSchemaStateSetter.fieldsFromExcel[excelSheet].set(schemaFields)
    }

    const setSheet = (choice) => {
        if (choice) {
            setExcelSheet(choice.value)

        }
    }
    const updateSchema = () => {

        console.log(configStateSetter)
        let sourceFields = integrationType === "deltareport" ? [] : JSON.parse(JSON.stringify(originalTemplateGetter.fields[templatePage.value]))
        if ( integrationType !== "deltareport" && !Array.isArray(originalTemplateGetter.fields[templatePage.value])) {
            try {
                sourceFields = JSON.parse(JSON.stringify(originalTemplateGetter.fields[templatePage.value][fieldSchema.value]))

            } catch {

            }
        } let eventTypes = []
        if (integrationType !== "deltareport" && 'eventTypes' in originalTemplateGetter.fields) {
            eventTypes = JSON.parse(JSON.stringify(originalTemplateGetter.fields.eventTypes))
        }

        const c1Fields = JSON.parse(JSON.stringify(c1StateGetter.c1Fields))
        const selectedFields = []
        dataFromExcel.map(item => {
            const templateField = JSON.parse(JSON.stringify(originalTemplateGetter.fieldTemplate))
            schemaFields.map(field => {
                if (field.field) {
                    if (field.field === 'c1Field') {
                        const c1Field = c1Fields.find((element) => element.value === item[field.value]);
                        if (c1Field) {
                            templateField[field.field] = c1Field
                            if (fieldSchema.value === "c1headers") {
                                templateField['sourceField'] = { label: c1Field.label, value: c1Field.label }
                            }
                        }
                    } else if (field.field === 'sourceField') {
                        if (integrationType === "deltareport" && item[field.value]) {
                            templateField.columnName = item[field.value]
                        } else {
                        const sourceField = sourceFields.find((element) => element.value === item[field.value]);
                        if (sourceField) {
                            templateField[field.field] = sourceField
                        }
                    }
                    } else if (field.field === 'validFrom') {
                        const validFrom = item[field.value].toLowerCase() === 'yes'
                        templateField[field.field] = validFrom
                    } else if (field.field === 'plannedChange') {
                        const plannedChange = item[field.value].toLowerCase() === 'yes'
                        templateField[field.field] = plannedChange
                    } else if (field.field === 'alwaysInclude') {
                        const alwaysInclude = item[field.value].toLowerCase() === 'yes'
                        templateField[field.field] = alwaysInclude
                    } else if (field.field === 'valueType') {
                        if (item[field.value] === 'alternativeExportValue') {
                            templateField[field.field] = { label: 'Alternative export value', value: 'alternativeExportValue' }
                        } else if (item[field.value] === 'uniqueImportId') {
                            templateField[field.field] = { label: 'Unique import Id', value: 'uniqueImportId' }
                        } else if (item[field.value] === 'validFrom') {
                            templateField[field.field] = { label: 'Valid from', value: 'value', 'validFrom': true }
                        }
                    } else if (field.field === 'eventType') {

                        const eventType = eventTypes.find((element) => element.value === item[field.value].toLowerCase());

                        if (eventType) {
                            templateField[field.field] = eventType
                        }
                    } else if (field.field === 'fieldType') {
                        let fieldType = "c1Field"
                        switch (item[field.value].toLowerCase()) {
                            case "hardcoded":
                                fieldType = "hardCoded"
                                break;
                            case "logic":
                                fieldType = "logic"
                                break;
                            case "concat":
                                fieldType = "concat"
                                break;
                            case "calculated":
                                fieldType = "calculated"
                                break;
                            case "dataprofile":
                                fieldType = "dataProfile"
                                break;
                            case "object":
                                fieldType = "object"
                                break;
                            case "manager":
                                fieldType = "manager"
                                break;
                            default:
                            // code block
                        }
                        templateField[field.field] = fieldType
                    } else if (field.field === 'hardCodedValue') {
                        templateField[field.field] = item[field.value]
                    }
                }
            })
            selectedFields.push(templateField)
        })
        if (integrationType === "deltareport") {
            if (templatePage.value !== 0) {
            const newReport = JSON.parse(JSON.stringify(originalTemplateGetter.reportTemplate))
            newReport.reportName = templatePage.label
            newReport.sheets[0].selectedFields = selectedFields
            const newReports = JSON.parse(JSON.stringify(configStateGetter.reports))
            newReports.push(newReport)
            configStateSetter.reports.set(newReports)
            } else {
                configStateSetter.reports[templatePage.value].sheets[0].selectedFields.set(selectedFields)
            }
            
        } else {
            if (!Array.isArray(originalTemplateGetter.fields[templatePage.value])) {
                configStateSetter.selectedFields[templatePage.value].fields.set(selectedFields)
            } else {
            configStateSetter.selectedFields[templatePage.value].set(selectedFields)
            }
        }
        

        navigate('/importschema/options')
    }

    return (
        <IntegrationsListContainer colors={colors}>
            <HeaderDiv colors={colors}>Map import schema</HeaderDiv>
            <SelectField width="260px" color={colors} label="Excel sheet" options={sheetOptions} fieldValue={{ label: excelSheet, value: excelSheet }} handleSelect={setSheet} attName="" margin="20px auto 0 auto" />
            <SelectedFieldsDiv>

                <SelectField width="260px" color={colors} label={integrationType === "deltareport" ? "Report" : "Schema page"} options={templatePages} fieldValue={templatePage} handleSelect={setTemplatePage} attName="" margin="20px 10px 0 10px" />
                {hasMultipleFieldsSchemas ? "" : <SelectField width="260px" color={colors} label="Fields schema" options={fieldSchemas} fieldValue={fieldSchema} handleSelect={setFieldSchema} attName="" margin="20px 10px 0 10px" />
                }
            </SelectedFieldsDiv>
            <MapSchemaDiv colors={colors}>
                {fieldOptions.map(item => {
                    let fieldText = item.label
                    if (fieldText === 'Field in integrated system') {
                        fieldText = 'Field in ' + integrationType.substring(0, 1).toUpperCase() + integrationType.substring(1)
                    }
                    const fieldIndex = schemaFields.findIndex(x => x.field === item.value);
                    let fieldValue = ""
                    try {
                        if (fieldIndex >= 0) {
                            fieldValue = schemaFields[fieldIndex]
                        }

                    } catch {

                    }
                    if (item.value === 'validFrom' && !("validFrom" in originalTemplateGetter.fieldTemplate)) {
                        return null
                    }
                    if (item.value === 'plannedChange' && !("plannedChange" in originalTemplateGetter.fieldTemplate)) {
                        return null
                    }
                    if (item.value === 'alwaysInclude' && !("alwaysInclude" in originalTemplateGetter.fieldTemplate)) {
                        return null
                    }
                    if (item.value === 'eventType' && !("eventType" in originalTemplateGetter.fieldTemplate)) {
                        return null
                    }

                    return (
                        <ListItem colors={colors}>
                            <SchemaField colors={colors}>{fieldText}</SchemaField>
                            <SelectField width="260px" color={colors} label="Field from schema" options={schemaFields} fieldValue={fieldValue} handleSelect={updateField} attName={item.value} margin="0 10px 0 10px" />
                        </ListItem>
                    )
                })}
            </MapSchemaDiv>
            <ButtonDiv>
                <BasicButton fontSize="1em" background="primary" label="Back" width="80px" height="30px" margin="auto 20px 15px auto" handleClick={() => navigate('/importschema')} />
                <BasicButton fontSize="1em" background="primary" label="Next" width="80px" height="30px" margin="auto auto 15px auto" handleClick={updateSchema} />
            </ButtonDiv>
        </IntegrationsListContainer>
    )
}

const IntegrationsListContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const MapSchemaDiv = styled.div`

    width: 60%;
    margin: auto auto auto auto;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: ${props => props.theme.colors.elevation1};
    padding: 10px;
    height: 66%;
    overflow: auto;

`
const ListItem = styled.div`
    height: 75px;
    width: 100%;
    background: ${props => props.theme.colors.elevation1};
    display:flex;
    flex-direction: row;
    margin: 5px auto;
    align-items: center;
    border-radius: 30px;

`

const HeaderDiv = styled.div`
    width: 100%;
    font-size: 2.5em;
    text-align: center;
    margin: 40px auto 0 0;
    align-items: center;
    border-radius: 30px;
    color: ${props => props.theme.colors.primary};

`
const SchemaField = styled.div`
    width: 50%;
    color: ${props => props.theme.colors.primary};
    padding: 10px;
    font-size: 1.3em;
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`
const SelectedFieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0px 0 0px 0px;
`
export class IntegrationTemplateModel {
    constructor(selectedFields, secrets, validFrom, plannedChange, alwaysInclude, eventType) {
        this.selectedFields = addSelectedFields(selectedFields);
        this.settings = settings;
        this.fieldTemplate = addFieldTemplate(validFrom, plannedChange, alwaysInclude, eventType);
        this.fields = addFields(selectedFields, eventType);
        this.employeeFilter = {};
        this.secrets = secrets;
        this.notes = "";
        this.audit = {createdBy: "", createdDate: "", lastUpdatedBy: "", lastUpdated: ""};

    }
}

const settings = {
    integrationStatus: {
        "label": "Integration status",
        "type": "select",
        "value": {
            "label": "Development",
            "value": "development"
        },
        "info": "Set status of integration (development, testing or in production)",
        "list": [
            {
                "label": "Production",
                "value": "production"
            },

            {
                "label": "Hypercare",
                "value": "hypercare"
            },
            {
                "label": "Development",
                "value": "development"
            },
            {
                "label": "Testing",
                "value": "testing"
            }
        ]
    }
}

const addSelectedFields = selectedFields => {
    const selectedFieldsObj = {}
    selectedFields.forEach(field => {
        selectedFieldsObj[field.label] = {c1Endpoint: field.type, fields : [], direction :field.direction}
    })
    return selectedFieldsObj
}

const addFieldTemplate = (validFrom, plannedChange, alwaysInclude, eventType) => {
    const fieldTemplate = {
		"c1Field": {
			"label": "Choose field",
			"value": "Choose field",
			"type": "TEXT"
		},
		"sourceField": {
			"label": "Choose field",
			"value": "Choose field"
		},
		"valueType": {
			"label": "Value",
			"value": "value"
		},
		"calculatedValue": "",
		"fieldType": "c1Field",
		"concatedOutput": [],
		"customLogic": [],
		"hardCodedValue": "",
		"dataProfileField": {
			"label": "Choose field",
			"value": "Choose field",
			"type": "TEXT"
		},
		"objectField": {
			"objectName": {
				"label": "Choose field",
				"value": "Choose field"
			},
			"fields": []
		}
	}
    if (validFrom) {
        fieldTemplate.validFrom = false
    }
    if (plannedChange) {
        fieldTemplate.plannedChange = false
    }
    if (alwaysInclude) {
        fieldTemplate.alwaysInclude = false
    }
    if (eventType) {
        fieldTemplate.eventType = {
            "label": "Choose field",
            "value": "Choose field"
        }
    }
    return fieldTemplate
}
const addFields = (selectedFields, eventType) => {
    const fields = {}
    selectedFields.forEach(field => {
        fields[field.label] = []
    })
    if (eventType) {
        fields['eventTypes'] = []
    }
    fields.objectFields = []
    return fields
}
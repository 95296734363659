import React from 'react'
import Switch from "react-switch";
import { styled } from 'styled-components';
import InfoHoverBox from './InfoHoverBox';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export default function SwitchComponent({label, handleChange, checked, onColor, attName, index, info}) {
  const themeContext = useContext(ThemeContext)
  return (
    <SwitchContainer>
       <StyledLabel>{label}
       {info ? <InfoHoverBox text={info} margin="-5px 0px -5px 0px"/> : null }
       </StyledLabel> 
        <Switch height={25} width={53} onChange={() => handleChange()} checked={checked} onColor={themeContext.colors[onColor]}/>
    </SwitchContainer>
  )
};

const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    color: white;
    margin:0 auto 0px auto;
`
const SwitchContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1em;
    margin: 5px;
`

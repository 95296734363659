import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
   dark:  {colors: {
        background: '#121212',
        primary: '#dbd9d9',
        disabledButton: '#858585',
        primaryLighter: '#f7f7f7',
        contrast: '#0a0a0a',
        primaryButton: '#dbd9d9',
        selectBoxBackground: '#383838',
        primaryButtonText: '#dbd9d9',
        tableBackground: '#2E3744',
        boxColor1: '#15141c',
        boxColor2: '#2d2c33',
        dropZone: '#242c36',
        dropZoneActive: '#3e4750',
        grey: '#383838',
        grey1: 'rgba(255, 255,255, 0.1)',
        grey2: 'rgba(255, 255,255, 0.2)',
        blue1: '#B6F4FB',
        blue2: '#00B4C8',
        blue3: '#005F78',
        blue4: '#001F33',
        contrastBlue: '#00B4C8',
        contrastBlue1: '#00B4C8',
        contrastRed: '#FF8DAC',
        contrastGreen: '#9EDDA7',
        contrastYellow: '#FDCB7D',
        contrastOrange: '#FAA61A',
        shadow: '0 0 20px rgba(0, 0,0, 1)',
        borderColor: 'rgba(255,255,255, 0.0)',
        boxColor: '#001F33',
        topNavColor: '#001F33',
        overlay: 'rgba(0,0,0, 0.3)',
        elevation1: 'rgba(255,255,255, 0.05)',
        elevation2: 'rgba(255,255,255, 0.07)',
        elevation3: 'rgba(255,255,255, 0.09)',
        elevation1Color : '#1E1E1E',
        grey3: '#464646',
        blueElevation1: 'rgba(0, 180, 200, 0.06)',
        blueElevation2: 'rgba(0, 180, 200, 0.1)'
    },
    fonts: ["sans-serif", "Roboto"],
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em"
    }},
    light: {colors: {
        background: '#ffffff',
        primary: '#0a0a0a',
        disabledButton: '#0a0a0a',
        primaryLighter: '#383838',
        contrast: '#dbd9d9',
        primaryButton: '#005F78',
        primaryButtonText: '#dbd9d9',
        selectBoxBackground: '#ffffff',
        tableBackground: '#2E3744',
        boxColor1: '#15141c',
        boxColor2: '#2d2c33',
        dropZone: '#242c36',
        dropZoneActive: '#3e4750',
        borderColor: "#001F33",
        grey: '#ffffff',
        grey1: 'rgba(10, 10,10, 0.07)',
        grey2: 'rgba(10, 10,10, 0.01)',
        blue1: '#B6F4FB',
        blue2: '#00B4C8',
        blue3: '#005F78',
        blue4: '#001F33',
        contrastBlue: '#005F78',
        contrastBlue1: '#00B4C8',
        contrastRed: '#FF8DAC',
        contrastGreen: '#006C50',
        contrastYellow: '#FDCB7D',
        contrastOrange: '#FAA61A',
        shadow: '0 0 20px rgba(0, 0,0, 1)',
        boxColor: '#001F33',
        topNavColor: '#001F33',
        overlay: 'rgba(0,0,0, 0.3)',
        elevation1: 'rgba(0,0,0, 0.04)',
        elevation2: 'rgba(0,0,0, 0.08)',
        elevation3: 'rgba(0,0,0, 0.12)',
        elevation1Color : '#E2E2E2',
        grey3: '#464646',
        blueElevation1: 'rgba(0, 180, 200, 0.05)',
        blueElevation2: 'rgba(0, 180, 200, 0.1)'
    },
    fonts: ["sans-serif", "Roboto"],
    fontSizes: {
        small: "1em",
        medium: "2em",
        large: "3em"
    }}
};


const Theme = ({ children, mode }) => (
    <ThemeProvider theme={theme[mode]}>{children}</ThemeProvider>
);

export default Theme;

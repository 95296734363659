import React from 'react'
import styled from 'styled-components'
import { firstLetterUppercase } from '../../../services/functions'

export default function AuditOptions({options, setOption,option}) {
  return (
    <Container>

        {options.map( (item) => {
            const active = item === option
            return (
                <Option onClick={() => setOption(item)} active={active} >{firstLetterUppercase(item)}</Option>
            )
        })}
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    widht: 100%;
    justify-content: center;
    margin: 10px;
    
    `

const Option = styled.div`
    padding: 6px;
    background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation1};
    color: ${props => props.theme.colors.primary};
    border-radius: 30px;
    height: auto;
    margin: auto 4px;
    z-index: 1000001;
    &:hover {
        cursor: pointer;
    }
`


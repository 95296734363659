import { v4 as uuidv4 } from 'uuid'; 

export class FieldTemplateModel {
    constructor() {
        this.c1Field = new C1Field;
        this.sourceField = new  BasicField;
        this.eventType = new BasicField;
        this.valueType = new ValueType;
        this.calculatedValue = "";
        this.fieldType = "c1Field";
        this.concatedOutput = [];
        this.plannedChange = false;
        this.alwaysInclude = false;
        this.validFrom = false;
        this.customLogic = [];
        this.hardCodedValue = "";
        this.dataProfileField = new C1Field;
        this.objectField = new ObjectField;
        this.id = uuidv4();

    }
}

class C1Field {
    constructor() {
        this.label = "Choose field";
        this.value = "Choose field";
        this.type = "TEXT";
    }
}
class BasicField {
    constructor() {
        this.label = "Choose field";
        this.value = "Choose field";
        this.type = "TEXT";
    }
}

class ValueType {
    constructor() {
        this.label = "Value";
        this.value = "value";
    }
}

class ObjectField {
    constructor() {
        this.objectName = new BasicField;
        this.fields = [];
    }
}

export class ConditionTemplateModel {
    constructor() {
        this.textValue = "";
        this.fieldValue = new C1Field;
        this.valueFromField = false;
        this.else = false;
        this.concatOutput = false;
        this.valueType = new BasicField;
        this.fieldType = 'c1Field';
        this.calculatedValue = "";
        this.concatedOutput = [];
        this.objectField = new ObjectField;
        this.conditionFields = [new ConditionField];
    }
}

class ConditionField {
    constructor() {
        this.operator = { label: "Equals", value: "=" };
        this.field = new BasicField;
        this.value = "";
        this.listValues = [];
        this.hasValueFromList = false;
        this.dateRolling = false;
        this.dateRollingValueType = new ValueType;
    }
}

export class ConcatedFieldTextModel {
    constructor() {
        this.type = 'text';
        this.value = '';
    }
}
export class ConcatedFieldC1FieldModel {
    constructor() {
        this.type = 'field';
        this.value = '';
        this.substringStart = "";
        this.substringEnd = "";
        this.valueType = new BasicField;
    }
}
export class ConcatedFieldIteratorModel {
    constructor() {
        this.type = 'iterator';
        this.value = '';
        this.priority = "";
        this.onlyOnPriority = false;
    }
}

export class ObjectFieldModel {
    constructor() {
        this.c1Field = new C1Field;
        this.valueType = new BasicField;
        this.sourceField = new BasicField;
        this.fieldType = "c1Field";
        this.dataProfileField = new BasicField;
        this.hardcodedValue = "";
        this.concatedOutput = [];
    }
}

import React from 'react'
import { styled } from 'styled-components'
import { useHookstate } from '@hookstate/core'
import { Trash } from "@styled-icons/bootstrap/Trash"
import { c1State } from '../../../States/c1State'
import BasicButton from '../../SharedComponents/BasicButton'
import ConditionLogic from './ConditionLogic'
import SwitchComponent from '../../SharedComponents/Switch'
import SwitchButton from '../../SharedComponents/SwitchButton'
import { fieldOptions } from '../../../globalConfig/fieldOptions'
import { Copy } from "@styled-icons/fluentui-system-regular/Copy"
import FieldInput from '../FieldEditor/FieldInput'
import { originalTemplate } from '../../../States/originalTemplate'

export default function Condition({ condition, fieldIndex, conditionIndex, configStateSetter, conditions, schemaPage, lastCondition, fieldObj, selectedFields, systemFields, setFieldToEdit,originalFieldObj }) {

    const c1StateGetter = useHookstate(c1State).get()
    const originalTemplateStateGetter = useHookstate(originalTemplate).get()

    let fieldOptionsList = [{ label: 'Value', value: 'value' }]

    if (condition.fieldValue.type in fieldOptions) {
        fieldOptionsList = fieldOptions[condition.fieldValue.type]

    }

    const setConditionAttribute = (choice, index, attName) => {
        if (choice) {
            condition[attName] = choice
        } else {
            condition[attName] = { label: 'Choose field', value: 'value' }
        }
        if (schemaPage[0] === 'transactions' && choice === 'object') {
            condition.objectField = originalTemplateStateGetter.fields.transactionObject
        }
        fieldObj.customLogic.splice(conditionIndex, 1, condition)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))

    }

    const deleteCondition = () => {

        fieldObj.customLogic.splice(conditionIndex, 1)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))

    }

    const copyCondidtion = () => {
 
        fieldObj.customLogic.splice(conditionIndex, 0, conditions[conditionIndex])
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))

    }

    const setElse = (e) => {
        condition.else = !condition.else
        fieldObj.customLogic.splice(conditionIndex, 1, condition)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }

    const addConditionField = () => {
        condition.conditionFields.push(
            {
                operator: { label: "Equals", value: "=" },
                field: { label: "Choose field", value: "chooseField" },
                value: "",
                listValues: [],
                hasValueFromList: false,
                dataProfileField: ""
            }
        )
        fieldObj.customLogic.splice(conditionIndex, 1, condition)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }

    return (
        <OuterContainer>
            <ConditionName >Condition {conditionIndex + 1}</ConditionName>
            <ConditionContainer >
                <HeaderDiv>
                    <ConditionName >Output</ConditionName>
                    <SwitchButton
                        handleClick={setConditionAttribute}

                        width="490px"
                        boxWidth="25%"
                        activeButton={condition.fieldType}
                        options={[
                            { label: 'C1 Field', value: 'c1Field' },
                            { label: 'Concat', value: 'concat' },
                            { label: 'Hardcoded', value: 'hardCoded' },
                            { label: 'Calculated', value: 'calculated' },
                            { label: 'Object', value: 'object' },
                            { label: 'Profile', value: 'dataProfile' },
                            { label: 'Manager', value: 'manager' }
                        ]} />
                    <CopyIcon onClick={copyCondidtion} />
                    <DeleteIcon onClick={deleteCondition} />
                </HeaderDiv>

                {lastCondition ?
                    <SwitchComponent label="Else (default value)" checked={condition.else} handleChange={() => setElse()} onColor="contrastBlue" attName="hardCoded" />
                    :
                    ""

                }
                <FieldInput
                    fieldObj={condition}
                    setFieldAttribute={setConditionAttribute}
                    templateType=""
                    c1Fields={c1StateGetter.c1Fields}
                    fieldOptionsList={fieldOptionsList}
                    fieldIndex={fieldIndex}
                    systemFields={systemFields}
                    configStateSetter={configStateSetter}
                    selectedFields={selectedFields}
                    schemaPage={schemaPage}
                    dataProfileFields={c1StateGetter.lists.dataProfiles}
                    conditionIndex={conditionIndex}
                    fromCondition={true}
                    originalTemplateStateGetter={originalTemplateStateGetter}
                    setFieldToEdit={setFieldToEdit}
                    originalFieldObj={originalFieldObj}
                />

                {
                    condition.else ?
                        ""
                        :
                        <>
                            <TextDiv >Output above if below condition(s) is true</TextDiv>

                            {condition.conditionFields.map((conditionField, conditionFieldIndex) => {
                                const andText = conditionFieldIndex !== condition.conditionFields.length - 1 ? "AND" : ""
                                return (
                                    <ConditionDiv>
                                        <ConditionLogic
                                            conditionField={conditionField}
                                            conditionIndex={conditionIndex}
                                            conditionFieldIndex={conditionFieldIndex}
                                            setFieldToEdit={setFieldToEdit}
                                            fieldObj={fieldObj}
                                            key={`conditionField${conditionFieldIndex}-${conditionIndex}-${fieldIndex}`}
                                        />
                                        <TextDiv >{andText}</TextDiv>
                                    </ConditionDiv>
                                )

                            })}
                            <BasicButton background="primary" fontSize="0.9em" label="AND" width="4em" height="32px" margin="10px auto 10px auto" handleClick={addConditionField} />
                        </>
                }

            </ConditionContainer>
        </OuterContainer>
    )
}

const ConditionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 3px 8px 3px 8px;
    background: ${props => props.theme.colors.elevation3};
    margin: 7px 0 7px 0px;
`
const ConditionName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    margin-left: 5px;
`
const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 7px 0 0px 0px;
`
const ConditionDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0 0px 0px;
`

const TextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    text-align: middle;
    margin: 2px auto 2px auto;
    font-style: italic;
    font-weight: 600;
`

const DeleteIcon = styled(Trash)`
   width: 1.4em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`

const CopyIcon = styled(Copy)`
   width: 1.4em ;
   margin: auto 5px;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`
const OuterContainer = styled.div`
    width: 100%;
`

const SelectedFieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 0 0px 0px;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    margin: 20px 0 10px 0;
`
import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { customerState } from '../../States/customerState';
import { useHookstate } from '@hookstate/core';
import { templateStates } from '../../States/templateState';

export default function NewIntegrationOptionsMenu({options}) {

  const customerStateGet= useHookstate(customerState).get()
  const templateStateGet = useHookstate(templateStates).get()

  const mappingPath = customerStateGet.integrationType === 'deltareport' ? '/deltareport' : '/mapping'

  const navigate = useNavigate()

  return (
    <Container >

      <OptionDiv>
      <ListOptionBox onClick={() => navigate('/importschema')} >
        <ListOptionText >
          Import from schema
        </ListOptionText>
      </ListOptionBox>
      <ListOptionBox onClick={() => navigate(mappingPath)} >
        <ListOptionText >
          Map manually
        </ListOptionText>
      </ListOptionBox>
      <ListOptionBox onClick={() => navigate('/getconfig')} >
        <ListOptionText >
          Get config from existing blob
        </ListOptionText>
      </ListOptionBox>
      </OptionDiv>
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`


const ListOptionBox = styled.div`
    background: ${props => props.theme.colors.blueElevation1};
    width: 230px;
    height: 180px;
    margin: 20px 10px 0 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 4px 1px rgba(0, 180, 200, 0.03);
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.blueElevation2};
        font-size: 1.1em;
    }
`
const ListOptionText = styled.div`

    color: ${props => props.theme.colors.contrastBlue};
    text-align: center;
    font-size: 1.6em;
    padding: 8px;
    
`
const OptionDiv = styled.div`
    margin: auto auto auto auto;
    display: flex;
    flex-direction: row;
    align-self: center;
`
import React from 'react'
import styled from 'styled-components'
import c1_logo_dark from '../../assets/c1_logo_dark.png'
import c1_logo_blue from '../../assets/c1_logo_blue.png'

export default function LoginPage({ handleRedirect }) {
  return (
    <Container visible={false}>
      <C1Logo />
      <HeaderText>Integration<ColoredHeader>One</ColoredHeader></HeaderText>
      <LoginButton onClick={handleRedirect}>Login</LoginButton>
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    height: 100vh;
    margin: ${props => props.margin};
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    `

const C1Logo = styled.img.attrs({
  src: c1_logo_blue
})`
      width: 400px;
    
      `
const LoginButton = styled.div`
background: ${props => props.theme.colors.contrastBlue};
color: ${props => props.theme.colors.background};
width: 160px;
border-radius: 30px;
font-size: 1.3em;
align-items: center;
text-align: center;
vertical-align: middle;
padding: 5px;
font-weight: 600;
margin: 50px auto;
box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
&:hover {
  cursor: pointer;
  background: ${props => props.blueElevation1};
}
    `
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 2em;
    margin: -40px 0px 0px 0px;
`
const ColoredHeader = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  display: inline;
  font-style: italic;
`
import React from 'react'
import styled from 'styled-components'
import { EditorProvider, FloatingMenu, BubbleMenu, useEditor, EditorContent } from '@tiptap/react'
import StarterKit from "@tiptap/starter-kit";
import MenuBar from '../Notes/MenuBar';
import { useHookstate } from '@hookstate/core';
import BasicButton from '../../SharedComponents/BasicButton';
import { useNavigate } from 'react-router-dom';
import { customerState } from '../../../States/customerState';


export default function DocumentationTextInput({content, setContent}) {
    const navigate = useNavigate()
    const customerStateGetter = useHookstate(customerState).get()
    const configSetter = useHookstate(customerState).config
    const customerIntegrationName = customerStateGetter.systemName ? `${customerStateGetter.customerName}: ${customerStateGetter.systemName}` : `${customerStateGetter.customerName}: ${customerStateGetter.integrationType}`
    
    const originalNotes = configSetter.get().notes ? configSetter.get().notes : ''


    const extensions = [
        StarterKit,
    ]


    const editor = useEditor({
        extensions,
        content,
        onUpdate: ({ editor }) => {
            setContent(editor.getHTML())
           }
    })

    const saveNotes = () => {
        const newTemplateState = JSON.parse(JSON.stringify(configSetter.get()))
        newTemplateState.notes = content
        configSetter.set(newTemplateState)
        console.log(newTemplateState)
    }

    return (
        <OuterContainer>
            <Container>
                <HeaderText>Integration notes</HeaderText>
                <InnerContainer>
                <MenuBar editor={editor} />
                    <EditorDiv>
                        <EditorContent editor={editor} />
                    </EditorDiv>
                </InnerContainer>
                <ButtonDiv>

                </ButtonDiv>
                
            </Container>
        </OuterContainer>
    )
}

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`
const InnerContainer = styled.div`
height: 83%;
width: 99%;
margin: auto auto auto auto;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: column;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const OuterContainer = styled.div`
background: ${props => props.theme.colors.background};
width: 35%;
height: 100%;
margin: 2% auto auto auto;
display: flex;
flex-direction: row;
justify-content: flex-start;
`

const HeaderText = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const EditorDiv = styled.div`

    margin: auto;
    border-radius: 31px;
    padding: 30px;
    color: ${props => props.theme.colors.primary};
    width: 100%;
    height: 100%;
    overflow: auto;

    font-size: 1.3em;

`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  align-self: center;
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1em;
`
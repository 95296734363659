
import * as XLSX from 'xlsx'
import { fetchFieldsAndListsWithSecrets } from './getC1Data';
import { v4 as uuidv4 } from 'uuid'; 
import BackendApiClient from './backendApiClient';


export const parseExcelFile = async (file) => {

  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data, { type: 'binary', cellText: false, cellDates: true })
  const outPutData = {}

  workbook.SheetNames.map(sheetName => {
    const worksheet = workbook.Sheets[sheetName]
    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: false, dateNF: 'yyyy-mm-dd', defval: "" });
    const excelData = JSON.parse(JSON.stringify(jsonData))
    const headers = excelData[0]
    outPutData[sheetName] = []

    excelData.map(row => {
      const newRow = []
      row.map(cell => {
        let cellString = cell.toString().replace(/(\r\n|\n|\r)/gm, "")
        newRow.push(cellString)
      })
      outPutData[sheetName].push(newRow)
    })

  })

  return outPutData

}
export const setEditIntegration = async (customerName, integrationType, configName, blobName, c1StateRaw, messageStateRaw, user) => {
  const secretPrefix = configName ? `${integrationType}-${configName}` : integrationType
  const backendApiClient = new BackendApiClient()
  let missingKeyVault = false

  // Get integration template from blob
  let template = await backendApiClient.get(`template/integrationtemplates/${integrationType}/template.json`)
  if (template.status !== 200) {
    messageStateRaw.set("Failed to get template")
  }
  template = template.data  
  
  // Get fields from C1
  const c1Fields = await fetchFieldsAndListsWithSecrets(`${customerName}-${process.env.REACT_APP_KEYVAULT_PREFIX}`, secretPrefix, c1StateRaw)

  if (!c1Fields[0]) {
    if (c1Fields[1] === 'Key vault not found') {
      missingKeyVault = true
    } else if (c1Fields[1].includes("The given key ")) {
      missingKeyVault = true
    } else if (c1Fields[1].includes("Failed to retrieve secrets")) {
      missingKeyVault = true
    }
  }


  // Get config from blob
  const configResponse = await backendApiClient.get(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerName}/${blobName}`)
  if (configResponse.status === 200) {
    const result = configResponse.data
    let config = {
    }

    if (integrationType === 'deltareport') {
      config = {
        reports: JSON.parse(JSON.stringify(result.reports)),
        settings: JSON.parse(JSON.stringify(result.settings)),
      }
    } else {
      config = {
        selectedFields: JSON.parse(JSON.stringify(template.selectedFields)),
        settings: JSON.parse(JSON.stringify(template.settings)),
        employeeFilter: {},
        notes: "",
        audit: result.audit ? result.audit : { createdBy: user.username, createdDate: new Date().toISOString(), lastUpdatedBy: user.username, lastUpdated: new Date().toISOString(), auditLog: [] }
      }
      Object.keys(result.settings).map(key => {
        try {
          if ('info' in config.settings[key]) {
            result.settings[key].info = config.settings[key].info
          }
        } catch { }

        config.settings[key] = result.settings[key]
      })
      Object.keys(result.selectedFields).map(key => {
        config.selectedFields[key] = addIdToFields(result.selectedFields[key])
      })
      if ('employeeFilter' in result) {
        config.employeeFilter = result.employeeFilter
      }
      if ('notes' in result) {
        config.notes = result.notes
      }
    }

    return { config: config, originalTemplate: template, missingKeyVault: missingKeyVault }
  }
  return false

}

export const firstLetterUppercase = (str) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1)
  } catch (error) {
    return "str"
  }

}

const addIdToFields = (fields) => {
  let fieldsArray = Array.isArray(fields) ? fields : fields.fields

  fieldsArray.map((field) => {
    if (!field.id) {
      field.id = uuidv4()
    }
  })
  return fields
}
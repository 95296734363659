import React from 'react'
import styled from 'styled-components'
import { keyframes } from 'styled-components'
import c1_logo_blue from '../../assets/c1_logo_blue.png'

export default function LoadingPage() {
  return (
    <Container >
        <C1Logo />
    </Container>
  )
}


const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

`
const breatheAnimation = keyframes`
 0% {  width: 500px; opacity: 1}
 50% { width: 350px; opacity: 0.7}
 100% {  width: 500px; opacity: 1}
`

const C1Logo = styled.img.attrs({
    src: c1_logo_blue
  })`
  width: 400px;
  object-fit: contain;
  animation-name: ${breatheAnimation};
    animation-duration: 3s;
 animation-iteration-count: infinite;
  filter: drop-shadow(0 0 0.95rem #00B4C8);
  margin: auto;

  `

import React, {useState} from 'react'
import styled from 'styled-components'
import AuditField from './AuditField'

export default function AuditLogList({auditLogs, mappingPageOption}) {

    const [changeType, setChangeType] = useState('updated')
    const [fieldIndex, setFieldIndex] = useState(0)
    const [showAuditField, setShowAuditField] = useState(false)
    const [auditIndex, setAuditIndex] = useState(0)

    const hasLogs = (auditLog) => {
        if (auditLog.updated.selectedFields.hasOwnProperty(mappingPageOption)) {
            return true
        } else if (auditLog.added.selectedFields.hasOwnProperty(mappingPageOption)) {
            return true
        } else if (auditLog.deleted.selectedFields.hasOwnProperty(mappingPageOption)) {
            return true
        } else {
            return false
        }
    }

  return (
    <Container>
        {auditLogs.map( (item, auditListIndex) => {
            if (hasLogs(item)) {
                return (
                    <AuditDiv>
                    <AuditDate key={"date-" + auditListIndex}>Changed by: {item.updatedBy} at {item.lastUpdated}</AuditDate>
                    {item.updated.selectedFields[mappingPageOption].length > 0 ?
                    <>
                    <AuditHeader>Updated Fields</AuditHeader>
                    {item.updated.selectedFields[mappingPageOption].map( (field, index) => {
                        const showField = showAuditField && fieldIndex === index && changeType === 'updated' && auditIndex === auditListIndex
                        return <AuditField 
                            key={index} 
                            index={index}
                            field={field} 
                            setChangeType={setChangeType} 
                            setFieldIndex={setFieldIndex} 
                            setShowAuditField={setShowAuditField}
                            setAuditIndex={setAuditIndex}
                            auditListIndex={auditListIndex}
                            showField={showField} 
                            fieldChangeType="updated"/>

                    })}
                    </>  
                    : null}


                    {item.added.selectedFields[mappingPageOption].length > 0 ? 
                    <>
                    <AuditHeader>New Fields</AuditHeader>
                    {item.added.selectedFields[mappingPageOption].map( (field, index) => {
                        const showField = showAuditField && fieldIndex === index && changeType === 'new' && auditIndex === auditListIndex
                        return <AuditField 
                            key={index} 
                            index={index}
                            field={field} 
                            setChangeType={setChangeType} 
                            setFieldIndex={setFieldIndex} 
                            setShowAuditField={setShowAuditField}
                            setAuditIndex={setAuditIndex}
                            auditListIndex={auditListIndex}
                            showField={showField} 
                            fieldChangeType="new"/>

                    })}
                    </>
                    : null}

                    {item.deleted.selectedFields[mappingPageOption].length > 0 ? <AuditHeader>Deleted Fields</AuditHeader> : null}
                    </AuditDiv>
                    
                    
                )
            }
        })}
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 100%;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    `
const AuditDate = styled.div`
    font-size: 1em;
    color: ${props => props.theme.colors.primary};
    `
const AuditHeader = styled.div`
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
    `

const AuditDiv = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    `
const FieldList = styled.div`
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    `

import React from 'react'
import styled from 'styled-components'
import BasicButton from '../../SharedComponents/BasicButton'
import FilterLogic from './FilterLogic'
import {Trash} from "@styled-icons/bootstrap/Trash"

export default function EmployeeFilter({ visible, colors, configStateSetter,schemaPage,setEmployeeFilterVisible }) {

    if ('employeeFilter' in configStateSetter.get()) {

    } else {
        configStateSetter.employeeFilter.set({})
    }
    let employeeFilter = JSON.parse(JSON.stringify(configStateSetter.employeeFilter.get()))
    const employeeFilterArray = employeeFilter[schemaPage] ? employeeFilter[schemaPage] : []
    const addNewFilter = () => {
        if (!employeeFilter[schemaPage]) {
            employeeFilter[schemaPage] = []
        }
        employeeFilter[schemaPage].push(
            {
                fields: [
                    {
                        field: "",
                        value: "",
                        operator: "",
                        hasValueFromList: false,
                    }
                ]
            }
        )
        configStateSetter.employeeFilter.set(employeeFilter)

    }

    const addCondition = (index) => { 
        employeeFilter[schemaPage][index].fields.push(
            {
                field: "",
                value: "",
                operator: "",
                hasValueFromList: false,
            }
        )
        configStateSetter.employeeFilter.set(employeeFilter)
    }

    const deleteCondition = (index) => { 
        employeeFilter[schemaPage].splice(index,1)
        configStateSetter.employeeFilter.set(employeeFilter)
    }


    return (
        <EmployeeFilterContainter visible={visible} colors={colors}>
            <TextDiv colors={colors}>Employee filters</TextDiv>
            <InfoTextDiv colors={colors}>If one of the filters evaluates to true, the employee will be included</InfoTextDiv>
            
            {employeeFilterArray.map((filter, index) => {
                return (
                    <ConditionDiv>
                        <HeaderDiv>
                        <FilterHeader colors={colors}>Filter {index + 1}</FilterHeader>
                        <DeleteIcon colors={colors} onClick={e => deleteCondition(index)}/>
                        </HeaderDiv>
                        <FilterLogic schemaPage={schemaPage} filter={filter} configStateSetter={configStateSetter} employeeFilter={employeeFilter} filterIndex={index} colors={colors} />
                        <BasicButton background="primary" label="Add" width="70px" height="35px" margin="15px auto 10px auto" handleClick={e => addCondition(index)} />
                    </ConditionDiv>
                )
                })}
            <BasicButton background="primary" label="Add filter" width="110px" height="35px" margin="10px auto 10px auto" handleClick={addNewFilter} />
            <BasicButton background="primary" label="Save" width="130px" height="35px" margin="30px auto 10px auto" handleClick={() => setEmployeeFilterVisible(false)} />
        </EmployeeFilterContainter>
    )
}

const EmployeeFilterContainter = styled.div`
    background: ${props => props.theme.colors.grey};
    left: 17%;
    top: 12%;
    width: 66%;
    max-height: 76%;
    border-radius: 30px;
    visibility: ${props => props.visible ? "visbile" : "hidden"};
    display:flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
    overflow: overlay;
    z-index: 5;
`
const TextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.4em;
    text-align: middle;
    margin: 2px auto 10px auto;

`
const InfoTextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1em;
    text-align: middle;
    margin: 2px auto 10px auto;
    font-style: italic;

`

const FilterHeader = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
`
const ConditionDiv = styled.div`
 margin: 10px auto 10px auto;
`
const DeleteIcon = styled(Trash) `
   width: 1.2em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }

    margin: auto 2px auto 8px;
`
const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 0 10px 0px;


`
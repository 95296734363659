import React from 'react'
import styled from 'styled-components'

export default function SwitchButton({color,handleClick,activeButton,options,width,boxWidth}) {


  return (
    <Container>
    <SwitchContainer width={width}  colors={color} >
       {
        options.map((item,index) => {
          const borderLeft = (index === 0) ? false : true
          const borderRadiusLeft = (index === 0) ? true : false
          const borderRadiusRight = (index === options.length -1) ? true : false
          const active = (item.value === activeButton) ? true : false
          return(
            <ButtonDiv boxWidth={boxWidth}  onClick={() => handleClick(item.value, index, 'fieldType')} colors={color} borderLeft={borderLeft} active={active} borderRadiusLeft={borderRadiusLeft} borderRadiusRight={borderRadiusRight}>
              {item.label}
            </ButtonDiv>
          )
        })
       }
    </SwitchContainer>
    </Container>
  )
}



const SwitchContainer = styled.div`
margin: 10px;
display:flex;
width: ${props => props.width};
flex-direction: row;
border-style: solid;
border-color: ${props => props.theme.colors.primary};
border-width: 0.1em;
border-collapse: collapse;
border-radius: 30px;
box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
&:hover {
  cursor: pointer;
}
`
const ButtonDiv = styled.div`
    padding: 7px;
    font-size: 0.95em;
    flex: 1;
    text-align: center;
    border-color: ${props => props.theme.colors.primary};
    border-width: 0.1em;
    border-collapse: collapse;
    color: ${props => props.active ? props.theme.colors.primaryButtonText : props.theme.colors.primary};
    border-left-style: ${props => props.borderLeft ? 'solid' : 'none'};
    background: ${props => props.active ? props.theme.colors.contrastBlue : 'none'};
    border-radius: ${props => props.borderRadiusLeft ? '30px 0 0 30px' : props.borderRadiusRight ? '0 30px 30px 0'  :'0 0 0 0'};
    width: ${props => props.boxWidth};
`
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`

import React, {useState} from 'react'
import styled from 'styled-components'
import MappingSideNav from '../../IntegrationMapping/MappingSideNav/MappingSideNav'
import { useNavigate } from 'react-router-dom'
import AuditOptions from './AuditOptions'
import useIntegrationStates from '../../../hooks/useIntegrationStates'
import AuditLogList from './AuditLogList'

export default function Audit() {
    const {configStateGetter} = useIntegrationStates()
    const [auditOption, setAuditOption] = useState('Mapping')
    const [mappingPageOption, setMappingPageOption] = useState(Object.keys(configStateGetter.selectedFields)[0])
    
    const getMappingPages = () => {
        const mappingPages = []
        Object.keys(configStateGetter.selectedFields).forEach(page => {
            mappingPages.push(page)
        })
        return mappingPages
    }
    console.log(configStateGetter.audit)

    const navigate = useNavigate()
  return (
    <OuterContainer>
        <MappingSideNav navigate={navigate} />

        <Container>
        <HeaderDiv >Audit</HeaderDiv>
        <CustomerName >Created by: {configStateGetter.audit.createdBy}, {configStateGetter.audit.createdDate}</CustomerName>
            <InnerContainer>
                <AuditOptions options={['Mapping', 'Settings']} setOption={setAuditOption} option={auditOption} />
                {auditOption === 'Mapping' ?
                <>
                <AuditOptions options={Object.keys(configStateGetter.selectedFields)} setOption={setMappingPageOption} option={mappingPageOption} />
                <AuditLogList auditLogs={configStateGetter.audit.auditLog} mappingPageOption={mappingPageOption} />
                </>
                 : null}
            </InnerContainer>
        </Container>
    </OuterContainer>
  )
}

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`
const InnerContainer = styled.div`
height: 83%;
width: 96%;
margin: auto auto auto 15px;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: column;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const OuterContainer = styled.div`
background: ${props => props.theme.colors.background};
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
`
const HeaderDiv = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1.4em;
`
import React, {useState} from 'react'
import styled from 'styled-components'
import BasicButton from '../../SharedComponents/BasicButton'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import DateTimePicker from 'react-datetime-picker';
import './DateTimePicker.css';
import './Calendar.css';

export default function UpdateLastRun({showUpdate, lastSuccessfulRun, updateLastRun,originalTemplateGetter }) {
    const configNameOptions = originalTemplateGetter.configName ? originalTemplateGetter.configName : ""
    const initialConfigName = configNameOptions.length > 0 ? configNameOptions[0] : ""
    const [configName, setConfigName] = useState(initialConfigName)
    const [lastRun, setLastRun] = useState("")

    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();
    
    // Convert offset from minutes to hours and minutes
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;
    
    // Construct the formatted timezone offset string
    const offsetSign = offsetMinutes <= 0 ? '+' : '-'; // Adjusted sign here
    const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesPart.toString().padStart(2, '0')}`;

    const lastRunLocal = lastRun + formattedOffset

    const dateValue = lastRun ? lastRun : now

    const updateLastRunTime = (value) => {
        var localISOTime = (new Date(value - (offsetMinutes * 60000))).toISOString().slice(0, -1);
        setLastRun(localISOTime)
        
    }

    const updateConfigName = (value) => {
        setConfigName(value)
    }

    const configNameForUpdate = configName ? configName.value : ""

    if (!showUpdate) {
        return null
    }
  return (
    <Container>
        <Header>Update Last Run</Header>
        <DateDiv>
        <DateTimePicker disableClock={true} format="yyyy-MM-dd HH:mm" locale="sv-SV" value={dateValue} onChange={(e) => updateLastRunTime(e)}/>
        </DateDiv>
        {configNameOptions.length > 0 ? 
            <SelectField width="210px"  label={`Integration type`} options={configNameOptions} fieldValue={configName} handleSelect={updateConfigName} attName="importValueType" margin="0 auto 0 auto" /> : null}
        <BasicButton fontSize="1.1em" background="primary" label="Save" width="100px" height="30px" margin="20px auto 10px auto" handleClick={() => updateLastRun(lastRunLocal,configNameForUpdate)} />
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.grey};
    width: 30%;
    height: 23%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 35%;
    left: 40%;
    border-radius: 30px;
`
const Header = styled.div`
    color: ${props => props.theme.colors.primary};
    text-align: center;
    margin: 10px auto 30px auto;
    font-size: 1.5em;
    display: inline;
`

const DateDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    margin: auto;
    font-size: 1em;
`
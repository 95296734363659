import React from 'react'
import styled from 'styled-components'
import InputField from '../SharedComponents/InputField'
import SelectFieldMulti from '../SharedComponents/SelectFieldMulti'
import SearchInput from '../SharedComponents/SearchInput'

export default function SearchBar({ statusFilter, setStatusFilter, filterIntegrations, integrationTypeOptions, integrationTypeFilter, setIntegrationTypeFilter, searchInput, setSearchInput }) {

    const statusOptions = [{ label: "All", value: "" }, { label: "Production", value: "production" }, { label: "Testing", value: "testing" }, { label: "Development", value: "development" }]

    const handleFilterChange = (e, index, attName) => {
        if (attName === "search") {
            setSearchInput(e)
            filterIntegrations(e, "search")
        }
        else {
            let filterValue = e.length > 0 ? e : [{ label: "All", value: "" }]
            if (filterValue.length === 2 && filterValue[0].value === "") {
                filterValue.shift()
            }
            if (e && attName === "status") {
                setStatusFilter(filterValue)
                filterIntegrations(filterValue, "status")
            } else if (e && attName === "integrationType") {
                setIntegrationTypeFilter(filterValue)
                filterIntegrations(filterValue, "integrationType")
            }
        }

    }

    return (
        <Container>
            <SearchInput label='Search for Customer or Integration' attName='search' handleChange={handleFilterChange} width="300px" margin="auto 10px auto 10px" />
            <SelectFieldMulti width="300px" label='Integration' options={integrationTypeOptions} fieldValue={integrationTypeFilter} handleSelect={handleFilterChange} attName="integrationType" margin="auto 10px auto 10px" />
            <SelectFieldMulti width="300px" label='Status' options={statusOptions} fieldValue={statusFilter} handleSelect={handleFilterChange} attName="status" margin="auto 10px auto 10px" />

        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    padding: 0 20px 0 20px;
    height: 100px;
    border-radius: 30px;
    background: ${props => props.theme.colors.elevation1};
    align-self: center;
    margin 5px auto 10px auto;
    z-index: 1;
`
import React from 'react'
import styled  from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import {Edit} from "@styled-icons/fluentui-system-regular/Edit"
import {Trash} from "@styled-icons/bootstrap/Trash"
import {Copy} from "@styled-icons/fluentui-system-regular/Copy"

export default function DeltaReportTableField({item,editField, index, deleteField, schemaPage, copyField, setFieldBoolean}) {

    const colors = colorScheme.night
    const columName = item.columnName ? item.columnName : item.c1Field.label
  return (
    <TableFieldContainer colors={colors} >
      <RowField colors={colors} width="0.5%">
      {index + 1}
    </RowField> 
      {item.fieldType === 'logic' ? 
      <RowField colors={colors} width="24%">
      C1 field
    <FieldName colors={colors}>Custom logic</FieldName>
    </RowField> : 
      item.fieldType === 'hardCoded' ? 
        <RowField colors={colors} width="24%">
        Hardcoded value
      <FieldName colors={colors}>{item.hardCodedValue}</FieldName>
      </RowField> : 
      item.fieldType === 'concat' ? 
      <RowField colors={colors} width="24%">
      C1 field
    <FieldName colors={colors}>Concated fields</FieldName>
    </RowField> 
    :
    item.fieldType === 'calculated' ?
    <RowField colors={colors} width="24%">
      C1 field
    <FieldName colors={colors}>Calculated field</FieldName>
    </RowField>
    :
      <RowField colors={colors} width="24%">
      C1 field
    <FieldName colors={colors}>{item.c1Field.label} ({item.c1Field.value})</FieldName>
    </RowField>
    }

    {(item.hasLogic || item.hardCoded) ? 
      <RowFieldValueType colors={colors}>
        Value type
      <FieldName colors={colors}>n/a</FieldName>
      </RowFieldValueType> : 
      <RowFieldValueType colors={colors}>
      Value type
    <FieldName colors={colors}>{item.valueType.label}</FieldName>
    </RowFieldValueType>
  }
      
      
      <RowField colors={colors} width="20%">
        Column name
      <FieldName colors={colors}>{columName}</FieldName>
      </RowField>
      {'eventType' in item ? 
      <RowField colors={colors} width="14%">
        Event
      <FieldName colors={colors}>{item.eventType.label}</FieldName>
      </RowField> :""}
      <SettingBox active={item.alwaysInclude} colors={colors} onClick={() => setFieldBoolean(item, 'alwaysInclude', index)}>Always incl.</SettingBox>
      <SettingBox active={item.validFrom} colors={colors} onClick={() => setFieldBoolean(item, 'validFrom', index)}>Valid from</SettingBox>
      <SettingBox active={item.lastMod} colors={colors} onClick={() => setFieldBoolean(item, 'lastMod', index)}>Last mod.</SettingBox>
      <SettingBox active={item.plannedChange} colors={colors} onClick={() => setFieldBoolean(item, 'plannedChange', index)}>Planned change</SettingBox>
      {('payrollInfo' in item && schemaPage === 'employees') ?
    <SettingBox active={item.payrollInfo} colors={colors} onClick={() => setFieldBoolean(item, 'payrollInfo', index)}>Info-lart</SettingBox> : ""  
    }
      
      <EditIcon onClick={() => editField(index)} colors={colors}/>
      <CopyIcon onClick={() => copyField(index)} colors={colors}/>
      <DeleteIcon onClick={() => deleteField(index)} colors={colors}/>
    </TableFieldContainer>
  )
}

const TableFieldContainer = styled.div`
width: 100%;
height: 100%;
background: ${props => props.theme.colors.elevation1};
margin: 0px auto 0px auto;
border-radius: 30px;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
z-index: 2;
`

const FieldName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.5em;
`
const EditIcon = styled(Edit) `
   width: 2em ;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const DeleteIcon = styled(Trash) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`

const CopyIcon = styled(Copy) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`

const RowField = styled.div`
  display: flex; 
  flex-direction: column;
  color: ${props => props.theme.colors.contrastBlue};
  font-size: 0.8em;
  width: ${props => props.width};
`
const RowFieldValueType = styled.div`
  display: flex; 
  flex-direction: column;
  color: ${props => props.theme.colors.contrastBlue};
  font-size: 0.8em;
  width: 14%;
`

const SettingBox = styled.div`
  background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation3};
  padding: 7px;
  border-radius: 30px;
  color: ${props => props.theme.colors.primary};
  font-size: 0.75em;
  &:hover {
    cursor:pointer;
  }
`
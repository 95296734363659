import { hookstate } from '@hookstate/core';

export const customerState = hookstate({
    
    customerName: '',
    integrationType: '',
    systemName: '',
    reportName: '',
    config : {}
    } 
   )
    
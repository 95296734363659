import React from 'react'
import { styled } from 'styled-components'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import InputField from '../../SharedComponents/InputField'
import { c1State } from '../../../States/c1State'
import { useHookstate } from '@hookstate/core'
import BasicButton from '../../SharedComponents/BasicButton'
import { Trash } from "@styled-icons/bootstrap/Trash"
import InputFieldSubstring from '../../SharedComponents/InputFieldSubstring'
import { fieldOptions } from '../../../globalConfig/fieldOptions'
import CheckboxField from '../../SharedComponents/Checkbox'
import { customerState } from '../../../States/customerState'
import { ConcatedFieldC1FieldModel, ConcatedFieldTextModel, ConcatedFieldIteratorModel } from '../../../classes/FieldTemplateModel'


export default function ConcatedOutput({ concatedOutput, fieldIndex, conditionIndex, fromCondition, selectedFields, schemaPage, fromObject, objectIndex,fieldObj, setFieldToEdit,originalFieldObj }) {
    const c1StateGetter = useHookstate(c1State).get()
    const integrationType = useHookstate(customerState).get().integrationType
    const newSelectedFieldsStructure = !Array.isArray(selectedFields[schemaPage])
    const fieldToUpdate = fromCondition || fromObject ? originalFieldObj : fieldObj
    let targetFieldName = fieldToUpdate.sourceField.value 

    let fieldOptionsList = [{ label: 'Value', value: 'value' }]

    if (!concatedOutput) {
        concatedOutput = []
    }

    const addConcatField = (type) => {
        if (type === 'text') {
            concatedOutput.push(new ConcatedFieldTextModel())
        } else if (type === 'iterator') {
            concatedOutput.push(new ConcatedFieldIteratorModel())
        } else {
            concatedOutput.push(new ConcatedFieldC1FieldModel())
        }

        

        if (fromCondition && fromObject) {
            fieldToUpdate.customLogic[conditionIndex].objectField.fields[objectIndex].concatedOutput = concatedOutput

        } else if (fromCondition) {
            fieldToUpdate.customLogic[conditionIndex].concatedOutput = concatedOutput

        } else if (fromObject) {
            fieldToUpdate.objectField.fields[objectIndex].concatedOutput = concatedOutput
        }
        else {
            fieldToUpdate.concatedOutput = concatedOutput
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldToUpdate)))

    }

    const editConcatField = (choice, index, attName) => {

        concatedOutput[index][attName] = choice

        if (fromCondition && fromObject) {
            fieldToUpdate.customLogic[conditionIndex].objectField.fields[objectIndex].concatedOutput = concatedOutput

        } else if (fromCondition) {
            fieldToUpdate.customLogic[conditionIndex].concatedOutput = concatedOutput

        } else if (fromObject) {
            fieldToUpdate.objectField.fields[objectIndex].concatedOutput = concatedOutput
        }
        else {
            fieldToUpdate.concatedOutput = concatedOutput
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldToUpdate)))

    }


    const deleteConcatField = index => {
        concatedOutput.splice(index, 1)
        
        if (fromCondition && fromObject) {
            fieldToUpdate.customLogic[conditionIndex].objectField.fields[objectIndex].concatedOutput = concatedOutput

        } else if (fromCondition) {
            fieldToUpdate.customLogic[conditionIndex].concatedOutput = concatedOutput

        } else if (fromObject) {
            fieldToUpdate.objectField.fields[objectIndex].concatedOutput = concatedOutput
        }
        else {
            fieldToUpdate.concatedOutput = concatedOutput
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldToUpdate)))
    }

    return (
        <ConcatedOutputDiv>
            <ButtonDiv>
                {integrationType === "entraid" && targetFieldName === "userPrincipalName" ?
                    <>
                        <BasicButton background="primary" label="Add textfield" width="7em" height="32px" margin="auto 5px auto auto" handleClick={() => addConcatField('text')} />
                        <BasicButton background="primary" label="Add C1 field" width="7em" height="32px" margin="auto 5px auto 5px" handleClick={() => addConcatField('field')} />
                        <BasicButton background="primary" label="Add iterator" width="7em" height="32px" margin="auto auto auto 5px" handleClick={() => addConcatField('iterator')} />
                    </>
                    :
                    <>
                        <BasicButton background="primary" label="Add textfield" width="7em" height="32px" margin="auto 5px auto auto" handleClick={() => addConcatField('text')} />
                        <BasicButton background="primary" label="Add C1 field" width="7em" height="32px" margin="auto auto auto 5px" handleClick={() => addConcatField('field')} />
                    </>
                }
            </ButtonDiv>
            <FieldDivs>
                {concatedOutput.map((item, index) => {
                    if (item.value && item.value.type in fieldOptions) {
                        fieldOptionsList = fieldOptions[item.value.type]
                    }
                    return (
                        <FieldDiv>
                            {item.type === 'text' ?
                                <SelectFieldDiv>
                                    <InputField width="140px" value={item.value} attName="value" handleChange={editConcatField} index={index} label={"Text value " + (index + 1)} />
                                    {integrationType === "entraid" && targetFieldName === "userPrincipalName" ?
                                        <InputFieldSubstring value={item.priority} handleChange={editConcatField} index={index} label="Priority" attName="priority" />
                                        : null}
                                </SelectFieldDiv>
                                : null}
                            {item.type === 'field' ?
                                <SelectFieldDivOuter>
                                    <SelectFieldDiv>
                                        <SelectField width="14em" label={"Field value " + (index + 1)} options={c1StateGetter.c1Fields} index={index} attName="value" fieldValue={item.value} handleSelect={editConcatField} margin="0 10px 0 10px" />
                                        <FieldDivs>
                                            <InputFieldSubstring value={item.substringStart} handleChange={editConcatField} index={index} label="Sub str. start" attName="substringStart" />
                                            <InputFieldSubstring value={item.substringEnd} handleChange={editConcatField} index={index} label="Sub str. end" attName="substringEnd" />
                                            {integrationType === "entraid" && targetFieldName === "userPrincipalName" ?
                                                <>
                                                    <InputFieldSubstring value={item.priority} handleChange={editConcatField} index={index} label="Priority" attName="priority" />
                                                    <InputFieldSubstring value={item.separator} handleChange={editConcatField} index={index} label="Separator" attName="separator" />
                                                </>
                                                : null}
                                        </FieldDivs>
                                        {integrationType === "entraid" && targetFieldName === "userPrincipalName" ?
                                            <FieldDivs>
                                                <CheckboxField margin="auto auto auto auto" label="Only priority" checked={item.onlyOnPriority} attName="onlyOnPriority" setOption={editConcatField} index={index} />
                                                <CheckboxField margin="auto auto auto auto" label="Ignore after space" checked={item.ignoreAfterSpace} attName="ignoreAfterSpace" setOption={editConcatField} index={index} />
                                            </FieldDivs> : null}
                                    </SelectFieldDiv>
                                    {integrationType === "entraid" && targetFieldName === "userPrincipalName" ? null :
                                        <SelectField width="11em" label={"Field type " + (index + 1)} options={fieldOptionsList} index={index} fieldValue={item.valueType} attName="valueType" handleSelect={editConcatField} margin="0 10px 0 10px" />
                                    }
                                </SelectFieldDivOuter>
                                : null}
                            {item.type === 'iterator' ?
                                <>
                                    <SelectFieldDiv>
                                        <TextDiv>Iterator</TextDiv>
                                        <InputFieldSubstring value={item.priority} handleChange={editConcatField} index={index} label="Priority" attName="priority" />

                                        <CheckboxField margin="auto auto auto 0px" label="Only priority" checked={item.onlyOnPriority} attName="onlyOnPriority" setOption={editConcatField} index={index} />

                                        <CheckboxField margin="auto auto auto 0px" label="Separate" checked={item.separate} attName="separate" setOption={editConcatField} index={index} />
                                    </SelectFieldDiv> </> : null}

                            <DeleteIcon onClick={() => deleteConcatField(index)} />
                        </FieldDiv>

                    )

                })}
            </FieldDivs>

        </ConcatedOutputDiv>
    )
}

const ConcatedOutputDiv = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 10px;
    margin: 7px auto 0px auto;
    justify-content: flex-start;
    overflow-x: auto;
    width: 95%;
`
const ButtonDiv = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: middle;
    margin: 0px auto 10px auto;

`
const DeleteIcon = styled(Trash)`
    width: 1em ;
    margin-top: 36px;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
const FieldDiv = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 0px 10px 0px;
`
const FieldDivs = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    font-size: 0.9em;
`
const SelectFieldDiv = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
`
const SelectFieldDivOuter = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
`
const TextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.1em;
`

import React, { useState } from 'react'
import { styled } from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig'
import { templateStates } from '../../States/templateState'
import { useHookstate } from '@hookstate/core'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import SelectFieldMulti from '../SharedComponents/SelectFieldMulti'
import { selectedTemplate } from '../../States/selectedTemplate'
import { customerState } from '../../States/customerState'
import { originalTemplate } from '../../States/originalTemplate'
import { c1State } from '../../States/c1State'
import CheckboxField from '../SharedComponents/Checkbox'
import InputField from '../SharedComponents/InputField'
import InfoHoverBox from '../SharedComponents/InfoHoverBox'
import { useNavigate } from "react-router-dom";
import DeltaReportEmployeeFilter from '../DeltaReportFilter/DeltaReportEmployeeFilter'

export default function DeltaReportSettings({settings, reportPage, sheetPage, reportName, sheetName, employeeFilter, overallSettings, language}) {

    const colors = colorScheme.night
    const navigate = useNavigate()
    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    let templateType = useHookstate(selectedTemplate)
    const customerStateGetter = useHookstate(customerState).get()
    const configStateGetter = customerStateGetter.config
    const configStateSetter = useHookstate(customerState).config
    const originalTemplateGetter = useHookstate(originalTemplate).get()
    templateType = templateType.get().templateState
    const templateStateRaw = useHookstate(templateStates[templateType])
    let orgs = []
    try {
        orgs = JSON.parse(JSON.stringify(c1StateGet.lists['8']))
    } catch {

    }

    const splitSettingsToChunks = (chunkSize) => {
        const keys = Object.keys(settings);
        const result = [];
        let currentPart = {};
        let count = 0;

        // Iterate over the keys of the original object
        for (const key of keys) {
            // Add key-value pair to currentPart
            currentPart[key] = settings[key];
            count++;

            // If the currentPart has reached the chunkSize or we've processed all keys
            if (count === chunkSize || count === keys.length) {
                // Add currentPart to the result
                result.push(currentPart);
                // Reset currentPart and count for the next chunk
                currentPart = {};
                count = 0;
            }
        }

        return result;

    }


    const settingsList = Object.keys(settings)

    const [visible, setVisible] = useState(false)

    const setFieldAttribute = (choice, index, attName) => {
        if (choice) {
            settings[attName].value = choice
        } else {
            settings[attName].value = ""
        }
        configStateSetter.reports[reportPage].sheets[sheetPage].settings.set(settings)

    }

    const closeSettings = () => {
        setVisible(!visible)
    }
    
    const changeName = (choice, index, attName) => {
        if (attName === 'customerName') {

            overallSettings.customerName.value = choice
            configStateSetter.settings.set(overallSettings)
        } else if (attName === 'reportName') {
            configStateSetter.reports[reportPage].reportName.set(choice)
        } else if (attName === 'language') {
            language.value = choice
            configStateSetter.reports[reportPage].language.set(language)
        }
         else {
            configStateSetter.reports[reportPage].sheets[sheetPage].sheetName.set(choice)
        
        }

    }

    const changeStatus = (choice, index, attName) => {
        overallSettings.integrationStatus.value = choice
        configStateSetter.settings.set(overallSettings)

    }

    const setCheckBox = (checked, index, attName) => {
        settings[attName].value = checked
        configStateSetter.reports[reportPage].sheets[sheetPage].settings.set(settings)
    }
    return (
        <OuterContainer visible={visible} colors={colors}>

            <Container colors={colors} visible={visible}>
                <TextDiv colors={colors}>Settings</TextDiv>
                <SettingsContainer colors={colors}>
                    
                    <SettingsList>
                    <SettingsHeader colors={colors}>Report and sheet settings</SettingsHeader>
                    <SelectField
                        key={'Integration status'}
                        width="260px"
                        color={colors}
                        label="Integration status"
                        options={overallSettings.integrationStatus.list}
                        fieldValue={overallSettings.integrationStatus.value}
                        handleSelect={changeStatus}
                        attName={"integrationStatus"}
                        margin="7px 10px 7px 10px" />
                    <SelectField
                        index={reportPage}
                        key={'language'}
                        width="260px"
                        color={colors}
                        label={"Report language"}
                        options={language.list}
                        fieldValue={language.value}
                        handleSelect={changeName}
                        attName={"language"}
                        margin="7px 10px 7px 10px" />
                    <InputField label="Report name" colors={colors} value={reportName} handleChange={changeName} attName="reportName" />
                    <InputField label="Sheet name" colors={colors} value={sheetName} handleChange={changeName} attName="sheetName" />
                        {settingsList.map((setting, index) => {
                            const settingObj = settings[setting]
                            let showSetting = true
                            let hasInfo = false
                            try {
                                if ('info' in settingObj) 
                                {
                                    hasInfo = true
                                
                                }
                            } catch {}

                            if (settingObj.hasOwnProperty('showIf')) {
                                if (!settings[settingObj.showIf].value) {
                                    showSetting = false
                                }
                            }
                            if (showSetting) {
                                return (
                                    <SettingDiv>
                                        {
                                            settingObj.type == 'c1Field' ?
                                                <SelectField
                                                    index={index}
                                                    key={index + 'field'}
                                                    width="260px"
                                                    color={colors}
                                                    label={settingObj.label}
                                                    options={c1StateGet.c1Fields}
                                                    fieldValue={settingObj.value}
                                                    handleSelect={setFieldAttribute}
                                                    attName={setting}
                                                    margin="7px 10px 7px 10px" />
                                                : settingObj.type == 'c1MultiField' ?
                                                    <SelectFieldMulti
                                                        index={index}
                                                        key={index + 'field'}
                                                        width="290px"
                                                        color={colors}
                                                        label={settingObj.label}
                                                        options={c1StateGet.c1Fields}
                                                        attName={setting}
                                                        fieldValue={settingObj.value}
                                                        handleSelect={setFieldAttribute}
                                                        margin="0 10px 0 10px" />


                                                    : settingObj.type == 'text' ?
                                                        <InputField
                                                            colors={colors}
                                                            value={settingObj.value}
                                                            handleChange={setFieldAttribute}
                                                            attName={setting}
                                                            label={settingObj.label} />
                                                        : settingObj.type == 'orgField' ?
                                                            <SelectField
                                                                index={index}
                                                                key={index + 'field'}
                                                                width="260px"
                                                                color={colors}
                                                                label={settingObj.label}
                                                                options={c1StateGet.lists.dataProfiles}
                                                                fieldValue={settingObj.value}
                                                                handleSelect={setFieldAttribute}
                                                                attName={setting}
                                                                margin="7px 10px 7px 10px" />

                                                            : settingObj.type == 'orgMultiField' ?

                                                                <SelectFieldMulti
                                                                    index={index}
                                                                    key={index + 'field'}
                                                                    width="290px"
                                                                    color={colors}
                                                                    label={settingObj.label}
                                                                    options={orgs}
                                                                    attName={setting}
                                                                    fieldValue={settingObj.value}
                                                                    handleSelect={setFieldAttribute}
                                                                    margin="0 10px 0 10px" />
                                                                :
                                                                settingObj.type == 'select' ?
                                                                    <SelectField
                                                                        index={index}
                                                                        key={index + 'field'}
                                                                        width="260px"
                                                                        color={colors}
                                                                        label={settingObj.label}
                                                                        options={settingObj.list}
                                                                        fieldValue={settingObj.value}
                                                                        handleSelect={setFieldAttribute}
                                                                        attName={setting}
                                                                        margin="7px 10px 7px 10px" />
                                                                    :

                                                                    <CheckboxField
                                                                        label={settingObj.label}
                                                                        colors={colors}
                                                                        checked={settingObj.value}
                                                                        index={index}
                                                                        attName={setting}
                                                                        setOption={setCheckBox}
                                                                    />}

                                        {hasInfo ? <InfoHoverBox colors={colors} text={settingObj.info} /> : ""}
                                    </SettingDiv>

                                )
                            }
                        })}
                    </SettingsList>
                    <DeltaReportEmployeeFilter colors={colors} configStateSetter={configStateSetter} employeeFilter={employeeFilter} reportPage={reportPage} sheetPage={sheetPage} />
                </SettingsContainer>

            </Container>

        </OuterContainer>
    )
}

const Container = styled.div`
    padding: 12px 14px 12px 0px;
    overflow: auto;
    border-radius: 30px;
    width: 96.3%;
    margin: 0 auto 0 1px;
    justify-content: center;
    
`
const SettingsContainer = styled.div`
    background: ${props => props.theme.colors.elevation1};
    padding: 8px;
    border-radius: 30px;
    overflow: auto;
    width: 100%;
    height: 92%;
    margin: 0px auto 0 auto;
    display: flex;

`
const SettingsList = styled.div`
    padding: 5px;
    border-radius: 30px;
    height: 92%;
    margin: 0px auto 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    aligh-items: flex-start;
    flex-wrap: wrap;
    
`

const OuterContainer = styled.div`
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

const TextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.9em;
    text-align: center;
    margin: -20px auto 10px auto;
    font-weight: 600;

`

const SettingsHeader = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.5em;
    text-align: left;
    margin: 0px auto 14px 0px;
    font-weight: 600;

`

const SettingDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 30px 0px 10px;

`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
`
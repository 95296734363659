import React from 'react'
import styled from 'styled-components'
import { c1State } from '../../States/c1State'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import { useHookstate } from '@hookstate/core'
import { operatorOptions } from '../../globalConfig/fieldOptions'
import SelectFieldMulti from '../SharedComponents/SelectFieldMulti'
import InputField from '../SharedComponents/InputField'
import {Trash} from "@styled-icons/bootstrap/Trash"

export default function Logic({field, fieldIndex, employeeFilter, conditionIndex, colors, configStateSetter, reportPage, sheetPage}) {
    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()

    const updateFilterField = (choice, index, attName) => {
        if (choice) {
            employeeFilter[conditionIndex].fields[fieldIndex][attName] = choice
        } else {
            employeeFilter[conditionIndex].fields[fieldIndex][attName] = ""
        }
        if (field.field.value in c1StateGet.lists) {
            field.hasValueFromList = true
        } else {
            field.hasValueFromList = false
        }
        configStateSetter.reports[reportPage].sheets[sheetPage].employeeFilter.set(employeeFilter)
    }

    const deleteField = () => { 
        employeeFilter[conditionIndex].fields.splice(fieldIndex,1)
        configStateSetter.reports[reportPage].sheets[sheetPage].employeeFilter.set(employeeFilter)
    }

    const emptyOperator = field.operator.value === "!0" || field.operator.value === "0" ? true : false
    const operators = field.field.type === 'DATE' ? operatorOptions.DATE : operatorOptions.DEFAULT
    const hasList =  (field.field.value in c1StateGet.lists && field.operator.value !== 'con' &&  field.operator.value !== '!con' && !emptyOperator) ? true : false
  return (
    <ConditionDiv>
            <FiltersDiv>
            <SelectField width="220px" color={colors} label="Field" options={c1StateGet.c1Fields} index={fieldIndex} attName="field" fieldValue={field.field} handleSelect={updateFilterField} margin="0 10px 0 10px" />
            <SelectField width="220px" color={colors} label="Operator" options={operators} index={fieldIndex} attName="operator" fieldValue={field.operator} handleSelect={updateFilterField} margin="0 10px 0 10px" />
            {hasList ? 
           <SelectFieldMulti width="290px" color={colors} label="Field" options={c1StateGet.lists[field.field.value]} attName="listValues" fieldValue={field.listValues} mulit={true} handleSelect={updateFilterField} margin="0 10px 0 10px" /> 
           :  !emptyOperator ? 
            <InputField colors={colors} value={field.value} handleChange={updateFilterField} attName="value" label="Value" />
            : ""
    }
            <DeleteIcon colors={colors} onClick={e => deleteField()}/>
            </FiltersDiv>
    </ConditionDiv>
  )
}
const ConditionDiv = styled.div`

`

const FiltersDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-evenly;

`
const DeleteIcon = styled(Trash) `
   width: 1.2em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
    right: 0;
    margin: auto auto 12px auto;
`
import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import InputField from '../SharedComponents/InputField';
import CheckboxField from '../SharedComponents/Checkbox';
import { Plus } from "@styled-icons/bootstrap/Plus"
import AddMappingPagePopUp from './AddMappingPagePopUp';
import AddSecretPopUp from './AddSecretPopUp';
import { IntegrationTemplateModel } from '../../classes/IntegrationTemplateModel';
import BasicButton from '../SharedComponents/BasicButton';
import { messageState } from '../../States/messageState';
import { useHookstate } from '@hookstate/core';
import ErrorPopup from '../SharedComponents/ErrorPopUp';
import LoadingPage from '../SharedComponents/LoadingPage';
import BackendApiClient from '../../services/backendApiClient';

export default function AddIntegrationTemplate() {
    const messageStateSetter = useHookstate(messageState)

    const [addAttributeVisible, setAttributeVisible] = useState(false)
    const [addSecretVisible, setAddSecretVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [integrationName, setIntegrationName] = useState('')
    const navigate = useNavigate()
    const backendApiClient = new BackendApiClient()
    const [templateOptions, setTemplateOptions] = useState({
        validFrom: false,
        plannedChange: false,
        alwaysInclude: false,
        eventType: false,
        selectedFields: [],
        configNameOptions: [],
        secrets: [
            {
                "name": "c1Tenant",
                "secret": false,
                "value": ""
            },
            {
                "name": "c1ClientId",
                "secret": true,
                "value": ""
            },
            {
                "name": "c1ClientSecret",
                "secret": true,
                "value": ""
            }
        ],
    })

    const changeInteGrationName = (value) => {
        setIntegrationName(value)
    }

    const setCheckboxValues = (value, index, attName) => {
        const newOptions = { ...templateOptions }
        newOptions[attName] = value
        setTemplateOptions(newOptions)
    }

    const uploadToBlob = async () => {
        const template = new IntegrationTemplateModel(templateOptions.selectedFields, templateOptions.secrets, templateOptions.validFrom, templateOptions.plannedChange, templateOptions.alwaysInclude, templateOptions.eventType)
        setLoading(true)

        const uploadResult = await backendApiClient.post(`template/integrationtemplates/${integrationName}/template.json`, template)
        if (uploadResult.status === 200)  {
            messageStateSetter.set('Template uploaded to blob')
        } else {
            messageStateSetter.set('Failed to upload template to blob: ' + uploadResult.error)
        }
        setLoading(false)
    }
    if (loading) {
        return (
            <LoadingPage />
        )
    }
    return (
        <Container>
            <ErrorPopup />
            <AddMappingPagePopUp visible={addAttributeVisible} setVisible={setAttributeVisible} templateOptions={templateOptions} setTemplateOptions={setTemplateOptions} />
            <AddSecretPopUp visible={addSecretVisible} setVisible={setAddSecretVisible} templateOptions={templateOptions} setTemplateOptions={setTemplateOptions} />
            <BigHeaderDiv>Add new integration template</BigHeaderDiv>
            <InnerContainer>

            <HeaderDiv><b>1.</b> Integration name</HeaderDiv>
            <InputField placeholder="Integration name" margin="5px auto 0px auto" value={integrationName} handleChange={changeInteGrationName} />
            <HeaderDiv><b>2.</b> Available attributes</HeaderDiv>
            <CheckBoxDiv>
                <CheckboxField
                    label="Valid from"
                    checked={templateOptions.validFrom}
                    attName="validFrom"
                    setOption={setCheckboxValues}
                    margin="0px auto 0px 0px"
                />
                <CheckboxField
                    label="Planned change"
                    checked={templateOptions.plannedChange}
                    attName="plannedChange"
                    setOption={setCheckboxValues}
                    margin="0px auto 0px 0px"
                />
                <CheckboxField
                    label="Always include"
                    checked={templateOptions.alwaysInclude}
                    attName="alwaysInclude"
                    setOption={setCheckboxValues}
                    margin="0px auto 0px 0px"
                />
                <CheckboxField
                    label="Event- / payload type"
                    checked={templateOptions.eventType}
                    attName="eventType"
                    setOption={setCheckboxValues}
                    margin="0px auto 0px 0px"
                />
            </CheckBoxDiv>
            <HeaderDiv><b>3.</b> Available mapping pages</HeaderDiv>
            <MappingPagesDiv>

                {templateOptions.selectedFields.map((field, index) => {
                    return (
                        <MappingPageName>{field.label} - {field.type}</MappingPageName>
                    )
                })}
            </MappingPagesDiv>
            <PlusIcon onClick={() => setAttributeVisible(true)}/>
            <HeaderDiv><b>4.</b> Secrets</HeaderDiv>
            <MappingPagesDiv>

            {templateOptions.secrets.map((secret, index) => {
                return (
                    <MappingPageName>{secret.name}</MappingPageName>
                )
            })}
            </MappingPagesDiv>
            <PlusIcon onClick={() => setAddSecretVisible(true)}/>
            </InnerContainer>

            <ButtonDiv>
            <BasicButton background="primary" label="Back" width="140px" height="30px" margin="auto 5px 15px auto" handleClick={() => navigate("/optionsmenu")} />
            <BasicButton background="primary" label="Upload template" width="140px" height="30px" margin="auto auto 15px 5px" handleClick={() => uploadToBlob()} />
            
            </ButtonDiv>
        </Container>
    )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ::-webkit-scrollbar{width:4px;height: 2px;};
    ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    margin: 0 auto;
    height: 80%;
    overflow: auto;
`
const BigHeaderDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin: 40px 0 30px 0;
  font-size: 2em;
`
const HeaderDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin: 20px auto 0 auto;
  font-size: 1.4em;
`
const CheckBoxDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30%;
    margin: 10px auto 0 42%;
`
const MappingPagesDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const MappingPageName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    margin: 10px auto 0 auto;
`
const PlusIcon = styled(Plus)`
   width: 2em ;
   margin: 13px auto 2px auto;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
  `
  const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  margin: auto auto 10px auto;
`
import React from 'react'
import styled from 'styled-components'
import SwitchComponent from '../../SharedComponents/Switch'


export default function SwitchBar({options, setBoolean, onColor, fieldObj, fieldTemplate, importMapping}) {

  return (
    <SwitchBarContainer>
        {options.map((option) => {
            if (option.value in fieldTemplate && !importMapping|| importMapping && (option.value === "validFrom" || option.value === "identifier")) {
            return (
            <SwitchComponent
                label={option.label}
                onColor={onColor} 
                option={option} 
                handleChange={() => setBoolean(option.value)} 
                fieldObj={fieldObj}
                checked={fieldObj[option.value]}
            />
            )
            }
        })}
    </SwitchBarContainer>
  )
}

const SwitchBarContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
margin: 0 180px 0 180px;
`
import React from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'
import CheckboxField from '../../SharedComponents/Checkbox'

export default function EditField({fields, setField, isActive, fieldIndex,setEditActive}) {

    let fieldToEdit = {}
    try {
        fieldToEdit = fields[fieldIndex]
    } catch  {
        
    }
    if (isActive) {
        return (
            <Container >
                <HeaderText >Edit Field</HeaderText>
                <InputField
                    label="Label"
                    value={fieldToEdit.label}
                    attName="label"
                    handleChange={setField}
                        />
                <InputField
                    label="Value"
                    value={fieldToEdit.value}
                    attName="value"
                    handleChange={setField}
                        />
                <InputField
                    label="Parent"
                    value={fieldToEdit.parent}
                    attName="parent"
                    handleChange={setField}
                        />
                <InputField
                    label="Parent type"
                    value={fieldToEdit.parentType}
                    attName="parentType"
                    handleChange={setField}
                        />
                <CheckboxField
                    label="required"
                    checked={fieldToEdit.required}
                    attName="required"
                    setOption={setField}
                            />
                <BasicButton background="primary" label="Save" width="110px" height="35px" margin="20px auto 10px auto" handleClick={() => setEditActive(false)} />
            </Container>
          )
    }
  
}

const Container = styled.div`

    background: ${props => props.theme.colors.grey};
    border-radius: 30px;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
    padding: 10px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
`
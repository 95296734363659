import React, { useState } from 'react'
import { styled } from 'styled-components'
import { useHookstate } from '@hookstate/core'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import SelectFieldMulti from '../../SharedComponents/SelectFieldMulti'
import { c1State } from '../../../States/c1State'
import CheckboxField from '../../SharedComponents/Checkbox'
import BasicButton from '../../SharedComponents/BasicButton'
import InputField from '../../SharedComponents/InputField'
import InfoHoverBox from '../../SharedComponents/InfoHoverBox'
import MappingSideNav from '../../IntegrationMapping/MappingSideNav/MappingSideNav'
import { useNavigate } from "react-router-dom";
import LoadingPage from '../../SharedComponents/LoadingPage'
import ErrorPopup from '../../SharedComponents/ErrorPopUp'
import useIntegrationStates from '../../../hooks/useIntegrationStates';
import useLoggedInUser from '../../../hooks/useLoggedInUser'
import BackendApiClient from '../../../services/backendApiClient'

export default function Settings() {
    const navigate = useNavigate()
    const c1StateRaw = useHookstate(c1State)
    const backendApiClient = new BackendApiClient()
    const c1StateGet = c1StateRaw.get()
    const { user } = useLoggedInUser()
    const { originalTemplateGetter,
        customerStateGetter,
        configStateSetter,
        loading,
        setLoading,
        settings,
        messageStateSetter,
        customerIntegrationName
    } = useIntegrationStates()
    const originalTemplateSettingsGetter = originalTemplateGetter.settings

    let orgs = []
    try {
        orgs = JSON.parse(JSON.stringify(c1StateGet.lists['8']))
    } catch {

    }

    const settingsList = Object.keys(settings)

    const [visible, setVisible] = useState(false)

    const setFieldAttribute = (choice, index, attName) => {
        if (choice) {
            settings[attName].value = choice
        } else {
            settings[attName].value = ""
        }
        configStateSetter.settings.set(settings)

    }

    const closeSettings = () => {
        setVisible(!visible)
    }

    const setCheckBox = (checked, index, attName) => {
        settings[attName].value = checked
        configStateSetter.settings.set(settings)
    }
    const uploadConfig = async () => {
        setLoading(true)
        let config = {
            selectedFields: customerStateGetter.config.selectedFields,
            settings: customerStateGetter.config.settings,
            employeeFilter: customerStateGetter.config.employeeFilter ? customerStateGetter.config.employeeFilter : {},
            notes: customerStateGetter.config.notes ? customerStateGetter.config.notes : "",
            audit: customerStateGetter.config.audit ? JSON.parse(JSON.stringify(customerStateGetter.config.audit)) : { createdBy: user.username, createdDate: new Date().toISOString(), lastUpdatedBy: user.username, lastUpdated: new Date().toISOString() }
        }
        config.audit.lastUpdated = new Date().toISOString()
        config.audit.lastUpdatedBy = user.username
        const configName = customerStateGetter.systemName ? `${customerStateGetter.integrationType}-${customerStateGetter.systemName}-config.json` : `${customerStateGetter.integrationType}-config.json`
        const updateResponse = await backendApiClient.post(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerStateGetter.customerName}/${configName}`, config)
        if (updateResponse.status === 200) {
            messageStateSetter.set("Config uploaded")
        } else {
            messageStateSetter.set(updateResponse.error)
        }
        setLoading(false)
    }

    const divideSettingsInSections = () => {
        return settingsList.reduce((sections, setting) => {
            const settingObj = settings[setting];
            const sectionKey = settingObj.section || 'General';

            // Initialize the section array if it doesn't exist
            if (!sections[sectionKey]) {
                sections[sectionKey] = [];
            }

            // Push the current setting into the appropriate section
            sections[sectionKey].push(setting);

            return sections;
        }, {});
    };

    if (loading) {
        return (
            <LoadingPage />
        )
    }
    return (
        <OuterContainer visible={visible} >
            <ErrorPopup />
            <MappingSideNav navigate={navigate} />

            <Container visible={visible}>
                <HeaderDiv >Settings</HeaderDiv>
                <CustomerName >{customerIntegrationName}</CustomerName>
                <SettingsContainer >
                    <SettingsList>
                        {Object.keys(divideSettingsInSections()).map((section, sectionIndex) => {
                            const wrapSetting = Object.keys(divideSettingsInSections()).length > 1 ? "nowrap" : "wrap";
                            return (
                                <SectionDiv wrapSetting={wrapSetting}>
                                    <SectionHeader>{section}</SectionHeader>
                                    {divideSettingsInSections()[section].map((setting, index) => {
                                        const settingObj = settings[setting];
                                        let showSetting = true;
                                        const requiredSetting = (originalTemplateSettingsGetter[setting] && originalTemplateSettingsGetter[setting].required) ? "*" : "";
                                        const hasInfo = 'info' in settingObj;

                                        if (settingObj.hasOwnProperty('showIf')) {
                                            if (!settings[settingObj.showIf].value) {
                                                showSetting = false;
                                            }
                                        }

                                        if (showSetting) {
                                            return (
                                                <SettingDiv wrapSetting={wrapSetting} key={index}>
                                                    {settingObj.type === 'c1Field' ?
                                                        <SelectField
                                                            index={index}
                                                            key={index + 'field'}
                                                            width="260px"

                                                            label={settingObj.label}
                                                            options={c1StateGet.c1Fields}
                                                            fieldValue={settingObj.value}
                                                            handleSelect={setFieldAttribute}
                                                            attName={setting}
                                                            margin="10px 10px 10px 10px" />
                                                        : settingObj.type === 'c1MultiField' ?
                                                            <SelectFieldMulti
                                                                index={index}
                                                                key={index + 'field'}
                                                                width="290px"

                                                                label={settingObj.label}
                                                                options={c1StateGet.c1Fields}
                                                                attName={setting}
                                                                fieldValue={settingObj.value}
                                                                handleSelect={setFieldAttribute}
                                                                margin="0 10px 0 10px" />


                                                            : settingObj.type === 'text' ?
                                                                <InputField

                                                                    value={settingObj.value}
                                                                    handleChange={setFieldAttribute}
                                                                    attName={setting}
                                                                    label={settingObj.label} />
                                                                : settingObj.type === 'orgField' ?
                                                                    <SelectField
                                                                        index={index}
                                                                        key={index + 'field'}
                                                                        width="260px"

                                                                        label={settingObj.label}
                                                                        options={c1StateGet.lists.dataProfiles}
                                                                        fieldValue={settingObj.value}
                                                                        handleSelect={setFieldAttribute}
                                                                        attName={setting}
                                                                        margin="10px 10px 10px 10px" />

                                                                    : settingObj.type === 'orgMultiField' ?

                                                                        <SelectFieldMulti
                                                                            index={index}
                                                                            key={index + 'field'}
                                                                            width="290px"

                                                                            label={settingObj.label}
                                                                            options={orgs}
                                                                            attName={setting}
                                                                            fieldValue={settingObj.value}
                                                                            handleSelect={setFieldAttribute}
                                                                            margin="0 10px 0 10px" />
                                                                        :
                                                                        settingObj.type === 'select' ?
                                                                            <SelectField
                                                                                index={index}
                                                                                key={index + 'field'}
                                                                                width="260px"

                                                                                label={settingObj.label}
                                                                                options={settingObj.list}
                                                                                fieldValue={settingObj.value}
                                                                                handleSelect={setFieldAttribute}
                                                                                attName={setting}
                                                                                margin="10px 10px 10px 10px" />
                                                                            :

                                                                            <CheckboxField
                                                                                label={settingObj.label}

                                                                                checked={settingObj.value}
                                                                                index={index}
                                                                                attName={setting}
                                                                                setOption={setCheckBox}
                                                                            />}
                                                    <RequiredSettings>{requiredSetting}</RequiredSettings>
                                                    {hasInfo && <InfoHoverBox text={settingObj.info} />}
                                                </SettingDiv>
                                            );
                                        }
                                        return null;
                                    })}
                                </SectionDiv>
                            );
                        })}

                    </SettingsList>
                </SettingsContainer>
                <ButtonDiv>
                    <BasicButton background="primary" label="Back" width="110px" height="30px" margin="auto 10px auto 0px" handleClick={() => navigate('/optionsmenu')} />
                    <BasicButton background="primary" label="Save config" width="110px" height="30px" margin="auto 0px auto 10px" handleClick={() => uploadConfig()} inactive={!user.roles.includes("admin")}/>
                </ButtonDiv>
            </Container>

        </OuterContainer>
    )
}

const Container = styled.div`

width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
    
`
const SettingsContainer = styled.div`
height: 83%;
width: 96%;
margin: auto auto auto 15px;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: column;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};  
`
const SettingsList = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
    
`

const OuterContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`


const SettingDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0px 30px 0px 10px;

`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 60px;
  margin: auto;
  align-self: center;
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1.4em;
`
const HeaderDiv = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const RequiredSettings = styled.div`
    color: ${props => props.theme.colors.contrastRed};
    font-size: 1.7em;
    margin: auto 0px auto 0px;
`
const SectionHeader = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.7em;
    margin: 5px 0 10px 0;
`
const SectionDiv = styled.div`
    padding: 5px;
    border-radius: 30px;
    height: 84%;
    margin: 10px 10px 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: ${props => props.wrapSetting};
    width: 100%; /* Allows the div to take full width of the container */
`;
import React from 'react'
import { styled } from 'styled-components'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import InputField from '../SharedComponents/InputField'
import { c1State } from '../../States/c1State'
import { useHookstate } from '@hookstate/core'
import BasicButton from '../SharedComponents/BasicButton'
import { colorScheme } from '../../globalConfig/globalConfig'
import {Trash} from "@styled-icons/bootstrap/Trash"
import InputFieldSubstring from '../SharedComponents/InputFieldSubstring'
import { fieldOptions } from '../../globalConfig/fieldOptions'


export default function DeltaReportConcatedOutput({concatedOutput, configStateSetter, reportPage, sheetPage,fieldIndex, fromCondition, conditionIndex}) {

    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    const colors = colorScheme.night

    let fieldOptionsList = [{ label: 'Value', value: 'value' }]
    

    const addConcatField = (type) => {
        if (type === 'text') {
            concatedOutput.push({
                type: 'text',
                value: ''
            })
        } else {
            concatedOutput.push({
                type: 'field',
                value: '',
                substringStart: "",
                substringEnd: "",
                valueType: {value: 'value', label: 'Value'}
            })
        }

        if (fromCondition) {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].concatedOutput.set(concatedOutput)
        } else {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].concatedOutput.set(concatedOutput)
        }
 
    }

    const editConcatField = (choice, index) => {
        concatedOutput[index].value = choice
        if (fromCondition) {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].concatedOutput.set(concatedOutput)
        } else {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].concatedOutput.set(concatedOutput)
        }

    }

    const editConcatValueType = (choice, index) => {
        concatedOutput[index].valueType = choice

        if (fromCondition) {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].concatedOutput.set(concatedOutput)
        } else {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].concatedOutput.set(concatedOutput)
        }
    }

    const editSubstring = (choice, index, attName) => {
        concatedOutput[index][attName] = choice
        if (fromCondition) {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].concatedOutput.set(concatedOutput)
        } else {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].concatedOutput.set(concatedOutput)
        }
    }

    const deleteConcatField = index => {
        concatedOutput.splice(index, 1)
        if (fromCondition) {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].concatedOutput.set(concatedOutput)
        } else {
            configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].concatedOutput.set(concatedOutput)
        }
    }

  return (
    <ConcatedOutputDiv>
        <ButtonDiv>
        <BasicButton background="primary" label="Add textfield" width="100px" height="32px" margin="auto auto auto auto" handleClick={() => addConcatField('text')} />
        <BasicButton background="primary" label="Add C1 field" width="100px" height="32px" margin="auto auto auto auto"  handleClick={() => addConcatField('field')} />
        </ButtonDiv>
        <FieldDivs>
        {concatedOutput.map((item, index) => {
            if (item.value.type in fieldOptions) {
                fieldOptionsList = fieldOptions[item.value.type]
            }
            const textField = (item.type === 'text') ? <InputField colors={colors} value={item.value} handleChange={editConcatField} index={index}  label={"Text value " + (index + 1)} /> :
            <SelectFieldDivOuter>
            <SelectFieldDiv>
            <SelectField width="180px" color={colors} label={"Field value " + (index + 1)} options={c1StateGet.c1Fields} index={index} fieldValue={item.value} handleSelect={editConcatField} margin="0 10px 0 10px" />
            <FieldDivs>
            <InputFieldSubstring colors={colors} value={item.substringStart} handleChange={editSubstring} index={index}  label="Sub str. start" attName="substringStart" />
            <InputFieldSubstring colors={colors} value={item.substringEnd} handleChange={editSubstring} index={index}  label="Sub str. end" attName="substringEnd" />
            </FieldDivs>
            </SelectFieldDiv>
            <SelectField width="160px" color={colors} label={"Field type " + (index + 1)} options={fieldOptionsList} index={index} fieldValue={item.valueType} handleSelect={editConcatValueType} margin="0 10px 0 10px" />
            </SelectFieldDivOuter>
            return (
                <>
                <FieldDiv>
                {textField}
                <DeleteIcon colors={colors} onClick={() => deleteConcatField(index)} />
                </FieldDiv>
                </>
            )
        })}
        </FieldDivs>
        
    </ConcatedOutputDiv>
  )
}

const ConcatedOutputDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 7px 0 0px 0px;
    justify-content: flex-start;
`
const ButtonDiv = styled.div`
    display: flex; 
    flex-direction: row;
    justify-content: middle;
    width: 21%;
`
const DeleteIcon = styled(Trash) `
    width: 1em ;
    margin-top: 36px;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
const FieldDiv = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
`
const FieldDivs = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
`
const SelectFieldDiv = styled.div`
    display: flex; 
    flex-direction: column;
    align-items: flex-start;
`
const SelectFieldDivOuter = styled.div`
    display: flex; 
    flex-direction: row;
    align-items: flex-start;
`


import BackendApiClient from '../services/backendApiClient'

export const createAudit = async (customerStateGetter,user) => {
    const backendApiClient = new BackendApiClient()
    let hasChanges = false
    const auditLog = {
            updated: {
                selectedFields: {},
                settings: {},
            },
            added: {
                selectedFields: {},
                settings: {},
            },
            deleted: {
                selectedFields: {},
            },
        
        updatedBy: user.username,
        lastUpdated: new Date().toISOString()
        }
    try {


    const configName = customerStateGetter.systemName ? `${customerStateGetter.integrationType}-${customerStateGetter.systemName}-config.json` : `${customerStateGetter.integrationType}-config.json`
    const oldConfig = await backendApiClient.get(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerStateGetter.customerName}/${configName}`).data
    const newConfig = customerStateGetter.config

    Object.keys(newConfig.selectedFields).forEach(schema => {
        const newSchema = Array.isArray(newConfig.selectedFields[schema]) ? newConfig.selectedFields[schema] : newConfig.selectedFields[schema].fields
        const oldSchema = Array.isArray(oldConfig.selectedFields[schema]) ? oldConfig.selectedFields[schema] : oldConfig.selectedFields[schema].fields
        if (oldSchema.length > 0 && oldSchema[0].id !== undefined) {

        const changedFields = compareFields(oldSchema, newSchema)
        if (changedFields.changes) {
            hasChanges = true
            auditLog.updated.selectedFields[schema] = changedFields.updated
            auditLog.added.selectedFields[schema] = changedFields.added
            auditLog.deleted.selectedFields[schema] = changedFields.deleted
        }
    }

    })
    return {auditLog : auditLog, hasChanges: hasChanges}
} catch {
    return {auditLog : auditLog, hasChanges: false}
}
}

const compareFields = (oldFields, newFields) => {
    const output = {
        updated: [],
        added: [],
        deleted: [],
        changes: false
    }

    newFields.forEach(newField => {

        if (oldFields.filter(oldField => oldField.id === newField.id).length === 0) {
            output.added.push(newField)
            output.changes = true
        } else if (JSON.stringify(oldFields.filter(oldField => oldField.id === newField.id)[0]) !== JSON.stringify(newField)) {
            output.updated.push({new: newField, old: oldFields.filter(oldField => oldField.id === newField.id)[0]})
            output.changes = true
        }
    })

    oldFields.forEach(oldField => {
        if (newFields.filter(newField => newField.id === oldField.id).length === 0) {
            output.deleted.push(oldField)
            output.changes = true
        }
    })
    return output
}
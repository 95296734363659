import React, {useState}from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import AddSelectOption from './AddSelectOption';

export default function EditSetting({ setting, setSetting, isActive, setEditActive, editSetting }) {
    
    const [addSelectOptionActive, setAddSelectOptionActive] = useState(false)

    const settingTypes = [
        {value: 'c1Field', label: 'C1 Fields'},
        {value: 'boolean', label: 'Boolean'},
        {value: 'select', label: 'select'},
        {value: 'orgMultiField', label: 'Org multiselect'},
        {value: 'c1MultiField', label: 'Employee multiselect'},
        {value: 'text', label: 'Text'},
    ]

    const settingTypeObj = {
        c1Field: 'C1 Field',
        boolean: 'Boolean',
        select: 'Select',
        orgMultiField: 'Org multiselect',
        c1MultiField: 'Employee multiselect',
        text: 'Text'
    } 

    if (isActive) {
        return (
            <Container >
                <HeaderText >Edit setting</HeaderText>
                <SettingName >{editSetting}</SettingName>
                <InputField
                            
                            label='Label'
                            value={setting.label}
                            attName="label"
                            handleChange={setSetting}
                            width="360px"
                        />

                <SelectField width="260px"  label="Type"  options={settingTypes} fieldValue={{label:settingTypeObj[setting.type], value : setting.type}} handleSelect={setSetting} attName="type" margin="0 10px 0 10px" />
                <InputField
                            
                        label='Info'
                        value={setting.info}
                        attName="info"
                        handleChange={setSetting}
                        width="360px"
                    />
                <InputField
                        
                        label='Section'
                        value={setting.section}
                        attName="section"
                        handleChange={setSetting}
                        width="160px"
                    />
                {setting.type === 'select' ? 
                <>
                    <SelectField width="260px"  label="Default value"  options={setting.list} fieldValue={setting.value} handleSelect={setSetting} attName="value" margin="0 10px 0 10px" /> 
                    <SmallHeaderText >Select options</SmallHeaderText>
                    <SelectOptions>
                    {setting.list.map((item, index) => {
                        return (
                            <SelectOption  >{item.value},</SelectOption>
                        )
                    })}
                    </SelectOptions>
                    <BasicButton background="primary" label="Add option" width="110px" height="35px" margin="10px auto 10px auto" handleClick={() => setAddSelectOptionActive(true)} />
                    <AddSelectOption  active={addSelectOptionActive} setAddSelectOptionActive={setAddSelectOptionActive} setSelectOption={setSetting} list={setting.list} />
                    </>
                    : null
            }

                <BasicButton background="primary" label="Save" width="110px" height="35px" margin="20px auto 10px auto" handleClick={() => setEditActive(false)} />
            </Container>
        )
    }
}

const Container = styled.div`

    background: ${props => props.theme.colors.grey};
    border-radius: 30px;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 35%;
    width: 20%;

    padding: 10px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
`

const SmallHeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.1em;
    margin: 10px 0 10px 0;
`

const SettingName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1em;
    font-style: italic;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px 0 10px 0;
    position: relative;
`
const SelectOptions = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    `

    const SelectOption = styled.div`
    color: ${props => props.theme.colors.primary};
    margin: 0 3px 0 3px;
    `
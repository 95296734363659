import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth : {
        clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    },
    cache : {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false
    },
    system : {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }

};
export const loginRequest = { scopes: [process.env.REACT_APP_SCOPES] };
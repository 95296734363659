import React from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import SelectFieldMulti from '../../SharedComponents/SelectFieldMulti'
import ObjectField from './ObjectField'
import ConditionsList from '../Conditions/ConditionsList'
import ConcatedOutput from '../ConcactedOutPut/ConcatedOutput'
import BasicButton from '../../SharedComponents/BasicButton'
import { fieldOptions } from '../../../globalConfig/fieldOptions'


export default function FieldInput({ fieldObj, setFieldAttribute, integrationType, systemFields, fieldIndex, c1Fields, addCondition, fieldOptionsList, schemaPage, configStateSetter, selectedFields,dataProfileFields,fromCondition, conditionIndex,importMapping, setFieldToEdit,originalFieldObj }) {
    const fieldType = fieldObj.fieldType
    const c1FieldLabel = fromCondition ? "Output value" : "C1 field"
    const harcodedOutputLabel = fromCondition ? "Output value" : "Hard coded value"
    const hardcodedAttirbute = fromCondition ? "textValue" : "hardCodedValue"
    const c1FieldAttName = fromCondition ? "fieldValue" : "c1Field"

    // If importmapping and identfier, C1 field list i changes
    if (importMapping && fieldObj.identifier) {
        c1Fields = [
            { label: 'Employee ID', value: 'employeeId' },
            { label: 'Profile ID', value: 'profileId' },
        ]
    }

    if (fieldType === "c1Field") {
        
        return (
            <Container>
                {
                    (fieldObj.valueType.label === 'Valid from' && integrationType === 'agda') ?
                        <>
                            <SelectFieldMulti width="18em"  label="C1 field" options={c1Fields} fieldValue={fieldObj[c1FieldAttName]} handleSelect={setFieldAttribute} attName={c1FieldAttName} margin="0 10px 0 10px" />
                            <SelectField width="15em"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                        </>
                        :
                        <>
                            <SelectField width="17em"  label={c1FieldLabel} options={c1Fields} fieldValue={fieldObj[c1FieldAttName]} handleSelect={setFieldAttribute} attName={c1FieldAttName} margin="0 10px 0 10px" />
                            {importMapping && integrationType !== "sdworxse" ? null 
                            : 
                            <SelectField width="15em"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                            }
                            {importMapping && integrationType !== "sdworxse" && fieldObj.validFrom ? <SelectField width="15em"  label={"Valid from field"} options={systemFields} fieldValue={fieldObj.validFromField} handleSelect={setFieldAttribute} attName="validFromField" margin="0 10px 0 10px" /> 
                            : null    
                            }
                            {importMapping && fieldObj.c1Field.value == "35"  ? <SelectField width="15em"  label={"Currecy field"} options={systemFields} fieldValue={fieldObj.currency} handleSelect={setFieldAttribute} attName="currency" margin="0 10px 0 10px" /> 
                            : null    
                            }
                        </>
                        
                }
            </Container>
        )
    } else if (fieldType === "manager") {
        
        return (
            <Container>
                {
                    (fieldObj.valueType.label === 'Valid from' && integrationType === 'agda') ?
                        <>
                            <SelectFieldMulti width="260px"  label="C1 field" options={c1Fields} fieldValue={fieldObj[c1FieldAttName]} handleSelect={setFieldAttribute} attName={c1FieldAttName} margin="0 10px 0 10px" />
                            <SelectField width="210px"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                        </>
                        :
                        <>
                            <SelectField width="260px"  label={"Person field (default manager)"} options={c1Fields} fieldValue={fieldObj.managerField} handleSelect={setFieldAttribute} attName="managerField" margin="0 10px 0 10px" />
                            <SelectField width="260px"  label="Field from manager" options={c1Fields} fieldValue={fieldObj[c1FieldAttName]} handleSelect={setFieldAttribute} attName={c1FieldAttName} margin="0 10px 0 10px" />
                            <SelectField width="210px"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                        </>
                }
            </Container>
        )

    
    } else if (fieldType === "logic") {
        return (
            <>
                <ConditionsList
                    selectedFields={selectedFields} 
                    fieldObj={fieldObj} 
                    schemaPage={schemaPage} 
                    conditions={fieldObj.customLogic} 
                    fieldIndex={fieldIndex} 
                    configStateSetter={configStateSetter} 
                    systemFields={systemFields}
                    setFieldToEdit={setFieldToEdit}
                    originalFieldObj={originalFieldObj} 
                    />
                <BasicButton background="primary" label="Add condition" width="8em" height="32px" margin="10px auto 10px auto" handleClick={addCondition} />
            </>
        )
    } else if (fieldType === "concat") {
        return (
            <>
                <ConcatedOutput 
                    schemaPage={schemaPage} 
                    selectedFields={selectedFields} 
                    concatedOutput={fieldObj.concatedOutput} 
                    fieldIndex={fieldIndex} 
                    conditionIndex={conditionIndex} 
                    configStateSetter={configStateSetter} 
                    fromCondition={fromCondition}
                    setFieldToEdit={setFieldToEdit}
                    fieldObj={fieldObj}
                    originalFieldObj={originalFieldObj}
                    />
            </>
        )
    } else if (fieldType === "hardCoded") {
        return (
            <Container>
                <InputField  value={fieldObj[hardcodedAttirbute]} handleChange={setFieldAttribute} attName={hardcodedAttirbute} label={harcodedOutputLabel} />
            </Container>
        )
    } else if (fieldType === "calculated") {
        return (
            <Container>
                <InputField width="260px"  value={fieldObj.calculatedValue} handleChange={setFieldAttribute} attName="calculatedValue" label="Calculated formula" info="Input a formula in this field. Put a <b>#</b> before a field id to use value from field. Use regular operators (*,-,+,/) to perform calculations. Put parenthesis around calculation to calculate it seperately. i.e  <i>#35 * (#40 / 100)</i>. This formula calculates actual salary." />
                {
                    integrationType === 'hr8' ?
                        <SelectField width="15em"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                        : null
                }
            </Container>
        )
    } else if (fieldType === "dataProfile") {
        return (
            <Container>
                <SelectField width="17em"  label="C1 field" options={c1Fields} fieldValue={fieldObj[c1FieldAttName]} handleSelect={setFieldAttribute} attName={c1FieldAttName} margin="0 10px 0 10px" />
                <SelectField width="17em"  label="Data profile field" options={dataProfileFields} fieldValue={fieldObj.dataProfileField} handleSelect={setFieldAttribute} attName="dataProfileField" margin="0 10px 0 10px" />
                <SelectField width="17em"  label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
            </Container>
        )
    } else if (fieldType === "object") {
        return (
            <Container>
                <ObjectField
                    
                    fieldObj={fieldObj}
                    fieldOptionsList={fieldOptionsList}
                    c1Fields={c1Fields}
                    sorurceFields={systemFields}
                    index={fieldIndex}
                    configStateSetter={configStateSetter}
                    selectedFields={selectedFields}
                    schemaPage={schemaPage}
                    fieldOptions={fieldOptions}
                    conditionObj={fromCondition}
                    conditionIndex={conditionIndex}
                    dataProfileFields={dataProfileFields}
                    setFieldToEdit={setFieldToEdit}
                    originalFieldObj={originalFieldObj}
                />
            </Container>
        )
    } else if (fieldType === "upn") {

    }
}
const Container = styled.div`
display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px 0 10px 0;
    width: 100%;
`

import React, { useState } from 'react'
import styled from 'styled-components'
import BasicButton from '../../SharedComponents/BasicButton'
import { c1State } from '../../../States/c1State'
import { useHookstate } from '@hookstate/core';
import { fieldOptions } from '../../../globalConfig/fieldOptions'
import InputField from '../../SharedComponents/InputField'
import SwitchButton from '../../SharedComponents/SwitchButton'
import SwitchBar from './SwitchBar'
import FieldInput from './FieldInput'
import { XCircle } from "@styled-icons/bootstrap/XCircle"
import { ConditionTemplateModel } from '../../../classes/FieldTemplateModel'
import TargetField from './TargetField'
import AddTargetField from './AddTargetField';

export default function FieldEditor({ integrationType, selectedSystem, schemaPage, editIndex, visible, setfieldEditorVisible, configStateSetter, configStateGetter, originalTemplateGetter, settings, fieldToEdit, setFieldToEdit, newField, setNewField }) {
    const c1StateGetter = useHookstate(c1State).get()
    const selectedFields = JSON.parse(JSON.stringify(configStateGetter.selectedFields))
    const newSelectedFieldsStructure = !Array.isArray(selectedFields[schemaPage])
    let fieldObj = fieldToEdit ? fieldToEdit : originalTemplateGetter.fieldTemplate
    let c1Fields = c1StateGetter.c1Fields
    const [showAddField, setShowAddField] = useState(false)
    const [newFieldName, setNewFieldName] = useState("")
    let importMapping = !Array.isArray(configStateGetter.selectedFields[schemaPage]) && configStateGetter.selectedFields[schemaPage].direction === "import"

    try {
        importMapping = settings.importToC1.value && schemaPage.includes("import")
    } catch {
    }

    const systemFields = selectedSystem ? originalTemplateGetter.fields[schemaPage][selectedSystem] : originalTemplateGetter.fields[schemaPage]

    if (schemaPage[0] === 'organization') {
        c1Fields = c1StateGetter.lists['dataProfiles']
    }

    if (newSelectedFieldsStructure) {
        if (selectedFields[schemaPage].c1Endpoint === "absences") {
            c1Fields = c1StateGetter.absenceFields
        } else if (selectedFields[schemaPage].c1Endpoint === "organizations") {
            c1Fields = c1StateGetter.lists['dataProfiles']
        }
    }

    let fieldOptionsList = fieldOptions.DEFAULT
    if (fieldObj.c1Field.type in fieldOptions) {
        fieldOptionsList = fieldOptions[fieldObj.c1Field.type]
    }

    try {
        if (settings.getManagerField.value && fieldObj.c1Field.value === '9') {
            if (settings.mrp.value) {
                fieldOptionsList.push({ label: 'Custom', value: 'profileId' })
            } else {
                fieldOptionsList.push({ label: 'Custom', value: 'employeeId' })
            }

        }
    } catch {

    }

    const setFieldAttribute = (choice, index, attName) => {

        if (choice) {
            if (selectedSystem === "c1headers" && attName === "c1Field") {
                fieldObj["sourceField"] = { label: choice.label, value: choice.label }
            }
            fieldObj[attName] = choice
        } else {
            fieldObj[attName] = { "label": "Choose field", "value": "", "type": "" }
        }
        if (integrationType === 'agda' && schemaPage[0] === 'transactions' && choice === 'object') {
            fieldObj.objectField = originalTemplateGetter.fields.transactionObject
        }

        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }

    const addNewFieldName = () => {
        fieldObj.sourceField = { label: newFieldName, value: newFieldName }
        if (newSelectedFieldsStructure) {
            selectedFields[schemaPage].fields.splice(editIndex, 1, fieldObj)
        } else {
            selectedFields[schemaPage].splice(editIndex, 1, fieldObj)
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        setNewFieldName("")
        setShowAddField(false)
    }

    const updateNewFieldName = (e) => {
        setNewFieldName(e)
    }


    const setFieldBoolean = (fieldName) => {
        fieldObj[fieldName] = !fieldObj[fieldName]
        if (newSelectedFieldsStructure) {
            selectedFields[schemaPage].fields.splice(editIndex, 1, fieldObj)
        } else {
            selectedFields[schemaPage].splice(editIndex, 1, fieldObj)
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }


    const addCondition = () => {
        fieldObj.customLogic.push(new ConditionTemplateModel)
        if (newSelectedFieldsStructure) {
            selectedFields[schemaPage].fields.splice(editIndex, 1, fieldObj)
        } else {
            selectedFields[schemaPage].splice(editIndex, 1, fieldObj)
        }
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }

    const saveField = () => {
        if (newSelectedFieldsStructure) {
            if (newField) {
                selectedFields[schemaPage].fields.push(fieldObj)
            } else {
                selectedFields[schemaPage].fields.splice(editIndex, 1, fieldObj)
            }
        } else {
            if (newField) {
                selectedFields[schemaPage].push(fieldObj)
            } else {
                selectedFields[schemaPage].splice(editIndex, 1, fieldObj)
            }
        }
        configStateSetter.selectedFields.set(selectedFields)
        setNewField(false)
        setfieldEditorVisible(false)
    }

    const closeWindow = () => {
        setNewField(false)
        setfieldEditorVisible(false)
    }

    return (
        <NewFieldPopUpContainer visible={visible} >
            <CloseIcon onClick={() => closeWindow()} />

            <AddTargetField
                showAddField={showAddField}
                newFieldName={newFieldName}
                updateNewFieldName={updateNewFieldName}
                addNewFieldName={addNewFieldName}
            />
            {!importMapping ?
                <SwitchButton
                    handleClick={setFieldAttribute}
                    width="550px"
                    activeButton={fieldObj.fieldType}
                    options={[
                        { label: 'c1Field', value: 'c1Field' },
                        { label: 'Logic', value: 'logic' },
                        { label: 'Concat', value: 'concat' },
                        { label: 'Hardcoded', value: 'hardCoded' },
                        { label: 'Calculated', value: 'calculated' },
                        { label: 'Profile', value: 'dataProfile' },
                        { label: 'Object', value: 'object' },
                        { label: 'Manager', value: 'manager' }
                    ]} /> :
                <SwitchButton
                    handleClick={setFieldAttribute}
                    width="350px"
                    activeButton={fieldObj.fieldType}
                    options={[
                        { label: 'c1Field', value: 'c1Field' },
                        { label: 'Hardcoded', value: 'hardCoded' }

                    ]} />
            }

            <SwitchBar options={[
                { label: 'Alvays include', value: 'alwaysInclude' },
                { label: 'Valid from', value: 'validFrom' },
                { label: 'Planned change', value: 'plannedChange' },
                { label: 'Info-lart', value: 'payrollInfo' },
                { label: 'Identifier', value: 'identifier' },
                { label: 'Only new starter', value: 'onlyNewStart' }]}
                fieldObj={fieldObj}
                onColor="contrastBlue"
                setBoolean={setFieldBoolean}
                fieldTemplate={originalTemplateGetter.fieldTemplate}
                importMapping={importMapping}
            />
            <TargetField
                selectedSystem={selectedSystem}
                integrationType={integrationType}
                systemFields={systemFields}
                fieldObj={fieldObj}
                setFieldAttribute={setFieldAttribute}
                originalTemplateGetter={originalTemplateGetter}
                setShowAddField={setShowAddField}
                showAddField={showAddField}
                importMapping={importMapping}
            />

            {fieldObj.payrollInfo ? <FieldSelectionDiv>
                <InputField width="560px" value={fieldObj.payrollInfoText} handleChange={setFieldAttribute} attName="payrollInfoText" label="Payroll info text" />
                <InputField width="120px" value={fieldObj.lart} handleChange={setFieldAttribute} attName="lart" label="LART" />
            </FieldSelectionDiv> : ""}

            <FieldInput
                fieldObj={fieldObj}
                setFieldAttribute={setFieldAttribute}
                integrationType={integrationType}
                c1Fields={c1Fields}
                fieldOptionsList={fieldOptionsList}
                templateStatesGet={originalTemplateGetter}
                fieldIndex={editIndex}
                systemFields={systemFields}
                addCondition={addCondition}
                configStateSetter={configStateSetter}
                selectedFields={selectedFields}
                schemaPage={schemaPage}
                dataProfileFields={c1StateGetter.lists.dataProfiles}
                importMapping={importMapping}
                setFieldToEdit={setFieldToEdit}
                originalFieldObj={fieldObj}
            />


            <SaveButtonDiv>
                <BasicButton fontSize="1.1em" background="primary" label="Save" width="5em" height="30px" margin="20px auto 15px auto" handleClick={saveField} />
            </SaveButtonDiv>
        </NewFieldPopUpContainer>
    )
}

const NewFieldPopUpContainer = styled.div`
    background: ${props => props.theme.colors.grey};
    left: 13%;
    top: 12%;
    width: 75%;
    max-height: 76%;
    border-radius: 30px;
    visibility: ${props => props.visible ? "visbile" : "hidden"};
    display:flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.13);
    overflow: overlay;
    z-index: 5;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px 0 10px 0;
`

const SaveButtonDiv = styled.div`
    bottom: 0;
    margin-top: auto;
    width: 100%;
`
const CloseIcon = styled(XCircle)`
    position: absolute;
    right: 10px;
    top: 10px;
   width: 1.6em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }

`
const SetObjectName = styled.div`
background: ${props => props.theme.colors.grey};
visibility: ${props => props.showAddField ? "visible" : "hidden"};
box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.13);
border-radius: 30px;
margin: auto;
position: absolute;
top: 30%;
left: 35%;
width: 20%;
z-index: 1000000;
padding: 10px 80px;
display: flex;
flex-direction: column;
align-items: center;
`
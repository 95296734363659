
import Home from './Components/Home/Home';
import React, {useState} from 'react';
import IntegrationSchemaMapping from './Components/IntegrationMapping/IntegrationSchemaMapping';
import GetConfigFromBlob from './Components/GetConfigFromBlob.js/GetConfigFromBlob';
import Options from './Components/Options/Options';
import ImportSchema from './Components/ImportSchema/ImportSchema';
import AddIntegrationTemplate from './Components/AddIntegrationTemplate/AddIntegrationTemplate';
import IntegrationsOverview from './Components/lntegrationsOverview/IntegrationsOverview';
import NewIntegration from './Components/NewIntegration/NewIntegration';
import NewIntegrationOptionsMenu from './Components/NewIntegration/NewIntegrationOptions';
import Notes from './Components/IntegrationMapping/Notes/Notes';
import EditSecrets from './Components/IntegrationMapping/Secrets/EditSecrets';
import OptionsMenu from './Components/Options/OptionsMenu';
import Scheduler from './Components/IntegrationMapping/Scheduler/Scheduler';
import MapImportSchema from './Components/ImportSchema/MapImportSchema';
import ImportSchemaOptions from './Components/ImportSchema/ImportSchemaOptions';
import GeneratePdfDocumentation from './Components/IntegrationMapping/DocumentationPdf/GeneratePdfDocumentation';
import AddNewkeyVault from './Components/IntegrationMapping/Secrets/AddNewkeyVault';
import DeltaReportBuilder from './Components/Deltareport/DeltareportBuilder';
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";
import Settings from './Components/IntegrationMapping/Settings/Settings';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from "@azure/msal-react";
import { loginRequest } from './auth-config';
import LoginPage from './Components/Login/LoginPage';
import Theme from './Theme';
import './App.css';
import TemplateEditor from './Components/IntegrationMapping/TemplateEditor/TemplateEditor';
import TopNavLayout from './Components/TopNav/TopNavLayout';
import Audit from './Components/IntegrationMapping/Audit/Audit';


const WrappedView = ({darkMode, setDarkMode}) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();


  const handleRedirect = () => {
    instance.
      loginRedirect({
        ...loginRequest,
        prompt: 'create'
      })
      .catch((error) => console.error(error));
    ;
  }
  return (
    
    <div>
       
      <AuthenticatedTemplate>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
    <AppContainer>
      <BrowserRouter>
      <Routes>
      <Route element={<TopNavLayout darkMode={darkMode} setDarkMode={setDarkMode}/>}>
      {/* HOME NAVIGATION ROUTES */}
      <Route path="/" element={<Home/>}/>
      <Route path="/options" element={<Options/>}/>
      <Route path="/optionsmenu" element={<OptionsMenu/>}/>
      <Route path="/addtemplate" element={<AddIntegrationTemplate/>}/>
      {/* ---------- */}
      {/* INTEGRATION OVERVIEW ROUTES */}
      <Route path="/integrationoverview" element={<IntegrationsOverview/>}/>
      <Route path="/getconfig" element={<GetConfigFromBlob/>}/>
      {/* ---------- */}
      {/* SETUP NEW INTEGRATION ROUTES */}
      <Route path="/newintegration" element={<NewIntegration/>}/>
      <Route path="/newintegration/options" element={<NewIntegrationOptionsMenu/>}/>
      <Route path="/keyvault" element={<AddNewkeyVault/>}/>
      {/* ---------- */}
      {/* IMPORT FROM MAPPING SCHEMA ROUTES */}
      <Route path="/importschema" element={<ImportSchema/>}/>
      <Route path="/importschema/options" element={<ImportSchemaOptions/>}/>
      <Route path="/mapschema" element={<MapImportSchema/>}/>
      {/* ---------- */}
      {/* DELTA REPORT ROUTES */}
      <Route path="/deltareport" element={<DeltaReportBuilder/>}/>
      {/* ---------- */}
      {/* MAPPING ROUTES */}
      <Route path="/mapping" element={<IntegrationSchemaMapping/>}/>
      <Route path="/settings" element={<Settings/>}/>
      <Route path="/edittemplate" element={<TemplateEditor/>}/>
      <Route path="/scheduler" element={<Scheduler/>}/>
      <Route path="/editsecrets" element={<EditSecrets/>}/>
      <Route path="/pdf" element={<GeneratePdfDocumentation/>}/>
      <Route path="/notes" element={<Notes/>}/>
      <Route path="/audit" element={<Audit/>}/>
      {/* ----------- */}
      </Route>
      </Routes>
      </BrowserRouter>
    </AppContainer>
    </ErrorBoundary>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage handleRedirect={handleRedirect}/>
      </UnauthenticatedTemplate>
    </div>

  );

}

function App({ instance }) {
  const initialDarkModeValue = localStorage.getItem("darkMode") === null ? true : localStorage.getItem("darkMode") === "true";
  const [darkMode, setDarkMode] = useState(initialDarkModeValue);
  const themeMode = darkMode ? 'dark' : 'light'
  return (
    <MsalProvider instance={instance}>
      <Theme mode={themeMode}>
      <WrappedView darkMode={darkMode} setDarkMode={setDarkMode}/>
      </Theme>
    </MsalProvider>
  );
}

export default App;

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
`

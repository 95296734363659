import React, { useState } from 'react'
import styled from 'styled-components'
import BasicButton from '../../SharedComponents/BasicButton'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import { c1State } from '../../../States/c1State'
import { useHookstate } from '@hookstate/core';
import InputField from '../../SharedComponents/InputField'
import { customerState } from '../../../States/customerState';
import { originalTemplate } from '../../../States/originalTemplate';


export default function HogiaEntities(props) {

    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    const configStateGetter = useHookstate(customerState).config.get()
    const configStateSetter = useHookstate(customerState).config
    const originalTemplateStateGetter = useHookstate(originalTemplate).get()
    const selectedFields = JSON.parse(JSON.stringify(configStateGetter.selectedFields))
    let fieldObj = originalTemplateStateGetter.fieldTemplate
    
    if (selectedFields[props.schemaPage][props.index]) {
        fieldObj = JSON.parse(JSON.stringify(configStateGetter.selectedFields[props.schemaPage][props.index]))
    }
    let hasList = false
    try {
        hasList =  (fieldObj.filterField.value in c1StateGet.lists)
        
    } catch {

    }
    
    const setFieldAttribute = (choice, index, attName) => {
        if (choice) {
            if (hasList && attName === "filterValue") {
                fieldObj[attName] = choice.label
            } else {
                fieldObj[attName] = choice
            }
        } else {
            fieldObj[attName] = ""
        }
        selectedFields[props.schemaPage].splice(props.index, 1, fieldObj)
        configStateSetter.selectedFields.set(selectedFields)
    }
    const saveField = () => {
        props.setfieldEditorVisible(false)
    }
    
    return (
        <NewFieldPopUpContainer  visible={props.visible} >
            <FieldSelectionDiv>
            <SelectField width="260px"  label="Filter field" options={c1StateGet.c1Fields} fieldValue={fieldObj.filterField} handleSelect={setFieldAttribute} attName="filterField" margin="0 10px 0 10px" />
            {hasList ? 
            <SelectField width="260px"  label="Filter value" options={c1StateGet.lists[fieldObj.filterField.value]} fieldValue={{label: fieldObj.filterValue, value: fieldObj.filterValue}} handleSelect={setFieldAttribute} attName="filterValue" margin="0 10px 0 10px" />
            : <InputField  value={fieldObj.filterValue} handleChange={setFieldAttribute} attName="filterValue" label="Filter value" /> }
            <InputField  value={fieldObj.filePath} handleChange={setFieldAttribute} attName="filePath" label="File path" />
            <InputField  value={fieldObj.fileName} handleChange={setFieldAttribute} attName="fileName" label="File name" />
            </FieldSelectionDiv>
            <SaveButtonDiv>
                <BasicButton background="primary" label="Save" width="90px" height="30px" margin="20px auto 15px auto" handleClick={saveField} />
            </SaveButtonDiv>
        </NewFieldPopUpContainer>
    )
}

const NewFieldPopUpContainer = styled.div`
    background: ${props => props.theme.colors.grey};
    left: 17%;
    top: 12%;
    width: 66%;

    max-height: 76%;
    border-radius: 30px;
    visibility: ${props => props.visible ? "visbile" : "hidden"};
    display:flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
    overflow: overlay;
    z-index: 5;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    margin: 20px 0 0 0;
`
const FieldSwitchDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 180px 0 180px;
`
const SaveButtonDiv = styled.div`
    bottom: 0;
    margin-top: auto;
    width: 100%;
`
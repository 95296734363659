import React from 'react'
import styled from 'styled-components'
import {Edit} from "@styled-icons/fluentui-system-regular/Edit"

export default function FieldsList({fields, setField,setEdit}) {
    if (Array.isArray(fields)) {
        return (
            <Container  >
                {fields.map( (field,index) => {
                    const required = field.required ? 'Yes' : 'No'
                    return (
                        <Field >
                            <FieldRow >
                                Label
                            <FieldLabel >{field.label}</FieldLabel>
                            </FieldRow>
                            <FieldRow >
                                Value
                            <FieldLabel >{field.value}</FieldLabel>
                            </FieldRow>
                            <FieldRow >
                                Parent
                            <FieldLabel >{field.parent}</FieldLabel>
                            </FieldRow>
                            <FieldRow >
                                Parent type
                            <FieldLabel >{field.parentType}</FieldLabel>
                            </FieldRow>
                            <FieldRow >
                                Required
                            <FieldLabel >{required}</FieldLabel>
                            </FieldRow>
                            
                            <EditIcon  onClick={() => setEdit(index)}/>
                            </Field>
                    )
                })}
            </Container>
          )
    }
  
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 97.5%;
    height: 100%;
    overflow: auto;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    `
const Field = styled.div`
    padding: 10px;
    background: ${props => props.theme.colors.elevation2};
    color: ${props => props.theme.colors.primary};
    border-radius: 30px;
    margin: 7px;
    display: flex;
    flex-direction: row;
    &:hover {
        cursor: pointer;
    }
`
const FieldLabel = styled.div`
    flex: 2;
    font-size: 1.3em;

    color: ${props => props.theme.colors.primary};
`
const FieldRow = styled.div`
    flex: 2;
    display:flex;
    max-width: 20%;
    flex-direction: column;
    font-size: 0.8em;
    color: ${props => props.theme.colors.contrastBlue};
`

const EditIcon = styled(Edit)`
font-size: 1.1em;
margin: auto 7px auto 0px;
flex: 0.07;
color: ${props => props.theme.colors.contrastBlue};

`
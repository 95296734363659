import React from 'react'
import { styled } from 'styled-components'
import { useHookstate } from '@hookstate/core'
import {Trash} from "@styled-icons/bootstrap/Trash"
import { c1State } from '../../States/c1State'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import { colorScheme } from '../../globalConfig/globalConfig'
import BasicButton from '../SharedComponents/BasicButton'
import SwitchComponent from '../SharedComponents/Switch'
import DeltaReportConditionLogic from './DeltareportConditionLogic'
import InputField from '../SharedComponents/InputField'
import SwitchButton from '../SharedComponents/SwitchButton'
import DeltaReportConcatedOutput from './DeltaReportConcatedOutput'
import { fieldOptions } from '../../globalConfig/fieldOptions'
import {Copy} from "@styled-icons/fluentui-system-regular/Copy"

export default function DeltaReportCondition({ condition, fieldIndex, conditionIndex, configStateSetter, conditions, reportPage, sheetPage, lastCondition, fieldObj,selectedFields,systemFields}) {

    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    const colors = colorScheme.night


    let fieldOptionsList = [{ label: 'Value', value: 'value' }]

    if (condition.fieldValue.type in fieldOptions) {
        fieldOptionsList = fieldOptions[condition.fieldValue.type]
        
    }

    const setConditionAttribute = (choice, index, attName) => {
        if (choice) {
            condition[attName] = choice
        } else {
            condition[attName] = ""
        }
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].set(condition)
    }


    const deleteCondition = () => {
        const newConditions = conditions
        newConditions.splice(conditionIndex,1)
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic.set(newConditions)
    }

    const copyCondidtion = () => {
        const newConditions = conditions
        newConditions.splice(conditionIndex,0,conditions[conditionIndex])
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic.set(newConditions)
    }

    const setElse = (e) => {
        condition.else = !condition.else
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].set(condition)
    }

    const addConditionField = () => {
        condition.conditionFields.push(
            {
                operator: { label: "Equals", value: "=" },
                field: "",
                value: "",
                listValues: [],
                hasValueFromList: false,
                dataProfileField : ""
            }
        )
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].set(condition)
    }

    return (
        <OuterContainer>
            <ConditionName colors={colors}>Condition {conditionIndex + 1}</ConditionName>
        <ConditionContainer colors={colors}>
            <HeaderDiv>
                
                <SwitchButton
                handleClick={setConditionAttribute}
                color={colors} 
                width="490px"
                boxWidth="20%"
                activeButton={condition.fieldType}
                options={[
                    {label: 'c1Field', value: 'c1Field'},
                    {label: 'Concat', value: 'concat'},
                    {label: 'Hardcoded', value: 'hardCoded'},
                    {label: 'Calculated', value: 'calculated'},
                    {label: 'Object', value: 'object'},
                    {label: 'Profile', value: 'dataProfile'}
                ]}/>
                <CopyIcon colors={colors} onClick={copyCondidtion}/>
                <DeleteIcon colors={colors} onClick={deleteCondition}/>
            </HeaderDiv>

            {lastCondition ? 
            <SwitchComponent label="Else (default value)" checked={condition.else} handleChange={() => setElse()} onColor={colors.contrastBlue} attName="hardCoded" />
            :    
            ""
        }

            {condition.fieldType === 'concat' ? 

            <DeltaReportConcatedOutput concatedOutput={condition.concatedOutput} configStateSetter={configStateSetter} conditionIndex={conditionIndex} fromCondition={true} reportPage={reportPage} sheetPage={sheetPage} fieldIndex={fieldIndex} />
             :
            condition.fieldType === 'c1Field' ?
                <>
                <SelectedFieldsDiv>
                <SelectField width="220px" color={colors} label="Output value" options={c1StateGet.c1Fields} attName="fieldValue" fieldValue={condition.fieldValue} handleSelect={setConditionAttribute} margin="0 10px 0 10px" />
                <SelectField width="180px" color={colors} label="Field type" options={fieldOptionsList} attName="valueType" fieldValue={condition.valueType} handleSelect={setConditionAttribute} margin="0 10px 0 10px" />
                </SelectedFieldsDiv>
                </>
                :
                condition.fieldType === 'calculated' ? 
                <InputField colors={colors} value={condition.calculatedValue} handleChange={setConditionAttribute} attName="calculatedValue" label="Calculated formula" /> 

                :

                condition.fieldType === 'dataProfile' ? 
                <FieldSelectionDiv>
                <SelectField width="260px" color={colors} label="C1 field" options={c1StateGet.c1Fields} fieldValue={condition.fieldValue} handleSelect={setConditionAttribute} attName="fieldValue" margin="0 10px 0 10px" />
                    <SelectField width="260px" color={colors} label="Data profile field" options={c1StateGet.lists.dataProfiles} fieldValue={condition.dataProfileField} handleSelect={setConditionAttribute} attName="dataProfileField" margin="0 10px 0 10px" />
                    <SelectField width="210px" color={colors} label={"Value type"} options={fieldOptionsList} fieldValue={condition.valueType} handleSelect={setConditionAttribute} attName="valueType" margin="0 10px 0 10px" />
                    </FieldSelectionDiv> 
                :

                <InputField colors={colors} value={condition.textValue} handleChange={setConditionAttribute} attName="textValue" label="Output value" />

                
            }
            
            {
                condition.else? 
                ""
                :
                <>
                <TextDiv colors={colors}>Output above if below condition is true</TextDiv>
            
            {condition.conditionFields.map((conditionField, conditionFieldIndex) => {
                const andText = conditionFieldIndex !== condition.conditionFields.length - 1 ? "AND" : ""
                return (
                    <ConditionDiv>
                        <DeltaReportConditionLogic
                            conditionField={conditionField}
                            fieldIndex={fieldIndex}
                            reportPage={reportPage}
                            sheetPage={sheetPage}
                            conditionIndex={conditionIndex}
                            conditionFieldIndex={conditionFieldIndex}
                            configStateSetter={configStateSetter}
                            conditionFields={condition.conditionFields}
                        />
                        <TextDiv colors={colors}>{andText}</TextDiv>
                    </ConditionDiv>
                )

            })}
            <BasicButton background="primary" fontSize="0.9em" label="Add" width="50px" height="32px" margin="10px auto 10px auto" handleClick={addConditionField} />
            </>
        }
            
        </ConditionContainer>
        </OuterContainer>
    )
}

const ConditionContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 7px 8px 7px 8px;
    background: ${props => props.theme.colors.elevation3};
    margin: 7px 0 7px 0px;
`
const ConditionName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    margin-left: 5px;
`
const HeaderDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 7px 0 0px 0px;
`
const ConditionDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0px 0 0px 0px;
`

const TextDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1em;
    text-align: middle;
    margin: 2px auto 2px auto;
    font-style: italic;
    font-weight: 600;
`

const DeleteIcon = styled(Trash) `
   width: 1.4em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`

const CopyIcon = styled(Copy) `
   width: 1.4em ;
   margin: auto 5px;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`
const OuterContainer = styled.div`

`

const SelectedFieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0px 0 0px 0px;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    margin: 20px 0 10px 0;
`
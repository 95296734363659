import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import styled from 'styled-components'
import { FileText } from '@styled-icons/bootstrap/FileText'
import { CloudUpload } from '@styled-icons/bootstrap/CloudUpload'


export default function DropZone(props) {

    const onDrop = useCallback(acceptedFiles => {
        props.uploadFile(acceptedFiles[0])
      }, [])

    

    const dropZoneText1 = props.fileName ? `${props.fileName}` : 'Drag and drop a file (Excel) here, or click to upload'
    const dropZoneText2 = props.fileName ? `Drag and drop or click to upload a new file` : ''
    const icon = props.fileName ? <FileSpreadsheetIcon/> : <UploadIcon/> 



    const {getRootProps, getInputProps, isDragActive} = useDropzone({accept: { "text/csv": [".csv"], "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" : [".xlsx"], 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : [".docx"]}, onDrop})

    return (
        <DropzoneDiv id='dropZone' {...getRootProps()}>
          <DropInputDiv{...getInputProps()} />
          { isDragActive ?
              <DropTextArea active={isDragActive}> <DropText>Drop the files here ...</DropText></DropTextArea> :
              <DropTextArea active={isDragActive}><DropText>{icon}{dropZoneText1}<br></br><br></br> <i>{dropZoneText2}</i></DropText></DropTextArea>
          }
        </DropzoneDiv>
      )
}

const DropzoneDiv = styled.div`
    width: 30%;
    height: 150px;
    margin: auto auto 10px auto;
    border-radius: 10px;
    `

const DropInputDiv = styled.input`
    vertical-align: middle;
    height: 100%;
    &:hover{
        cursor: pointer;
    }
`
const DropTextArea = styled.div`
    height: 150px;
    display: flex;
    align-items:center;
    justify-content:center;
    background: ${props => props.active ? props.theme.colors.elevation2 : props.theme.colors.elevation1};
    border-radius: 10px;
    border: ${props => `dashed 1px ${props.theme.colors.contrastBlue}`};
    &:hover{
        cursor: pointer;
    }
    
`

const DropText = styled.div`
    margin: auto;
    color: ${props => props.theme.colors.primary}
    
`
const FileSpreadsheetIcon = styled(FileText)`
    width: 2em;
    color: ${props => props.theme.colors.contrastBlue};
    margin-right: 5px;
    `

const UploadIcon = styled(CloudUpload)`
width: 2em;
color: ${props => props.theme.colors.contrastBlue};
margin-right: 5px;
`


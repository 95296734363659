import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'

export default function AddSelectOption({active, setSelectOption, setAddSelectOptionActive, list}) {

    const [optionValue, setOptionValue] = useState('')
    const [optionLabel, setOptionLabel] = useState('')

    const setOption = (choice,index, attName) => {

        if (attName === 'value') {
            setOptionValue(choice)
        } else {
            setOptionLabel(choice)
        }
    }
    const saveOption = () => {
        list.push({label: optionLabel, value: optionValue})
        setSelectOption(list,0 , 'list')
        setAddSelectOptionActive(false)

    }
    if (active) {
  return (
    <Container>
        <HeaderText >Add Select Option</HeaderText>
        <InputField
                           
                            label='Label'
                            value={optionLabel}
                            attName='label'
                            handleChange={setOption}
                        />
    <InputField
                           
                            label='Value'
                            value={optionValue}
                            attName='value'
                            handleChange={setOption}
                        />
    <BasicButton background="primary" label="Save" width="110px" height="35px" margin="10px auto 10px auto" handleClick={() => saveOption()} />
    </Container>
  )
    }
}

const Container = styled.div`

    background: ${props => props.theme.colors.background};
    border-radius: 30px;
    margin: auto;
    position: absolute;
    top: 25%;
    width: 60%;

    padding: 10px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
`
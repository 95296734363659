import React from 'react'
import styled from 'styled-components'
import c1_logo from '../../assets/c1logo.png'
import { useNavigate } from "react-router-dom";
import useIntegrationStates from '../../hooks/useIntegrationStates';

export default function ImportSchemaOptions() {
  const {integrationType} = useIntegrationStates()
  const navigate = useNavigate()
  const mappingNavPath = integrationType === "deltareport" ? "/deltareport" : "/mapping"
  return (
    <Container >

      <OptionDiv>
      <ListOptionBox onClick={() => navigate('/mapschema')} >
        <ListOptionText >
          Import antoher sheet
        </ListOptionText>
      </ListOptionBox>
      <ListOptionBox onClick={() => navigate(mappingNavPath)} >
        <ListOptionText >
          Start mapping
        </ListOptionText>
      </ListOptionBox>
      </OptionDiv>
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Header = styled.div`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin: 0px auto -100px auto;
  font-size: 3.5em;
`
const ListOptionBox = styled.div`
    background: ${props => props.theme.colors.blueElevation1};
    width: 230px;
    height: 180px;
    margin: 20px 10px 0 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 4px 1px rgba(0, 180, 200, 0.03);
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.blueElevation2};
        font-size: 1.1em;
    }
`
const ListOptionText = styled.div`

    color: ${props => props.theme.colors.contrastBlue};
    text-align: center;
    font-size: 1.6em;
    padding: 8px;
    
`
const OptionDiv = styled.div`
    margin: auto auto auto auto;
    display: flex;
    flex-direction: row;
    align-self: center;
`
const C1Logo = styled.img.attrs({
  src: c1_logo
})`
width: 100px;
object-fit: contain;
margin: 30px auto 10px auto;

`
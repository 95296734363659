import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import { useHookstate } from '@hookstate/core'
import { c1State } from '../../../States/c1State'
import { fetchFieldsAndLists } from '../../../services/getC1Data'
import BasicButton from '../../SharedComponents/BasicButton'
import { useNavigate } from 'react-router-dom'
import LoadingPage from '../../SharedComponents/LoadingPage'
import ErrorPopup from '../../SharedComponents/ErrorPopUp'
import { messageState } from '../../../States/messageState'
import { customerState } from '../../../States/customerState'

export default function AddNewkeyVault() {
    const navigate = useNavigate()
    const c1StateRaw = useHookstate(c1State)
    const messageStateRaw = useHookstate(messageState)
    const integrationType = useHookstate(customerState).get().integrationType
    const [loading, setLoading] = useState(false)

    const setCredential = (value, index, attName) => {
        c1StateRaw[attName].set(value)
    }

    const handleNext = async () => {
        setLoading(true)
        const response = await fetchFieldsAndLists(c1StateRaw)
        if (!response[0]) {
            setLoading(false)
            messageStateRaw.set(response[1])
        } else {
            setLoading(false)
            if (integrationType === 'deltareport') {
                return navigate('/deltareport')
            }
            navigate('/mapping')
        }
        
    }

    if (loading) {
        return <LoadingPage />
    }
  return (
    <Container>
        <HeaderText margin="20px auto 20px auto" fontSize="2.5em">Add credentials</HeaderText>
        <HeaderText margin="20px auto 40px auto" fontSize="1.6em">Key vault missing, input C1 credentials</HeaderText>
        <InputField
            label="C1 Tenant"
            value={c1StateRaw.clientUrl.get()}
            margin="20px auto 20px auto"
            handleChange={setCredential}
            attName="clientUrl"
            width="270px"
        />
        <InputField
            label="Client ID"
            value={c1StateRaw.clientId.get()}
            margin="20px auto 20px auto"
            handleChange={setCredential}
            attName="clientId"
            width="270px"
        />
        <InputField
            label="Client Secret"
            value={c1StateRaw.clientSecret.get()}
            margin="20px auto auto auto"
            handleChange={setCredential}
            attName="clientSecret"
            width="270px"
        />
        <ButtonDiv>
            <BasicButton
                background="primary"
                label="Next"
                width="90px"
                height="30px"
                margin="auto auto 15px auto"
                handleClick={handleNext}
            />
        </ButtonDiv>
           
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const HeaderText = styled.div`
    font-size: ${props => props.fontSize};
    color: ${props => props.theme.colors.primary};
    margin: ${props => props.margin};
    text-align: center;
`
const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto auto 20px auto;
`
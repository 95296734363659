import React from 'react'
import { styled } from 'styled-components'
import DeltaReportCondition from './DeltareportCondition'


export default function DeltaReportConditionsList({ conditions, fieldIndex, configStateSetter,reportPage,sheetPage, fieldObj, selectedFields}) {

  return (
    <ConditionsListContainer>
      {
        conditions.map((condition, index) => {
          const lastCondition = index === (conditions.length - 1) && index !== 0
          return (
            <DeltaReportCondition
              condition={condition}
              fieldIndex={fieldIndex}
              conditionIndex={index}
              configStateSetter={configStateSetter}
              conditions={conditions}
              reportPage={reportPage}
              sheetPage={sheetPage}
              lastCondition={lastCondition}
              fieldObj={fieldObj}
              selectedFields={selectedFields}
              />
              
          )
        })
      }
    </ConditionsListContainer>
  )
}

const ConditionsListContainer = styled.div`
    display: flex, 
    flex-direction: column, 

`

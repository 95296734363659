import React from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import DeltaReportTableField from './DeltaReportTableField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default function DeltaReportDragNDropTable({selectedFields, editField, reports,configStateSetter, schemaPage, searchInput, showOrgs, copyField, reportPage, sheetPage}) {

    const colors = colorScheme.night
    const draggableColumns = 
    {
      fields : {
      name: 'fields',
      items: selectedFields
    }
  }


  const onDrop = (result) => {
    try {
      const sourceIndex = result.source.index
      const index = result.destination.index
      const field = selectedFields[sourceIndex]
      selectedFields.splice(sourceIndex, 1)
      selectedFields.splice(index, 0,field)
      configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields.set(selectedFields)
    } catch {

    }
    
  }

  const setFieldBoolean = (fieldObj,fieldName, index) => {
    selectedFields = JSON.parse(JSON.stringify(selectedFields))
    fieldObj = JSON.parse(JSON.stringify(fieldObj))
    fieldObj[fieldName] = !fieldObj[fieldName]
    reports[reportPage].sheets[sheetPage].selectedFields.splice(index, 1, fieldObj)
    configStateSetter.reports.set(reports)
}

const deleteField = (index) => {
  selectedFields = JSON.parse(JSON.stringify(selectedFields))
  selectedFields.splice(index, 1)
  reports[reportPage].sheets[sheetPage].selectedFields = selectedFields
  configStateSetter.reports.set(reports)
}


  return (
    <InnerContainer colors={colors}>
      <DragDropContext onDragEnd={(result) => onDrop(result)}>
        {Object.entries(draggableColumns).map(([id,column]) => {
          return (
            <Droppable droppableId={id} key={`droppable-${id}`}>
              {(provided, snapshot) => {
                  return (

                    <FieldsToExportContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      colors={colors}
                      isDraggingOver={snapshot.isDraggingOver}
                      key={`droppableDiv-${id}`}
                    >
                      {column.items.map((item, index) => {
                        let c1FieldLabel = ''
                        let sourceField = ''
                        try {
                          if (item.c1Field.label) {
                            c1FieldLabel = item.c1Field.label ? item.c1Field.label.toLowerCase() : ""
                          } 
                        } catch {
                            
                        }
                        try {
                          if (item.columnNamel) {
                            sourceField = item.columnName ? item.columnName.toLowerCase() : ""
                          } 
                        } catch {
                            
                        }
                        const isInSearch = (!searchInput || c1FieldLabel.includes(searchInput.toLowerCase()) || sourceField.includes(searchInput.toLowerCase()))
                          return (
                            <Draggable key={index} draggableId={`${index}+id`} index={index}>
                              {(provided, snapshot) => {
                                
                                  
                                  
                                      
                                if (isInSearch) {
                                  return (
                                    <TableFieldContainer
                                  colors={colors}
                                  ref={provided.innerRef}
                                    {...provided.draggableProps}
                                   {...provided.dragHandleProps}

                                  >
                                  <DeltaReportTableField
                                    item={item} 
                                    index={index} 
                                    editField={editField} 
                                    deleteField={deleteField}
                                    key={`item-${index}`}
                                    schemaPage={schemaPage}
                                    copyField={copyField}
                                    setFieldBoolean={setFieldBoolean}
                                    ></DeltaReportTableField>
                                  </TableFieldContainer>
                                  )
                                }   
                              }}
    
                            </Draggable>
                          )                   
                      })}
                      {provided.placeholder}
                      
                    </FieldsToExportContainer>
                  )
              }}
            </Droppable>
          )
        })}
        </DragDropContext>
      </InnerContainer>


  )
}



const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0.5% auto auto auto;

  border-top: 1px solid ${props => props.theme.colors.contrastBlue};
  display: flex;
  flex-direction: column;
  overflow: overlay;
  ::-webkit-scrollbar{width:10px;height: 10px;};
  ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};

`

const FieldsToExportContainer = styled.div`

    
`

const TableFieldContainer = styled.div`
width: 100%;
height: 6.6vh;

margin: 5px auto 5px auto;
border-radius: 30px;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
z-index: 2;
`
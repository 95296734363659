import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import MappingSideNav from '../MappingSideNav/MappingSideNav';
import LoadingPage from '../../SharedComponents/LoadingPage';
import ErrorPopup from '../../SharedComponents/ErrorPopUp';
import ScheduleList from './ScheduleList';
import BasicButton from '../../SharedComponents/BasicButton';
import UpdateLastRun from './UpdateLastRun';
import TestRun from './TestRun';
import moment from 'moment'
import useSchedules from '../../../hooks/useSchedules';
import BackendApiClient from '../../../services/backendApiClient';
import useLoggedInUser from '../../../hooks/useLoggedInUser';


export default function Scheduler() {
    const {
        schedule,
        setSchedule,
        lastRun,
        setLastRun,
        editActive,
        setEditActive,
        editIndex,
        setEditIndex,
        showUpdate,
        setShowUpdate,
        showTestRun,
        setShowTestRun,
        messageStateSetter,
        customerName,
        systemName,
        integrationType,
        customerIntegrationName,
        loading,
        setLoading,
        originalTemplateGetter,
        integrationTypeName
      } = useSchedules()
    const {user} = useLoggedInUser()
    const navigate = useNavigate()
    const backendApiClient = new BackendApiClient()
    let filteredSchedule = schedule.filter(item => item.customerName === customerName && item.integrationType === integrationType && (item.configName === systemName || !item.configName || originalTemplateGetter.configName))

    const editSchedule = (choice, index, attName) => {
        const configNameOption = attName.split('.')[1] ? attName.split('.')[1] : systemName
        attName = attName.split('.')[0]
        const scheduleIndex = schedule.findIndex(x => x.customerName === customerName && x.integrationType === integrationType && (x.configName === configNameOption || !x.configName))
        const newSchedule = JSON.parse(JSON.stringify(schedule))
        if (attName === "isActive") {   
            if (choice.value === "Yes") {
                choice = true
            } else {
                choice = false
            }
        } 
        if (attName === "configName") {
            newSchedule[scheduleIndex].configName = choice
        } else {
            newSchedule[scheduleIndex].schedule[index][attName] = choice
        }
        
        setSchedule(newSchedule)
    }

    const addSchedule = (configOptionName) => {
        const newSchedule = JSON.parse(JSON.stringify(schedule))
        const scheduleTemplate = { cronExpression: '* * * * *', isActive: false, timeZoneId: 'Europe/Stockholm' }
        if (integrationType === "populum") {
            scheduleTemplate.cronExpression = '0 1 * * 6'
        }

        if (configOptionName) {
            filteredSchedule = schedule.filter(item => item.customerName === customerName && item.integrationType === integrationType && item.configName === configOptionName)
        }

        const ScheduleConfigName = configOptionName ? configOptionName : systemName
        if (filteredSchedule.length === 0) {

            newSchedule.push({ customerName: customerName, integrationType: integrationType, configName: ScheduleConfigName, schedule: [scheduleTemplate] })
        } else {
            let scheduleIndex = schedule.findIndex(x => x.customerName === customerName && x.integrationType === integrationType)
            if (configOptionName) {
                scheduleIndex = schedule.findIndex(x => x.customerName === customerName && x.integrationType === integrationType && x.configName === configOptionName)
            }
            newSchedule[scheduleIndex].schedule.push(scheduleTemplate)
        }

        setSchedule(newSchedule)
    }

    const uploadSchedule = async () => {
        setLoading(true)
        const response = await new BackendApiClient().post(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/scheduler/integrationrunner.json`, schedule)
        if (response.status === 200) {
            messageStateSetter.set("Schedule uploaded")
            setLoading(false)
        } else {
            messageStateSetter.set('Could not upload schedule: ' + response.error)
            setLoading(false)
        }
    }

    const deleteSchedule = (index,configOptionName) => {
        const configNameOption = configOptionName ? configOptionName : systemName
        const scheduleIndex = schedule.findIndex(x => x.customerName === customerName && x.integrationType === integrationType && x.configName === configNameOption)
        const newSchedule = JSON.parse(JSON.stringify(schedule))
        newSchedule[scheduleIndex].schedule.splice(index, 1)
        if (newSchedule[scheduleIndex].schedule.length === 0) {
            newSchedule.splice(scheduleIndex, 1)
        }
        setSchedule(newSchedule)
    }

    const updateLastRunFunction = async (lastSuccessfulRun,configName) => {
        setShowUpdate(false)
        setLoading(true)
        const rowKey = configName ? `${integrationTypeName}-${configName}` : `${integrationTypeName}`

        const updateLastRunRequest = await backendApiClient.post(`azuretables/integrationinfo/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerName}/${rowKey}/`, { lastRun: lastSuccessfulRun })
        if (updateLastRunRequest.status !== 200) {
            messageStateSetter.set('Could not update last run: ' + updateLastRunRequest.error)
            return setLoading(false)
        }
        setLoading(false)
        if (configName) {
            const lastRunKey = configName.substring(0,1).toUpperCase() + configName.substring(1)
            lastRun[lastRunKey].lastSuccessfulRun = updateLastRunRequest.data.lastRun
        } else {
            lastRun.lastSuccessfulRun = updateLastRunRequest.data.lastRun
        }
        setLastRun(lastRun)
        
    }

    const showTestRunFunction = () => {

        setShowTestRun(!showTestRun)
    }

    const parseDate = (date) => {
       const parsedDate = moment(date).format("YYYY-MM-DD HH:mm:ss")
       if (parsedDate === "Invalid date") {
           return date
       }
         return parsedDate
    }

    const lastSuccessRun = originalTemplateGetter.configName ? lastRun[Object.keys(lastRun)[0]].lastSuccessfulRun : lastRun.lastSuccessfulRun

    if (loading) {
        return <LoadingPage />
    }
    return (
        <OuterContainer>
            <ErrorPopup />
            <MappingSideNav navigate={navigate} />
            <UpdateLastRun 
                showUpdate={showUpdate} 
                setShowUpdate={setShowUpdate} 
                lastSuccessfulRun={lastSuccessRun}
                updateLastRun={updateLastRunFunction}
                originalTemplateGetter={originalTemplateGetter}
                />

            <TestRun
                showTestRun={showTestRun}
                setShowTestRun={setShowTestRun}
                setLoading={setLoading}
                messageStateSetter={messageStateSetter}
                originalTemplateGetter={originalTemplateGetter}
            />
            <Container>
                <HeaderText>Scheduler</HeaderText>
                <CustomerName>{customerIntegrationName}</CustomerName>
                <InnerContainer>
                    <InnerSplitContiner showBorder={true}>
                        <SmallHeaderText>Schedules</SmallHeaderText>
                        <ScheduleList
                            schedules={filteredSchedule}
                            setEditActive={setEditActive}
                            setEditIndex={setEditIndex}
                            editActive={editActive}
                            editIndex={editIndex}
                            editSchedule={editSchedule}
                            deleteSchedule={deleteSchedule}
                            originalTemplateGetter={originalTemplateGetter}
                            addSchedule={addSchedule}
                        />
                    </InnerSplitContiner>
                    <InnerSplitContiner>
                        <SmallHeaderText>Run stats</SmallHeaderText>
                        {originalTemplateGetter.configName ? originalTemplateGetter.configName.map(item => {

                            return (
                                <LastRunDiv>
                                    <LastRunSplitHeader>{item.label}</LastRunSplitHeader>
                                    <LastRunHeader>Last successful run </LastRunHeader>
                                    <LastRunText>{parseDate(lastRun[item.label].lastSuccessfulRun)}</LastRunText>
                                    <LastRunHeader>Last scheduled run </LastRunHeader>
                                    <LastRunText>{parseDate(lastRun[item.label].lastRun)}</LastRunText>
                                </LastRunDiv>
                            )
                        }) : 
                        <LastRunDiv>
                            <LastRunHeader>Last successful run</LastRunHeader>
                            <LastRunText>{parseDate(lastRun.lastSuccessfulRun)}</LastRunText>
                            <LastRunHeader>Last scheduled run</LastRunHeader>
                            <LastRunText>{parseDate(lastRun.lastRun)}</LastRunText>    
                        </LastRunDiv>
                        }
                        
                        <BasicButton fontSize="1.1em" background="primary" label="Update last run" width="130px" height="30px" margin="20px auto auto auto" handleClick={() => setShowUpdate(!showUpdate)} inactive={!user.roles.includes("admin")} />

                    </InnerSplitContiner>

                </InnerContainer>
                <ButtonDiv>
                    <BasicButton background="primary" label="Back" width="90px" height="30px" margin="auto 10px auto 10px" handleClick={() => navigate('/optionsmenu')} />
                    <BasicButton background="contrastGreen" label="Trigger integration" width="160px" height="30px" margin="auto auto auto 10px" handleClick={() => showTestRunFunction()} inactive={!user.roles.includes("admin")}/>
                    <BasicButton background="primary" label="Upload schedule" width="160px" height="30px" margin="auto auto auto 10px" handleClick={() => uploadSchedule()} inactive={!user.roles.includes("admin")}/>
                </ButtonDiv>
            </Container>
        </OuterContainer>
    )
}

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`
const InnerContainer = styled.div`
height: 83%;
width: 96%;
margin: auto auto auto 15px;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: row;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const InnerSplitContiner = styled.div`

width: 100%;
margin: 20px auto 20px auto;
display: flex;
border-right: ${props => props.showBorder ? '0.02em solid ' + props.theme.colors.elevation3 : 'none'};
flex-direction: column;
flex: 1;

`

const OuterContainer = styled.div`
background: ${props => props.theme.colors.background};
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
`

const HeaderText = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto auto auto auto;
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1em;
`
const SmallHeaderText = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 0px 0 0px 0;
font-size: 1.8em;
`
const LastRunText = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 0px 0 40px 0;
font-size: 1.5em;
`
const LastRunHeader = styled.div`
text-align: center;
color: ${props => props.theme.colors.contrastBlue};
margin: 2px 0 3px 0;
font-size: 1.6em;

`

const LastRunSplitHeader = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 2px auto 3px auto;
font-size: 1.6em;
padding: 0 10px 0 10px;
border-bottom: 0.1em solid ${props => props.theme.colors.elevation3};

`
const LastRunDiv = styled.div`
display: flex;
flex-direction: column;
margin auto auto 10px auto;
height: 100%;
justify-content: center;
`
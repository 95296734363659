import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'

export default function AddSystem({ addSystem,addSystemActive}) {

    const [systemName, setSystemName] = useState('')

    const setSystemNameHandler = (value) => {
        setSystemName(value)

    }
    if (addSystemActive) {
        return (
            <Container >
                <InputField
                
                label="System name"
                value={systemName}
        
                handleChange={setSystemNameHandler}
                                />
            <BasicButton background="primary" label="Add system" width="110px" height="35px" margin="20px auto 10px auto" handleClick={() => addSystem(systemName)} />
            </Container>
          )
    }
  
}
const Container = styled.div`
background: ${props => props.theme.colors.grey};
border-radius: 30px;
margin: auto;
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 100000;
padding: 10px 80px;
display: flex;
flex-direction: column;
align-items: center;
`

import React, { useState } from 'react'
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table';
import Pagination from '@mui/material/Pagination';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { SortDown } from "@styled-icons/bootstrap/SortDown"
import { SortUp } from "@styled-icons/bootstrap/SortUp"
import { Edit } from "@styled-icons/fluentui-system-regular/Edit"
import PagninationComponent from './PagninationComponent';

export default function IntegrationOverviewTable({ data, columns, sortTable, lastSorted, editIntegration }) {
    const themeContext = useContext(ThemeContext)

    const [tablePageSize, setTablePageSize] = useState(50)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        gotoPage,
        state,
        pageSize,
        prepareRow,
    } = useTable({ columns: columns, data: data, initialState: { pageSize: tablePageSize } }, usePagination)

    const { pageIndex } = state

    const handlePageChange = (e, newPage) => {
        gotoPage(newPage - 1)
    }
    const sortIcons = (column) => {
        const UpIcon = (column === lastSorted.column && lastSorted.direction === "up" || column !== lastSorted.column) ? null : <SortUpIcon key={column + "sortup"} onClick={() => sortTable(column, "up")} />
        const DownIcon = column === lastSorted.column && lastSorted.direction === "down" ? null : <SortDownIcon key={column + "sortdown"} onClick={() => sortTable(column, "down")} />
        if (!column) {
            return null
        }
        return (
            <>
                {UpIcon}
                {DownIcon}
            </>
        )
    }

    try {
        return (
            <>
                <TableContainer>
                    <StyledTable {...getTableProps()}>
                        <Thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => {
                                        if (column.Header === "") {
                                            return (
                                                <EditTh > {column.Header}</EditTh>
                                            )
                                        }
                                        return (
                                            <StyledTh > {column.Header} {sortIcons(column.filterAttribute)}</StyledTh>
                                        )
                                    })}
                                </tr>
                            ))}
                        </Thead>
                        <tbody {...getTableBodyProps()}>
                            {page.map((row, index) => {
                                prepareRow(row)
                                return (
                                    <StyledTr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            const cellValue = cell.value
                                            const statusColor = cellValue === "Production" ? "contrastGreen" : cellValue === "Testing" ? "contrastYellow" : cellValue === "Development" ? "contrastOrange" : "grey"
                                            if (cell.column.Header === "Status") {
                                                return <StyledTd>
                                                    <StatusText color={statusColor}>
                                                        {cellValue}
                                                    </StatusText>
                                                </StyledTd>
                                            } else if (cell.column.Header === "Last run" || cell.column.Header === "Last successful run" || cell.column.Header === "Schedule") {
                                                return <StyledTd>
                                                    {
                                                        cellValue.map(lastRun => {
                                                            return (
                                                                <TableText>
                                                                    <TableBold >{lastRun.name}</TableBold> {lastRun.value}

                                                                </TableText>
                                                            )
                                                        })
                                                    }
                                                </StyledTd>
                                            } else if (cell.column.Header === "") {
                                                return <StyledTd>
                                                    <EditIcon onClick={() => editIntegration(index, pageIndex, tablePageSize)} />
                                                </StyledTd>
                                            }
                                            return <StyledTd>{cellValue}</StyledTd>
                                        })}
                                    </StyledTr>
                                )
                            })}
                        </tbody>
                    </StyledTable>

                </TableContainer>
                <StyledPagnation>
                    <PagninationComponent pageOptions={pageOptions} pageIndex={pageIndex} handlePageChange={handlePageChange} />
                </StyledPagnation>
            </>

        )
    } catch {
        return (
            <TableContainer>
                <StyledTable>

                </StyledTable>
            </TableContainer>
        )
    }

}

const TableContainer = styled.div`
    background: ${props => props.theme.colors.elevation1};
    width: 90%;
    height: 73%;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    border-radius: 30px;
    ::-webkit-scrollbar{width:4px;height: 2px;};
    ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.elevation2};border-radius: 30px};
`
const StyledTable = styled.table`
    border-collapse: collapse;

    table-layout: auto;
    width: 100%;

    margin: 0 0px 0px 0px;
`
const Thead = styled.thead`
    position: sticky;
    position: -webkit-sticky;
    top:0;
    border-radius: 15px;
`
const StyledTh = styled.th`
    text-align: left;
    border-bottom: 4px solid #ddd;
    padding:18px 15px 18px 15px;
    max-width: 200px;
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};
    white-space: nowrap;
    border-color: ${props => props.theme.colors.contrastBlue};
    background: ${props => props.theme.colors.elevation1Color};
`
const StyledTd = styled.td`
    padding: 10px;
    border-bottom: 1px solid #ddd;
    font-size: 1em;
    overflow-x: scroll;
    max-width: 200px;
    color: ${props => props.theme.colors.primary};
    white-space: nowrap;
    border-color: ${props => props.theme.colors.background};
    background: ${props => props.theme.colors.elevation1};
    

`
const EditTh = styled.th`
    width: 30px;
    background: ${props => props.theme.colors.elevation1Color};
    border-bottom: 4px solid #ddd;
    border-color: ${props => props.theme.colors.contrastBlue};
`
const StyledTr = styled.tr`
height: 50px;

`
const StatusText = styled.div`
    background: ${props => props.theme.colors[props.color]};
    font-size: 0.9em;
    border-radius: 30px;
    width: 94px;
    padding: 5px;
    color: ${props => props.theme.colors.contrast};
    text-align: center;
`
const SortUpIcon = styled(SortUp)`
   width: 0.9em ;
   visibility: ${props => props.hidden ? "hidden" : "visible"};
   margin: auto 9px;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const SortDownIcon = styled(SortDown)`
   width: 0.9em ;
   margin: auto 9px;
   visibility: ${props => props.hidden ? "hidden" : "visible"};
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const EditIcon = styled(Edit)`
   width: 1.5em ;
   margin: auto 9px;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const TableText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.bold ? "bold" : "normal"};
    font-size: 1em;
`
const TableBold = styled.div`
    color: ${props => props.theme.colors.primary};
    font-weight: 600;
    font-size: 1em;
    display: inline;
`
const StyledPagnation = styled.div`
    margin: 5px 0 0 0;
    text-align: right;
    width: 90%;
    display: flex;
    flex-direction: row-reverse;
    z-index: 30000;
`
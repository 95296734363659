import React from 'react'
import styled from 'styled-components'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import { Plus } from "@styled-icons/bootstrap/Plus"

export default function TargetField({ selectedSystem, integrationType, systemFields, fieldObj, setFieldAttribute, originalTemplateGetter, setShowAddField, showAddField, importMapping }) {
    const eventTypeFieldName = integrationType.includes("sdworx") ? "Entity" : "Event type"
    return (
        <Container>
            {selectedSystem !== "c1headers" ? <SelectField width="260px" label={`${integrationType.substring(0, 1).toUpperCase()}${integrationType.substring(1)} field`} options={systemFields} fieldValue={fieldObj.sourceField} handleSelect={setFieldAttribute} attName="sourceField" margin="0 10px 0 10px" /> : null}

            {importMapping && originalTemplateGetter.fields.importValueTypes ?
                <SelectField width="210px" label={`Value type`} options={originalTemplateGetter.fields.importValueTypes} fieldValue={fieldObj.importValueType} handleSelect={setFieldAttribute} attName="importValueType" margin="0 10px 0 10px" />
                : null}

            {originalTemplateGetter.fieldTemplate.eventType ?
                <SelectField width="210px" label={eventTypeFieldName} options={originalTemplateGetter.fields.eventTypes} fieldValue={fieldObj.eventType} handleSelect={setFieldAttribute} attName="eventType" margin="0 10px 0 10px" />
                :
                null
            }

            < PlusIcon onClick={() => setShowAddField(!showAddField)} />
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px auto 10px auto;
`
const PlusIcon = styled(Plus)`
   width: 2em ;
   margin: 23px auto auto 10px;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
  `
import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../SharedComponents/InputField'
import BasicButton from '../SharedComponents/BasicButton'
import SelectField from '../SharedComponents/SelectlFieldToSplit'

export default function AddMappingPagePopUp({visible, setVisible, templateOptions, setTemplateOptions}) {
    const [mappingPageName, setMappingPageName] = useState('')
    const [mappingPageType, setMappingPageType] = useState({label: "Employees", "value": "employees"})
    const [mappingDirection, setMappingDirection] = useState({label: "Export", "value": "export"})

    const addMappingPage = (value) => {
      setMappingPageName(value)
    }

    const updateMappingPageType = (value) => {
      setMappingPageType(value)
    }

    const updateMappingDirection = (value) => {
      setMappingDirection(value)
    }

    const saveMappingPage = () => {
      const newOptions = { ...templateOptions }
      if (mappingPageName) 
        {
          newOptions.selectedFields.push({"label": mappingPageName, "type": mappingPageType.value, "direction": mappingDirection.value})
        }
      setTemplateOptions(newOptions)
      setMappingPageName('')
      setVisible(false)
    }

    if (!visible) {
        return null
    }
  return (
    <Container>
      <HeaderText>Add a mapping template</HeaderText>
      <InputField placeholder="Integration name" margin="0px auto auto auto" value={mappingPageName} handleChange={addMappingPage} />
      <SelectField
        options={[{label: "Employees", "value": "employees"}, {label: "Organizations", "value": "organizations"},{label: "Absences", "value": "absences"} ]}
        value={mappingPageType}
        margin="10px auto 10px auto"
        handleSelect={updateMappingPageType}
        label="CatalystOne endpoint"
        />
      <SelectField
        options={[{label: "Export", value: "export"},{label: "Import", value: "import"}]}
        value={mappingPageType}
        margin="10px auto 10px auto"
        handleSelect={updateMappingDirection}
        label="Import/Export"
        />
      <BasicButton background="primary" label="Save" width="90px" height="30px" margin="10px auto 10px auto" handleClick={saveMappingPage} />
    </Container>
  )
}

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 20%;
    height: 31%;
    left: 40%;
    top: 37%;
    z-index: 3;
    background: ${props => props.theme.colors.grey};
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    margin: 10px auto 15px auto;
`

import React from 'react'
import styled from 'styled-components'
import MappingSideNav from '../MappingSideNav/MappingSideNav'
import { useNavigate } from 'react-router-dom'
import ErrorPopup from '../../SharedComponents/ErrorPopUp'
import BasicButton from '../../SharedComponents/BasicButton'
import InputField from '../../SharedComponents/InputField'
import LoadingPage from '../../SharedComponents/LoadingPage'
import useSecrets from '../../../hooks/useSecrets'
import BackendApiClient from '../../../services/backendApiClient'
import useLoggedInUser from '../../../hooks/useLoggedInUser'

export default function EditSecrets() {
    const navigate = useNavigate()
    const backendApiClient = new BackendApiClient()
    const {
    secrets,
    setSecrets,
    loading,
    setLoading,
    customerIntegrationName,
    customerName,
    secretPrefix,
    messageStateSetter
    } = useSecrets()
    
    const setSecret = (value, index) => {
        secrets[index].value = value
        secrets[index].updated = true
        setSecrets([...secrets])
    }
    const {user} = useLoggedInUser()
    const updateSecrets = async () => {
        setLoading(true)
        const updateSecretsResponse = await backendApiClient.put(`secrets/${customerName}-${process.env.REACT_APP_KEYVAULT_PREFIX}/${secretPrefix}`, secrets)

        if (updateSecretsResponse.status !== 200) {
            messageStateSetter.set(`Failed to update secrets: ${updateSecretsResponse.error}`)
        } else {
            secrets.map(item => item.updated = false)
            messageStateSetter.set('Secrets updated')
        }
        setLoading(false)
    }

    if (loading) {
        return <LoadingPage />
    }

  return (
    <OuterContainer>
    <ErrorPopup />
    <MappingSideNav navigate={navigate} />
    <Container>
    <HeaderText>Edit secrets</HeaderText>
                    <CustomerName>{customerIntegrationName}</CustomerName>
        <InnerContainer>
            <SecretsList>
                {secrets.map((item, index) => {

                    return (
                        <InputField
                            label={item.name}
                            value={item.value}
                            index={index}
                            handleChange={setSecret}
                            width="300px"
                        />
                    )
                })}
            </SecretsList>
        </InnerContainer>
        <ButtonDiv>
            <BasicButton background="primary" label="Back" width="90px" height="30px" margin="auto 10px auto 10px" handleClick={() => navigate("/optionsmenu")} />
            <BasicButton  background="primary" label="Update secrets" width="180px" height="30px" margin="auto 10px auto 10px" handleClick={() => updateSecrets()} inactive={!user.roles.includes("admin")}/>
        </ButtonDiv>
    </Container>
</OuterContainer>
  )
}

const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
`
const InnerContainer = styled.div`
height: 83%;
width: 96%;
margin: auto auto auto 15px;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: column;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const OuterContainer = styled.div`
background: ${props => props.theme.colors.background};
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
`

const HeaderText = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto auto auto auto;
`
const SecretsList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 10px auto auto 10px;
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1em;
`
import React,{useState} from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import BasicButton from '../../SharedComponents/BasicButton'
import { Trash } from "@styled-icons/bootstrap/Trash"
import { Plus } from "@styled-icons/bootstrap/Plus"
import { ArrowDownCircle } from "@styled-icons/bootstrap/ArrowDownCircle"
import { ArrowUpCircle } from "@styled-icons/bootstrap/ArrowUpCircle"
import SwitchButton from '../../SharedComponents/SwitchButton'
import ConcatedOutput from '../ConcactedOutPut/ConcatedOutput'
import { useHookstate } from '@hookstate/core'
import { originalTemplate } from '../../../States/originalTemplate'
import { ObjectFieldModel } from '../../../classes/FieldTemplateModel'

export default function ObjectField({ fieldOptionsList, fieldObj, c1Fields, sorurceFields, index, configStateSetter, selectedFields, schemaPage, conditionObj, conditionIndex,fieldOptions, dataProfileFields,setFieldToEdit,originalFieldObj }) {
    const [showAddField, setShowAddField] = useState(false)
    const [newObjectName, setNewObjectName] = useState("")
    const originalTemplateGetter = useHookstate(originalTemplate).get()
    const newSelectedFieldsStructure = !Array.isArray(selectedFields[schemaPage])
    let objectField = fieldObj.objectField

    let objectOptions = [
        { label: 'Relative', parent: 'Relatives', value: "Relative", parent_parent: "" },
        { label: 'DeviatingAccounting', parent: 'DeviatingAccountings', value: "DeviatingAccounting", parent_parent: "" },
        { label: 'Salary', parent: 'Salaries', value: "Salary", parent_parent: "" },
        { label: 'FixedDeduction', parent: 'FixedDeductions', value: "FixedDeduction", parent_parent: "" },
        { label: 'Hogia löneberedning', parent: '', value: "Hogia löneberedning", parent_parent: "" },
        { label: 'Hogia löneberedning (föregående månad)', parent: '', value: "lastMonth", parent_parent: "" },
        { label: 'Hogia lönetillägg', parent: '', value: "salaryAdd", parent_parent: "" },
        { label: 'Ansatt nr nav', value: "ansattNrNAV"},
        { label: 'Ansatt nr leder', value: "ansattNrLeder"},
    ]

    if (originalTemplateGetter.fields.objectFields) {
        objectOptions = originalTemplateGetter.fields.objectFields
    }

    const setObjectName = (e) => {
        const newValue = e ? e : { label: "Choose field", value: "choose field", required: false }

        if (!conditionObj) {
            fieldObj.objectField.objectName = newValue
            fieldObj.sourceField = newValue
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].objectField.objectName = newValue
            originalFieldObj.sourceField = newValue
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
    }

    const setObjectField = (e) => {
        let value = ""
        if (e) {
            value = e
            
        } 

        fieldObj.c1Field.set(value)

        if (!conditionObj) {
            fieldObj.c1Field.set(value)
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].c1Field.set(value)
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
        
    }

    const setField = (e, fieldIndex, attName) => {
        if (e) {

            objectField.fields[fieldIndex][attName] = e
        } else {
            objectField.fields[fieldIndex][attName] = ""
        }
        if (!conditionObj) {
            fieldObj.objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
        
    }

    const addField = () => {
        objectField.fields.push(new ObjectFieldModel())
        if (!conditionObj) {
            fieldObj.objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
    }

    const deleteField = (fieldIndex) => {
        objectField.fields.splice(fieldIndex, 1)
        if (!conditionObj) {
            fieldObj.objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
    }

    const moveField = (fieldIndex, direction) => {
        const itemToMove = objectField.fields[fieldIndex]
        // Remove item from current index
        objectField.fields.splice(fieldIndex, 1)
        // Add item to new index
        if (direction === "up") {
            objectField.fields.splice(fieldIndex - 1, 0, itemToMove)
        } else {
            objectField.fields.splice(fieldIndex + 1, 0, itemToMove)
        }
        if (!conditionObj) {
            fieldObj.objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
        } else {
            originalFieldObj.customLogic[conditionIndex].objectField = objectField
            setFieldToEdit(JSON.parse(JSON.stringify(originalFieldObj)))
        }
    }

    const updateObjectName =   (value)  => {
        setNewObjectName(value)
    }

    const updateNewObjectName =   ()  => {
        setObjectName({label : newObjectName, value: newObjectName})
        setShowAddField(false)
    }


    const setFieldType = (e, test, index) => {
        console.log(e)
    }
    const objectFieldValue = conditionObj ? originalFieldObj.c1Field.value : fieldObj.c1Field.value

    return (
        <ObjectFieldContainer >
            { showAddField ? 
            <SetObjectName showAddField={showAddField}>
            <InputField  value={newObjectName}  handleChange={updateObjectName} attName="hardcodedValue" label="Object name" />
            <BasicButton background="primary" fontSize="0.9em" label="Save" width="80px" height="32px" margin="15px auto 0px auto" handleClick={updateNewObjectName} />
            </SetObjectName>
            : ""}
            <FieldsContainer>
            <SelectField width="260px"  label="Object Name" options={objectOptions} fieldValue={objectField.objectName} handleSelect={setObjectName} attName="c1Field" margin="0 10px 10px auto" />
            <PlusIcon onClick={() => setShowAddField(!showAddField)} />
            {objectField.objectName.value === 'salaryAdd' ? 
             <SelectField width="260px"  label="Salary add field" options={c1Fields} fieldValue={objectFieldValue} handleSelect={setObjectField} attName="Salary add field" margin="0 auto 10px auto" /> : ""  
        }
        </FieldsContainer>

            {objectField.fields.map((field, fieldIndex) => {
                try {
                    if (field.c1Field.type in fieldOptions) {
                        fieldOptionsList = fieldOptions[field.c1Field.type]
                    }
                } catch {

                }
                return (
                    <FieldsOuterContainer >
                        <FieldHeader >Field {fieldIndex + 1}</FieldHeader>
                        <SwitchButton
                            handleClick={(e) => setField(e, fieldIndex, "fieldType")}
                            index={fieldIndex}
                            
                            attName="fieldType"
                            width="370px"
                            boxWidth="20%"
                            activeButton={field.fieldType}
                            options={[
                                { label: 'c1Field', value: 'c1Field' },
                                { label: 'Hardcoded', value: 'hardCoded' },
                                {   label: 'Calculated', value: 'calculated'},
                                {   label: 'Concated', value: 'concat'},
                                { label: 'Profile', value: 'dataProfile' },

                            ]} />
                            <SelectField width="17em"  label="Target field" index={fieldIndex} options={sorurceFields} fieldValue={field.sourceField} handleSelect={setField} attName="sourceField" margin="0 auto 8px auto" />
                        <FieldsContainer>
                            {field.fieldType === 'c1Field' ? 
                            <>
                            <SelectField width="17em"  label="C1 field" index={fieldIndex} options={c1Fields} fieldValue={field.c1Field} handleSelect={setField} attName="c1Field" margin="0 10px 0 10px" />
                            <SelectField width="15em"  label="Value type" index={fieldIndex} options={fieldOptionsList} fieldValue={field.valueType} handleSelect={setField} attName="valueType" margin="0 10px 0 10px" />
                            </>
                               : field.fieldType === 'concat' ?
                               <ConcatedOutput schemaPage={schemaPage} setFieldToEdit={setFieldToEdit} selectedFields={selectedFields} objectIndex={fieldIndex} fieldObj={originalFieldObj ? conditionObj : fieldObj} originalFieldObj={originalFieldObj} concatedOutput={field.concatedOutput} fieldIndex={index} conditionIndex={conditionIndex} configStateSetter={configStateSetter} fromCondition={conditionObj} fromObject={true} />

                               : field.fieldType === 'calculated' ? 
                                <>
                                <InputField width="17em"  index={fieldIndex} value={field.calculatedValue} handleChange={setField} attName="calculatedValue" label="Calculated formula" />
                                <SelectField width="15em"  label="Value type" index={fieldIndex} options={fieldOptionsList} fieldValue={field.valueType} handleSelect={setField} attName="valueType" margin="0 10px 0 10px" />
                                </>
                                : field.fieldType === 'dataProfile' ? 
                                <>
                            <SelectField width="17em"  label="C1 field" index={fieldIndex} options={c1Fields} fieldValue={field.c1Field} handleSelect={setField} attName="c1Field" margin="0 10px 0 10px" />
                            <SelectField width="17em"  label="Data profile field" index={fieldIndex} options={dataProfileFields} fieldValue={field.dataProfileField} handleSelect={setField} attName="dataProfileField" margin="0 10px 0 10px" />
                            <SelectField width="17em"  label={"Value type"} index={fieldIndex} options={fieldOptionsList} fieldValue={field.valueType} handleSelect={setField} attName="valueType" margin="0 10px 0 10px" />
                            
                            </>
                            :
                                <>
                            <InputField  value={field.hardcodedValue} index={fieldIndex} handleChange={setField} attName="hardcodedValue" label="Value" />

                            </>
                            }

                            {fieldIndex === 0 ? "" : <UpIcon onClick={() => moveField(fieldIndex, "up")} />}
                            {fieldIndex === objectField.fields.length - 1 ? "" : <DownIcon onClick={() => moveField(fieldIndex, "down")} />}

                            <DeleteIcon  onClick={() => deleteField(fieldIndex)} />
                        </FieldsContainer>
                    </FieldsOuterContainer>
                )
            })}
            <BasicButton background="primary" fontSize="1em" label="Add field" width="6em" height="32px" margin="15px auto 0px auto" handleClick={addField} />
        </ObjectFieldContainer>
    )
}

const ObjectFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    widht: 100%;
    font-size: 0.9em;

`
const FieldsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
`
const FieldHeader = styled.div`
    color: ${props => props.theme.colors.primary};
    margin: 0px 0 -19px 0;
    font-size: 1.3em;

`
const FieldsOuterContainer = styled.div`
    width: 100%;
    min-width: 45em;
    display: flex;
    flex-direction: column;
    background: ${props => props.theme.colors.elevation1};
    margin: 5px 0 5px 0;
    padding: 10px;
    border-radius: 30px;
`
const DeleteIcon = styled(Trash)`
   width: 1.2em ;
   margin: auto 0px 10px 10px;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
const PlusIcon = styled(Plus)`
   width: 2em ;
   margin: auto auto auto 10px;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`

const UpIcon = styled(ArrowUpCircle)`
width: 1.2em ;
margin: auto 0px 10px 10px;
    color: ${props => props.theme.colors.primary};
    &:hover {
      cursor:pointer;
    }
`

const DownIcon = styled(ArrowDownCircle)`
width: 1.2em ;
margin: auto 0px 10px 10px;
    color: ${props => props.theme.colors.primary};
    &:hover {
      cursor:pointer;
    }
`

const SetObjectName = styled.div`
background: ${props => props.theme.colors.grey};
visibility: ${props => props.showAddField ? "visible" : "hidden"};
border-radius: 30px;
margin: auto;
position: absolute;
top: 30%;
left: 35%;
width: 20%;
z-index: 1000000;
padding: 10px 80px;
display: flex;
flex-direction: column;
align-items: center;
`
import React from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import { useNavigate } from "react-router-dom";
import { useHookstate } from '@hookstate/core';
import { importSchemaState } from '../../States/importSchemaState';
import DropZone from '../SharedComponents/DropZone';
import { parseExcelFile } from '../../services/functions';
import ErrorPopup from '../SharedComponents/ErrorPopUp';
import BasicButton from '../SharedComponents/BasicButton';


export default function ImportSchema() {
    const colors = colorScheme.night
    const importSchemaStateSetter = useHookstate(importSchemaState)
    
    const navigate = useNavigate()

    const uploadFile = async (file) => {
        const excelData = await parseExcelFile(file)
        const data = {}
        const headers = {}
        Object.keys(excelData).map(sheetName => {
            headers[sheetName] = []
            excelData[sheetName][0].map((header, ) => {
                headers[sheetName].push({label: header, value: header, field: ''})
            })
            data[sheetName] = []
            excelData[sheetName].map((row, i) => {
                if (i > 0) {
                    let rowObj = {}
                    row.map((item, i )=> {
                        rowObj[headers[sheetName][i].label] = item
                    })
                    data[sheetName].push(rowObj)
                }
            })
        })
        
        
        importSchemaStateSetter.fieldsFromExcel.set(headers)
        importSchemaStateSetter.data.set(data)
        navigate('/mapschema')
    }

  return (
    <IntegrationsListContainer colors={colors}>
        <ErrorPopup/>
        

        <HeaderText colors={colors}>Upload integration schema</HeaderText>
        <DropZone
            uploadFile={uploadFile}
        />

        <BasicButton fontSize="1.1em" background="primary" label="Back" width="80px" height="30px" margin="auto auto 15px auto" handleClick={() => navigate('/options')} />
    </IntegrationsListContainer>
  )
}

const IntegrationsListContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const InnerDiv = styled.div`
    margin: auto 0 0 0;
    width: 100%;
`
const HeaderText = styled.div`

color: ${props => props.theme.colors.primary};
  text-align: center;
  margin: 200px auto -200px auto;
  font-size: 2.5em;
`

import React from 'react';
import styled from 'styled-components';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import Select, { StylesConfig } from 'react-select';

export default function SelectField({options, fieldValue, handleSelect, index, label, margin, width, attName, index2}) {
  const themeContext = useContext(ThemeContext)

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px solid grey',
          color: state.isSelected ? themeContext.colors.contrastBlue : themeContext.colors.primary,
          padding: '0.7em',
          background: themeContext.colors.selectBoxBackground,
          fontSize: '0.9em',
          overflow: 'visible',
        }),
        control: () => ({
          // none of react-select's styles are passed to <Control />
            width: width,
            display: 'inline-flex',
            padding: 0,
            border: `1px solid ${themeContext.colors.primary}`,
            borderRadius: '30px',
            color: 'rgba(255,255,255, 0.09)',
            fontSize: '0.9em',
        }),
        container: () => ({
              position: 'relative',
            boxSizing: 'border-box',
            width: width
          }),
          input: (provided, state) => {
            // none of react-select's styles are passed to <Control />
            const color = themeContext.colors.primary
            return { ...provided, color };
            
          },
          placeholder: (provided, state) => {
            // none of react-select's styles are passed to <Control />
            const color = themeContext.colors.primary
            const fontStyle = 'italic'
            return { ...provided, color, fontStyle };
            
          },
        singleValue: (provided, state) => {
          const opacity = state.isDisabled ? 0.7 : 1;
          const transition = 'opacity 300ms';
          const color = themeContext.colors.primary
      
          return { ...provided, opacity, transition, color };
        }
      } 
      


  return (
    <Container margin={margin}>
      <LabelHeader >{label}</LabelHeader>
        <MultiSelect
        options={options}
        isClearable={true}
        value={fieldValue}
        styles={customStyles}
        onChange={(e )=> handleSelect(JSON.parse(JSON.stringify(e)), index, attName)}
        placeholder='Select field'
        menuPosition="fixed"
        menuPlacement="auto"
        />
        </Container>
  )
};

const Container = styled.div`
    margin: ${props => props.margin};
    font-size: 1.1em;
    display:block;
    overflow: visible;
`
const LabelHeader = styled.div`
    text-align: left;
    margin: 0 0 4px 4px;
    font-size: 0.9em;
    font-weight: 600px;
    color: ${props => props.theme.colors.primary};
`
const MultiSelect = styled(Select)`
    &.Select--multi  {

        .Select-value {
            display: inline-flex;
            align-items: center;
        }
    }

    & .Select-placeholder {
        font-size: small;
    }
`
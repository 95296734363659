import { useState, useEffect } from 'react'
import BackendApiClient from '../services/backendApiClient'
import { firstLetterUppercase } from '../services/functions'

export default function useIntegrationOverview() {
  const [integrations, setIntegrations] = useState([])
  const [filteredIntegrations, setFilteredIntegrations] = useState([])
  const [integrationTypeOptions, setIntegrationTypeOptions] = useState([{ label: "All", value: "" }])
  const [loading, setLoading] = useState(true)
  const backendApiClient = new BackendApiClient()

  const getIntegrationTypeList = (integrations) => {
    const integrationTypes = [{ label: "All", value: "" }]
    integrations.map(integration => {
      if (!integrationTypes.includes(integration.IntegrationType)) {
        integrationTypes.push({ label: firstLetterUppercase(integration.IntegrationType), value: integration.IntegrationType })
      }
    })

    const uniqueArray = [...new Map(integrationTypes.map(item => [item.label + item.value, item])).values()];
    return uniqueArray

  }

  const fetchData = async () => {
    setLoading(true)
    const integrationViewResult = await backendApiClient.get('integrationoverview')

    if (integrationViewResult.status === 200) {
      setIntegrations(integrationViewResult.data)
      setFilteredIntegrations(integrationViewResult.data)

    }
    setIntegrationTypeOptions(getIntegrationTypeList(integrationViewResult.data))
    setLoading(false)
  }
  useEffect(() => {

    fetchData()
  }, [])

  return {
    integrations, 
    filteredIntegrations, 
    integrationTypeOptions, 
    setIntegrations, 
    setFilteredIntegrations, 
    loading, 
    setLoading};
  
}

import React, { useState } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom';
import { Gear } from '@styled-icons/bootstrap/Gear'
import { Map } from '@styled-icons/bootstrap/Map'
import { Edit } from "@styled-icons/fluentui-system-regular/Edit"
import { CalendarRange } from "@styled-icons/bootstrap/CalendarRange"
import { Key } from "@styled-icons/bootstrap/Key"
import { PencilSquare } from "@styled-icons/bootstrap/PencilSquare"
import { customerState } from '../../../States/customerState';
import { useHookstate } from '@hookstate/core';
import { FiletypePdf } from '@styled-icons/bootstrap/FiletypePdf'
import {Eyeglasses} from '@styled-icons/bootstrap/Eyeglasses'


export default function MappingSideNav({ navigate }) {
    const customerStateGet = useHookstate(customerState).get()
    const integrationType = customerStateGet.integrationType
    const location = useLocation();
    const [active, setActive] = useState(false)

    const mappingPath = integrationType === 'deltareport' ? '/deltareport' : '/mapping'
    const settingsPath = integrationType === 'deltareport' ? '/deltareport' : '/settings'

    return (
        <Container active={active} >
            <NavLink active={location.pathname === '/mapping'} onClick={() => navigate(mappingPath)}>
                <MapIcon />
                Mapping
            </NavLink>
            <NavLink active={location.pathname === '/settings'} onClick={() => navigate(settingsPath)}>
                <GearIcon />
                Settings
            </NavLink>
            {integrationType === 'deltareport' ? null :
                <NavLink active={location.pathname === '/edittemplate'} onClick={() => navigate('/edittemplate')}>
                    <EditIcon />
                    Template
                </NavLink>
            }
            <NavLink active={location.pathname === '/scheduler'} onClick={() => navigate('/scheduler')}>
                <CalendarIcon />
                Schedule
            </NavLink>
            <NavLink active={location.pathname === '/editsecrets'} onClick={() => navigate('/editsecrets')}>
                <KeyIcon />
                Secrets
            </NavLink>
            <NavLink active={location.pathname === '/notes'} onClick={() => navigate('/notes')}>
                <NotesIcon />
                Notes
            </NavLink>
            {integrationType === 'deltareport' ? null :
                <NavLink active={location.pathname === '/pdf'} onClick={() => navigate('/pdf')}>
                    <PdfIcon />
                    PDF
                </NavLink>
            }
            {integrationType === 'deltareport' ? null :
                <NavLink active={location.pathname === '/audit'} onClick={() => navigate('/audit')}>
                    <AuditIcon />
                    Audit
                </NavLink>
            }

        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 10px auto 16px;
    width: 62px;
`
const NavLink = styled.div`
    color: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    margin: 0.7em auto 0.7em auto;
    text-align: left;
    font-size: ${props => props.active ? "1.1em" : "1em"};
    transition: 0.2s;
    &:hover {
        cursor: pointer;
        font-weight: 600;
        color: ${props => props.theme.colors.contrastBlue};
    }`

const GearIcon = styled(Gear)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const MapIcon = styled(Map)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const CalendarIcon = styled(CalendarRange)`
width: 1.8em ;
margin: auto auto 3px auto;

`

const EditIcon = styled(Edit)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const KeyIcon = styled(Key)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const NotesIcon = styled(PencilSquare)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const PdfIcon = styled(FiletypePdf)`
width: 1.8em ;
margin: auto auto 3px auto;

`
const AuditIcon = styled(Eyeglasses)`
width: 1.8em ;
margin: auto auto 3px auto;

`


import React from 'react'
import styled from 'styled-components';

export default function MenuBar({ editor})  {

  
    if (!editor) {
      return null;
    }
  
    return (
      <Container>
        <Button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive("bold") ? "is-active" : ""}
          active={editor.isActive("bold")}
        >
          bold
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive("italic") ? "is-active" : ""}
          active={editor.isActive("italic")}
        >
          italic
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 1 }).run()}
          className={editor.isActive("bold") ? "is-active" : ""}
          active={editor.isActive("heading", { level: 1 })}
        >
          h1
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          disabled={!editor.can().chain().focus().toggleHeading({ level: 2 }).run()}
          className={editor.isActive("bold") ? "is-active" : ""}
          active={editor.isActive("heading", { level: 2 })}
        >
          h2
        </Button>
        <Button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={!editor.can().chain().focus().toggleBulletList().run()}
          className={editor.isActive("bulletList") ? "is-active" : ""}
          active={editor.isActive("bulletList")}
        >
          bullet list
        </Button>

      </Container>
    );
  };

  const Button = styled.button`

    background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation2};
    border: none;
    border-radius: 30px;
    padding: 6px;
    cursor: pointer;
    margin: 0px auto 10px auto;
    color: ${props => props.active ? props.theme.colors.secondary : props.theme.colors.primary};
    font-size: 1.3em;
  `
  const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 10px auto;
    justify-content: center;
    border-bottom: 1px solid ${props => props.theme.colors.contrastBlue};

  `
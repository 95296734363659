import React from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'

export default function AddTargetField({ showAddField,newFieldName,updateNewFieldName,addNewFieldName }) {
    if (showAddField) {
    return (
        <Container showAddField={showAddField}>
            <InputField value={newFieldName} handleChange={updateNewFieldName} attName="hardcodedValue" label="Target field" />
            <BasicButton background="primary" fontSize="0.9em" label="Save" width="80px" height="32px" margin="15px auto 0px auto" handleClick={addNewFieldName} />
        </Container>
    )
}
}

const Container = styled.div`
background: ${props => props.theme.colors.grey};
visibility: ${props => props.showAddField ? "visible" : "hidden"};
box-shadow: 0px 0px 9px 2px rgba(0,0,0,0.13);
border-radius: 30px;
margin: auto;
position: absolute;
top: 30%;
left: 35%;
width: 20%;
z-index: 1000000;
padding: 10px 80px;
display: flex;
flex-direction: column;
align-items: center;
`
import React from 'react'
import styled from 'styled-components'
import InfoHoverBox from '../SharedComponents/InfoHoverBox'
import {Search} from "@styled-icons/bootstrap/Search"

export default function SearchInput({value, handleChange, index, label,attName, width, info, margin, placeholder,padding}) {
  return (
    <InputContainer margin={margin}>
    <StyledLabel >{label}
    {info ? <InfoHoverBox text={info}/> : ""}
    </StyledLabel>
    <SearchDiv width={width} padding={padding}>
      <SearchIcon/>
    <ConditionValueInput onChange={e => handleChange(e.target.value,index,attName)}
    value={value} 
    type="text" 
    width={width}
    placeholder={placeholder}/>
    </SearchDiv>
    </InputContainer>
  )
}

const ConditionValueInput = styled.input`
    display: flex;
    color: ${props => props.theme.colors.primary};
    background: none;
    outline: none;
    border: none;
    border-left: solid 1px ${props => props.theme.colors.elevation3};
    padding-left: 5px;
    margin:0 0 0 0x;
    text-align: left;
    font-size: 1em;
    width: ${props => props.width ? props.width : "180px"};
`
const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.colors.primary};
    font-size: 1.1em;
    margin: 0 0 2px 10px;
    align-items: center;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin: ${props => props.margin ? props.margin : "5px"};
`
const SearchIcon = styled(Search)`
   width: 1em ;
   margin: auto 5px auto 0px;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const SearchDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    background: ${props => props.theme.colors.grey1};
    border-radius: 30px;
    padding: ${props => props.padding ? props.padding : "10px"};
    width: ${props => props.width ? props.width : "180px"};
`
import React from 'react'
import { Outlet } from 'react-router-dom';
import styled from 'styled-components'
import { DarkModeSwitch } from 'react-toggle-dark-mode';

import c1_logo from '../../assets/c1logo.png'
import c1_logo_dark_small from '../../assets/c1_logo_dark_small.png'
import { useNavigate } from "react-router-dom";


export default function TopNavLayout({darkMode, setDarkMode}) {
  const navigate = useNavigate()

  const toggleDarkMode = (checked) => {
    localStorage.setItem('darkMode', checked);
    setDarkMode(checked);
  };
  return (
    <>  
    {darkMode ? <C1Logo onClick={() => navigate("/")} /> : <C1Logo_dark onClick={() => navigate("/")} />}

        <DarkModeSwitchContainer>
        <DarkModeSwitch
      style={{ marginTop: 4}}
      checked={darkMode}
      onChange={toggleDarkMode}
      size={30}
    />
        </DarkModeSwitchContainer>
        <Outlet/>
    </>
  )
}

const C1Logo = styled.img.attrs({
  src: c1_logo
})`
width: 60px;
object-fit: contain;
position: absolute;
margin: 20px 0 0 20px;
cursor: pointer;
`
const C1Logo_dark = styled.img.attrs({
  src: c1_logo_dark_small
})`
width: 60px;
object-fit: contain;
position: absolute;
margin: 20px 0 0 20px;
z-index: 1000;
cursor: pointer;
`

const DarkModeSwitchContainer = styled.div`

position: absolute;
margin: 20px 0 0 90px;
cursor: pointer;
vertical-align: center;
`
import React, {useState} from 'react'
import styled from 'styled-components'

import { Info } from '@styled-icons/bootstrap/Info';

export default function InfoHoverBox({text, margin}) {

    const htmlString = {__html: `<span>${text}</span>`}

    const [hover, setHover] = useState(false)
    const onHover = () => {
        setHover(true)
    }

    const onLeave = () => {
        setHover(false);
      };

  return (
    <InfoContainer margin={margin}>
        <InfoIcon margin={margin} onMouseEnter={onHover} onMouseLeave={onLeave}/>
        <InfoBox visible={hover} dangerouslySetInnerHTML={htmlString}/> 
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
position: relative;
margin: ${props => props.margin ? props.margin : '0 0 0 0'};
`

const InfoIcon = styled(Info) `
    width: 2em ;
    position: relative;
    widht: 300px;
    margin: 0 0 0 0px;
    margin: ${props => props.margin ? props.margin : '0 0 0 0'};
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const InfoBox = styled.div`
    visibility: ${props => props.visible ? 'visible' : 'hidden'};
    position: absolute;
    width: 300px;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.grey3};
    z-index: 100000;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
    padding: 13px;
    right: 0;
    margin-right: -150px;

`
import React, { useState } from 'react'
import styled from 'styled-components'
import BasicButton from '../../SharedComponents/BasicButton'
import { useHookstate } from '@hookstate/core';
import { customerState } from '../../../States/customerState';
import SwitchComponent from '../../SharedComponents/Switch';
import BackendApiClient from '../../../services/backendApiClient';
import SelectField from '../../SharedComponents/SelectlFieldToSplit';
import DateTimePicker from 'react-datetime-picker';
import './DateTimePicker.css';
import './Calendar.css';

export default function TestRun({ showTestRun, setShowTestRun, setLoading, messageStateSetter, originalTemplateGetter }) {
    const customerStateGet = useHookstate(customerState).get()
    const configNameOptions = originalTemplateGetter.configName ? originalTemplateGetter.configName : ""
    const initialConfigName = configNameOptions.length > 0 ? configNameOptions[0] : ""
    const [configName, setConfigName] = useState(initialConfigName)
    const backendApiClient = new BackendApiClient()

    const [queueMessage, setQueueMessage] = useState({
        customerName: customerStateGet.customerName,
        configName: customerStateGet.systemName,
        test: false,
        testTimestamp: new Date().toISOString().slice(0, 16),
        timeZone: "Europe/Stockholm"

    })


    const setTimestamp = (value) => {
        var localISOTime = (new Date(value - (offsetMinutes * 60000))).toISOString().slice(0, -1);
        setQueueMessage({ ...queueMessage, testTimestamp: localISOTime })
    }

    const updateConfigName = (value) => {
        setConfigName(value)
    }

    const sendTestRun = async () => {
        setLoading(true)
        const newQueueMessage = { ...queueMessage }

        
        newQueueMessage.testTimestamp = newQueueMessage.testTimestamp + formattedOffset
        if (configName) {
            newQueueMessage.configName = configName.value
        }

        const testRunResponse = await backendApiClient.post(`servicebus/${customerStateGet.integrationType}`, newQueueMessage)
        if (testRunResponse.status === 200) {
            messageStateSetter.set("Integration triggered successfully")
        } else {
            messageStateSetter.set("Integration trigger failed: " + testRunResponse.error)

        }
        setShowTestRun(false)
        setLoading(false)
    }

    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset();

    // Convert offset from minutes to hours and minutes
    const offsetHours = Math.abs(Math.floor(offsetMinutes / 60));
    const offsetMinutesPart = Math.abs(offsetMinutes) % 60;

    // Construct the formatted timezone offset string
    const offsetSign = offsetMinutes <= 0 ? '+' : '-'; // Adjusted sign here
    const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesPart.toString().padStart(2, '0')}`;

    const lastRunLocal = queueMessage.testTimestamp + formattedOffset


    if (!showTestRun) {
        return null
    }
    return (
        <Container>
            <Header>Trigger integration one time</Header>
            {configNameOptions.length > 0 ?
                <SelectField width="210px" label={`Integration type`} options={configNameOptions} fieldValue={configName} handleSelect={updateConfigName} attName="importValueType" margin="0 auto 0 auto" /> : null}
            <SwitchComponent label="Test run (will not update last successful run)" checked={queueMessage.test} handleChange={() => setQueueMessage({ ...queueMessage, test: !queueMessage.test })} onColor="contrastBlue" />
            {queueMessage.test ? <DateDiv>
                <DateTimePicker  disableClock={true} format="yyyy-MM-dd HH:mm" locale="sv-SV" value={queueMessage.testTimestamp} onChange={(e) => setTimestamp(e)} />
            </DateDiv> : null}

            <BasicButton fontSize="1.1em" background="primary" label="Send" width="100px" height="30px" margin="20px auto 10px auto" handleClick={() => sendTestRun()} />
        </Container>
    )
}

const Container = styled.div`
background: ${props => props.theme.colors.grey};
width: 30%;

display: flex;
flex-direction: column;
justify-content: flex-start;
position: absolute;
top: 32%;
left: 37%;
border-radius: 30px;
font-size: 1em;
    `

const Header = styled.div`
color: ${props => props.theme.colors.primary};
text-align: center;
margin: 10px auto 30px auto;
font-size: 1.5em;
display: inline;
`

const DateInput = styled.input`
    width: 200px;
    height: 40px;
    background: ${props => props.theme.colors.elevation1};
    border-radius: 30px;
    outline: none;
    border: none;
    color: ${props => props.theme.colors.primary};
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto 30px auto;
    &:hover {
        cursor: pointer;
    }
`
const DateDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    margin: 10px auto auto auto;
    font-size: 1em;
`
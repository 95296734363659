import { useState} from 'react'
import { customerState } from '../States/customerState';
import { useHookstate } from '@hookstate/core';
import { originalTemplate } from '../States/originalTemplate';
import { c1State } from '../States/c1State';
import { messageState } from '../States/messageState';
import { firstLetterUppercase } from '../services/functions';

export default function useIntegrationStates() {
  
  const [loading, setLoading] = useState(false)
  const c1StateSetter = useHookstate(c1State)
  const c1StateGetter = c1StateSetter.get()
  const originalTemplateGetter = useHookstate(originalTemplate).get()
  const originalTemplateSetter = useHookstate(originalTemplate)
  const messageStateSetter = useHookstate(messageState)
  const customerStateSetter = useHookstate(customerState)
  const customerStateGetter = customerStateSetter.get()
  const configStateSetter = customerStateSetter.config // Used to get config
  const configStateGetter = customerStateGetter.config // Used to update config state
  const settings = configStateGetter.settings ? JSON.parse(JSON.stringify(configStateGetter.settings)) : {}
  const integrationType = customerStateGetter.integrationType
  const customerName = customerStateGetter.customerName
  const systemName = customerStateGetter.systemName
  const integrationDirections = findIntegrationDirections(configStateGetter.selectedFields)
  const customerIntegrationName = customerStateGetter.systemName ? `${firstLetterUppercase(customerStateGetter.customerName)}: ${firstLetterUppercase(customerStateGetter.systemName)}` : `${firstLetterUppercase(customerStateGetter.customerName)}: ${firstLetterUppercase(customerStateGetter.integrationType)}`

  return {
    c1StateSetter,
    c1StateGetter,
    originalTemplateGetter, 
    customerStateSetter, 
    customerStateGetter, 
    configStateSetter, 
    configStateGetter,
    loading,
    setLoading,
    settings,
    integrationType,
    customerIntegrationName,
    originalTemplateSetter,
    messageStateSetter,
    customerName,
    systemName,
    integrationDirections
    };
  
}

const findIntegrationDirections = (selectedFields) => {
  try {
    const integrationDirections = []
    Object.keys(selectedFields).forEach(schema => {
      if (!Array.isArray(selectedFields[schema])) {
        if (!integrationDirections.includes(selectedFields[schema].direction)) {
          integrationDirections.push(selectedFields[schema].direction)
        }
      }
    }) 
    return integrationDirections
  } catch {
    return []
  }
  
}
import React  from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig'
import BasicButton from '../SharedComponents/BasicButton'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import { templateStates } from '../../States/templateState';
import { customerState } from '../../States/customerState'
import { originalTemplate } from '../../States/originalTemplate'
import { c1State } from '../../States/c1State'
import { useHookstate } from '@hookstate/core';
import { fieldOptions } from '../../globalConfig/fieldOptions'
import SwitchComponent from '../SharedComponents/Switch'
import InputField from '../SharedComponents/InputField'
import DeltaReportConditionsList from './DeltaReportConditionList'
import DeltaReportConcatedOutput from './DeltaReportConcatedOutput'
import SwitchButton from '../SharedComponents/SwitchButton'

export default function DeltaReportPopUp({ fieldObj, selectedFields, reportPage, sheetPage, visible, editIndex, setNewFieldPopUpVisible }) {

    const colors = colorScheme.night
    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    const templateStateRaw = useHookstate(templateStates.deltareport)
    const customerStateGetter = useHookstate(customerState).get()
    const configStateGetter = customerStateGetter.config
    const configStateSetter = useHookstate(customerState).config
    const originalTemplateGetter = useHookstate(originalTemplate).get()

    const templateStatesGet = templateStateRaw.get()
    let c1Fields = c1StateGet.c1Fields

    const systemFields = []

    if (!fieldObj) {
        fieldObj = originalTemplateGetter.fieldTemplate
    }

    let fieldOptionsList = [{ label: 'Value', value: 'value' }]
    if (fieldObj.c1Field.type in fieldOptions) {
        fieldOptionsList = fieldOptions[fieldObj.c1Field.type]
    }

    const setFieldAttribute = (choice, index, attName) => {
        if (choice) {
            fieldObj[attName] = choice
        } else {
            fieldObj[attName] = ""
        }
        selectedFields.splice(editIndex, 1, fieldObj)
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields.set(selectedFields)
    }

    const setFieldBoolean = (fieldName) => {
        fieldObj[fieldName] = !fieldObj[fieldName]
        selectedFields.splice(editIndex, 1, fieldObj)
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields.set(selectedFields)
    }

    const saveField = () => {
        setNewFieldPopUpVisible(false)
    }

    const addCondition = () => {

        const conditionObj = {
            textValue: "",
            fieldValue: "",
            valueFromField: false,
            else: false,
            concatOutput: false,
            valueType: { label: "Value", value: "value" },
            fieldType: 'c1Field',
            calculatedValue: "",
            concatedOutput: [],
            objectField: {
                objectName: "",
                fields: []
            },
            conditionFields: [
                {
                    operator: { label: "Equals", value: "=" },
                    field: "",
                    value: "",
                    listValues: [],
                    hasValueFromList: false

                }
            ]
        }
        fieldObj.customLogic.push(conditionObj)
        selectedFields.splice(editIndex, 1, fieldObj)
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields.set(selectedFields)
    }



    return (
        <NewFieldPopUpContainer colors={colors} visible={visible} >
            <SwitchButton
                handleClick={setFieldAttribute}
                color={colors}
                width="540px"
                activeButton={fieldObj.fieldType}
                options={[
                    { label: 'c1Field', value: 'c1Field' },
                    { label: 'Logic', value: 'logic' },
                    { label: 'Concat', value: 'concat' },
                    { label: 'Hardcoded', value: 'hardCoded' },
                    { label: 'Calculated', value: 'calculated' },
                    { label: 'Profile', value: 'dataProfile' }
                ]} />

            <FieldSwitchDiv>

                <SwitchComponent label="Always include" checked={fieldObj.alwaysInclude} handleChange={() => setFieldBoolean('alwaysInclude')} onColor={colors.contrastBlue} attName="hardCoded" />
                <SwitchComponent label="Valid from" checked={fieldObj.validFrom} handleChange={() => setFieldBoolean('validFrom')} onColor={colors.contrastBlue} attName="hardCoded" />
                <SwitchComponent label="Planned change" checked={fieldObj.plannedChange} handleChange={() => setFieldBoolean('plannedChange')} onColor={colors.contrastBlue} attName="hardCoded" />
            </FieldSwitchDiv>

            <FieldSelectionDiv>
                <InputField colors={colors} value={fieldObj.columnName} handleChange={setFieldAttribute} attName="columnName" label="Column name" />
            </FieldSelectionDiv>

            {fieldObj.fieldType === 'c1Field' ?
                <FieldSelectionDiv>
                    <SelectField width="260px" color={colors} label="C1 field" options={c1Fields} fieldValue={fieldObj.c1Field} handleSelect={setFieldAttribute} attName="c1Field" margin="0 10px 0 10px" />
                    <SelectField width="210px" color={colors} label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                </FieldSelectionDiv>
                : fieldObj.fieldType === 'concat' ?
                    <DeltaReportConcatedOutput concatedOutput={fieldObj.concatedOutput} configStateSetter={configStateSetter} reportPage={reportPage} sheetPage={sheetPage} fieldIndex={editIndex} />
                : fieldObj.fieldType === 'calculated' ?
                    <FieldSelectionDiv>
                        <InputField width="260px" colors={colors} value={fieldObj.calculatedValue} handleChange={setFieldAttribute} attName="calculatedValue" label="Calculated formula" info="Input a formula in this field. Put a <b>#</b> before a field id to use value from field. Use regular operators (*,-,+,/) to perform calculations. Put parenthesis around calculation to calculate it seperately. i.e  <i>#35 * (#40 / 100)</i>. This formula calculates actual salary." />
                    </FieldSelectionDiv>
                : fieldObj.fieldType === 'dataProfile' ?
                    <FieldSelectionDiv>
                        <SelectField width="260px" color={colors} label="C1 field" options={c1StateGet.c1Fields} fieldValue={fieldObj.c1Field} handleSelect={setFieldAttribute} attName="c1Field" margin="0 10px 0 10px" />
                        <SelectField width="260px" color={colors} label="Data profile field" options={c1StateGet.lists.dataProfiles} fieldValue={fieldObj.dataProfileField} handleSelect={setFieldAttribute} attName="dataProfileField" margin="0 10px 0 10px" />
                        <SelectField width="210px" color={colors} label={"Value type"} options={fieldOptionsList} fieldValue={fieldObj.valueType} handleSelect={setFieldAttribute} attName="valueType" margin="0 10px 0 10px" />
                    </FieldSelectionDiv> 
                : fieldObj.fieldType === 'hardCoded' ?
                    <FieldSelectionDiv>
                        <InputField colors={colors} value={fieldObj.hardCodedValue} handleChange={setFieldAttribute} attName="hardCodedValue" label="Hard coded value" />
                    </FieldSelectionDiv>
                : fieldObj.fieldType === 'logic' ?
                <>
                    
                    <DeltaReportConditionsList reportPage={reportPage} selectedFields={selectedFields} fieldObj={fieldObj} sheetPage={sheetPage} conditions={fieldObj.customLogic} fieldIndex={editIndex} configStateSetter={configStateSetter} />
                    <BasicButton background="primary" label="Add condition" width="120px" height="32px" margin="10px auto 10px auto" handleClick={addCondition} />
                </>
                :
                            ""
            }
            <SaveButtonDiv>
                <BasicButton fontSize="1.1em" background="primary" label="Save" width="80px" height="30px" margin="20px auto 15px auto" handleClick={saveField} />
            </SaveButtonDiv>

        </NewFieldPopUpContainer>
    )
}

const NewFieldPopUpContainer = styled.div`
    background: ${props => props.theme.colors.grey};
    left: 17%;
    top: 12%;
    width: 66%;
    max-height: 76%;
    border-radius: 30px;
    visibility: ${props => props.visible ? "visbile" : "hidden"};
    display:flex;
    flex-direction: column;
    margin: auto;
    position: absolute;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
    overflow: overlay;
    z-index: 5;
`
const FieldSelectionDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: 20px 0 10px 0;
`
const FieldSwitchDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 180px 0 180px;
`
const SaveButtonDiv = styled.div`
    bottom: 0;
    margin-top: auto;
    width: 100%;
`
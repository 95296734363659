import React from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import c1_logo from '../../assets/c1logo.png'
import { useNavigate } from "react-router-dom";
import { useMsal } from '@azure/msal-react';

export default function Home() {
  const colors = colorScheme.night
  const navigate = useNavigate()

  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  return (
    <Container colors={colors}>
      
      <Header colors={colors}>Integration<ColoredHeader>One</ColoredHeader></Header>

      <OptionDiv>
      <ListOptionBox onClick={() => navigate('optionsmenu')} colors={colors}>
        <ListOptionText  colors={colors}>
          Integrations
        </ListOptionText>
      </ListOptionBox>
      <ListOptionBox onClick={() => navigate('apidoc')} colors={colors}>
        <ListOptionText colors={colors}>
          Documentation (coming soon)
        </ListOptionText>
      </ListOptionBox>
      </OptionDiv>
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const Header = styled.div`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin: 15% auto 30px auto;
  font-size: 4.5em;
  display: inline;
`
const ListOptionBox = styled.div`
    background: ${props => props.theme.colors.blueElevation1};
    width: 190px;
    height: 150px;
    margin: auto 10px auto 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.blueElevation2};
        font-size: 1.1em;
    }
`
const ListOptionText = styled.div`
    color: ${props => props.theme.colors.contrastBlue};
    text-align: center;
    font-size: 1.6em;
    padding: 8px;
`
const OptionDiv = styled.div`
    margin: 30px auto auto auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const C1Logo = styled.img.attrs({
  src: c1_logo
})`
width: 100px;
object-fit: contain;
margin: 20px auto 10px auto;

`
const ColoredHeader = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  display: inline;
  font-style: italic;
`
export const colorScheme = {
    night: {
        background: '#121212',
        primary: '#dbd9d9',
        tableBackground: '#2E3744',
        boxColor1: '#15141c',
        boxColor2: '#2d2c33',
        dropZone: '#242c36',
        dropZoneActive: '#3e4750',
        grey: '#383838',
        grey1: 'rgba(255, 255,255, 0.1)',
        grey2: 'rgba(255, 255,255, 0.2)',
        blue1: '#B6F4FB',
        blue2: '#00B4C8',
        blue3: '#005F78',
        blue4: '#001F33',
        contrastBlue: '#00B4C8',
        contrastBlue1: '#00B4C8',
        contrastRed: '#FF8DAC',
        contrastGreen: '#9EDDA7',
        contrastYellow: '#FDCB7D',
        shadow: '0 0 20px rgba(0, 0,0, 1)',
        boxColor: '#001F33',
        topNavColor: '#001F33',
        overlay: 'rgba(0,0,0, 0.3)',
        elevation1: 'rgba(255,255,255, 0.05)',
        elevation2: 'rgba(255,255,255, 0.07)',
        elevation3: 'rgba(255,255,255, 0.09)',
        grey3 : '#464646',
        blueElevation1 : 'rgba(0, 180, 200, 0.06)',
        blueElevation2 : 'rgba(0, 180, 200, 0.1)'

    },
    day: {
        background: '#ebebeb',
        primary: '#000000',
        tableBackground: '#ffffff',
        boxColor1: '#f2f2f2',
        boxColor2: '#ffffff',
        dropZone: '#f2f2f2',
        dropZoneActive: '#cdcdcd',
        grey: 'rgba(0,0,0, 0.7)',
        grey1: 'rgba(0,0,0, 0.1)',
        grey2: 'rgba(0,0,0, 0.2)',
        blue1: '#B6F4FB',
        blue2: '#00B4C8',
        blue3: '#005F78',
        blue4: '#001F33',
        contrastBlue: '#005F78',
        contrastBlue1: '#001F33',
        contrastRed: '#FF3264',
        contrastGreen: '#006C50',
        contrastYellow: '#F15A22',
        shadow: '0 0 10px rgba(8,7,16,0.6)',
        boxColor: '#005F78',
        topNavColor: '#005F78',
        overlay: 'rgba(0,0,0, 0.3)'
    }
}

export const integrationList = [
    {name: 'Hogia', template: 'hogia'},
    {name: 'Hr+8', template: 'hr8'},
    {name: 'SD Worx', template: 'sdworx'},
    {name: 'Quinyx', template: 'quinyx'},
    {name: 'Agda', template: 'agda'},
    {name: 'CSV', template: 'csv'},
    {name: 'Entra ID', template: 'entraid'},
    {name: 'Teamtailor', template: 'teamtailor'},
    {name: 'Absence follow up SE', template: 'absencefollowup'},
    {name: 'Delta report', template: 'deltareport'},
    {name: 'Findity', template: 'findity'},
    {name: 'Documaster', template: 'documaster'},
]

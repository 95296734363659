import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "../auth-config";

class BackendApiClient {
  constructor() {
    this.baseURL = process.env.REACT_APP_BACKEND_URL; 
    this.msalInstance = new PublicClientApplication(msalConfig);
    this.accessToken = null;
  }

  // Method to get access token using MSAL
  async getAccessToken() {
    try {
      await this.msalInstance.initialize(); // Ensure MSAL is initialized
      const account = this.msalInstance.getActiveAccount();

      if (!account) {
        throw new Error('No active account');
      }

      const tokenRequest = {
        scopes: [process.env.REACT_APP_SCOPES], // Use environment variable for scopes
        account: account
      };

      // Try to acquire the token silently
      const response = await this.msalInstance.acquireTokenSilent(tokenRequest);
      this.accessToken = response.accessToken; // Save the access token
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        console.error("Interaction is required to acquire the token:", error);
      } else {
        console.error("Error acquiring access token:", error);
      }
      throw error; // Re-throw the error to handle it at the calling level
    }
  }

  // Helper method to make API requests
  async _makeRequest(method, endpoint, payload = null, queries = '', retries = 3) {
    // Ensure access token is available
    if (!this.accessToken) {
      await this.getAccessToken();
    }

    // Construct the full URL with optional queries
    const url = queries ? `${this.baseURL}/${endpoint}?${queries}` : `${this.baseURL}/${endpoint}`;

    // Set up request options
    const options = {
      method,
      headers: {
        'Authorization': `Bearer ${this.accessToken}`, // Attach the access token
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };

    if (payload) {
      options.body = JSON.stringify(payload); // Add payload if provided
    }

    try {
      const response = await fetch(url, options);

      if (response.status === 403) {
        return { data: null, status: response.status, error: "Logged in user lacks permission to perform this action. Admin role required." };
      }
      const data = await response.json();

      if (response.status !== 200) {
        console.error(`Error with ${method} request to ${url}:`, data.error);
        return { data: null, status: response.status, error: data.error};
      }

      return { data, status: response.status, error: null }; // Return data, status, and no error
    } catch (error) {
      console.error(`Error with ${method} request to ${url}:`, error);
      return { data: null, status: 500, error: error.message }; // Return error response
    }
  }

  // GET request
  async get(endpoint, queries = '') {
    return this._makeRequest('GET', endpoint, null, queries);
  }

  // POST request
  async post(endpoint, payload, queries = '') {
    return this._makeRequest('POST', endpoint, payload, queries);
  }

  // PUT request
  async put(endpoint, payload, queries = '') {
    return this._makeRequest('PUT', endpoint, payload, queries);
  }

  // PATCH request
  async patch(endpoint, payload, queries = '') {
    return this._makeRequest('PATCH', endpoint, payload, queries);
  }
}

export default BackendApiClient;
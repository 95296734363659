import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../../SharedComponents/InputField'
import BasicButton from '../../SharedComponents/BasicButton'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'

export default function AddSetting({addSettingActive, addSetting}) {

    const [settingName, setSettingName] = useState('')
    const [settingType, setSettingType] = useState({label: 'Text', value: 'text'})
    const settingTypes = [
        {value: 'c1Field', label: 'C1 Fields'},
        {value: 'boolean', label: 'Boolean'},
        {value: 'select', label: 'select'},
        {value: 'orgMultiField', label: 'Org multiselect'},
        {value: 'c1MultiField', label: 'Employee multiselect'},
        {value: 'text', label: 'Text'},
    ]

    const setOption = (choice,index, attName) => {
        if (attName === 'type') {
            setSettingType(choice)
        } else {    
            setSettingName(choice)
        }
    }

    if (addSettingActive) {
  return (
    <Container >
        <HeaderText  >Add setting</HeaderText>
        <InputField
                            
                            label='Setting name'
                            value={settingName}
                            attName='name'
                            handleChange={setOption}
                        />
        <SelectField width="260px"  label="Type"  options={settingTypes} fieldValue={settingType} handleSelect={setOption} attName="type" margin="0 10px 0 10px" />

    <BasicButton background="primary" label="Save" width="110px" height="35px" margin="10px auto 10px auto" handleClick={() => addSetting(settingName,settingType.value)} />
    </Container>
  )
    }
}

const Container = styled.div`

background: ${props => props.theme.colors.grey};
    border-radius: 30px;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 35%;
    width: 20%;

    padding: 10px 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
`
export const fieldOptions = {
    SCALE: [
        {label: 'Value', value: 'value'},
        {label: 'Alternative export value', value: 'alternativeExportValue'},
        {label: 'Numeric value', value: 'numericValue'},
        {label: 'Valid from', value: 'validFrom'}

    ],
    MULTISCALE: [
        {label: 'Value', value: 'value'},
        {label: 'Alternative export value', value: 'alternativeExportValue'},
        {label: 'Valid from', value: 'validFrom'}
    ],
    ORGANIZATION: [
        {label: 'Value', value: 'value'},
        {label: 'Unique import Id', value: 'uniqueImportId'},
        {label: 'Alternative export value', value: 'alternativeExportValue'},
        {label: 'Organization Id', value: 'organizationId'},
        {label: 'Valid from', value: 'validFrom'}
    ],
    PERSON: [
        {label: 'Value', value: 'value'},
        {label: 'Employee Id', value: 'employeeId'},
        {label: 'Username', value: 'username'},
        {label: 'Profile Id', value: 'profileId'},
        {label: 'Valid from', value: 'validFrom'},
        {label: 'Guid', value: 'guid'},
        {label: 'Profile guid', value: 'profileGUID'}
    ],
    DEFAULT: [
        {label: 'Value', value: 'value'},
        {label: 'Valid from', value: 'validFrom'}
    ],
    GUID: [
        {label: 'Value', value: 'value'},
        {label: 'Valid from', value: 'validFrom'},
        {label: 'Guid', value: 'guid'}
    ],
    PROFILEGUID: [
        {label: 'Value', value: 'value'},
        {label: 'Valid from', value: 'validFrom'},
        {label: 'Profile guid', value: 'profileGUID'}
    ]
}

export const operatorOptions = {
    DEFAULT: [
        { value: "=", label: "Equals" },
        { value: "!=", label: "Not equals" },
        { value: "0", label: "Empty" },
        { value: "!0", label: "Not empty" },
        { value: "con", label: "Contains" },
        { value: "!con", label: "Not contains" },
        { value: "change", label: "Changed" },
        { value: "!change", label: "Not changed" },
    ],
    DATE: [
        { value: "=", label: "Equals" },
        { value: "!=", label: "Not equals" },
        { value: "0", label: "Empty" },
        { value: "!0", label: "Not empty" },
        { value: "<", label: "Before" },
        { value: ">", label: "After" },
        { value: ">=", label: "After or equal to" },
        { value: "<=", label: "Before or equal to" },
        { value: "change", label: "Changed" },
        { value: "!change", label: "Not changed" },
    ]
}

export const dateRollingOptions = [
    { value: "days", label: "Day(s)" },
    { value: "weeks", label: "Week(s)" },
    { value: "months", label: "Month(s)" },
    { value: "years", label: "Years(s)" }
]
import React, { useState } from 'react'
import styled from 'styled-components'
import HogiaEntities from './FieldEditor/HogiaEntities';
import BasicButton from '../SharedComponents/BasicButton';
import FieldEditor from './FieldEditor/FieldEditor';
import { useNavigate } from "react-router-dom";
import SchemaPageSwitch from '../SharedComponents/SchemaPageSwitch';
import LoadingPage from '../SharedComponents/LoadingPage';
import DragNDropTable from './MappingTable/DragNDropTable';
import { ArrowClockwise } from '@styled-icons/bootstrap/ArrowClockwise';
import { fetchFieldsAndLists, fetchFieldsAndListsWithSecrets } from '../../services/getC1Data';
import EmployeeFilter from './EmployeeFilter/EmployeeFilter';
import MappingSideNav from './MappingSideNav/MappingSideNav';
import ErrorPopup from '../SharedComponents/ErrorPopUp';
import c1_logo from '../../assets/c1logo.png'
import useIntegrationStates from '../../hooks/useIntegrationStates';
import { FieldTemplateModel } from '../../classes/FieldTemplateModel';
import SearchInput from '../SharedComponents/SearchInput';
import useLoggedInUser from '../../hooks/useLoggedInUser';
import { createAudit } from '../../utils/createAudit';
import BackendApiClient from '../../services/backendApiClient';


export default function IntegrationSchemaMapping() {
  const navigate = useNavigate()
  const backendApiClient = new BackendApiClient()
  const [fieldEditorVisible, setfieldEditorVisible] = useState(false)
  const [newField, setNewField] = useState(false)
  const [employeeFilterVisible, setEmployeeFilterVisible] = useState(false)
  const [integrationDirection, setIntegrationDirection] = useState('export')
  const [searchInput, setSearchInput] = useState('')
  const { user } = useLoggedInUser()
  const { c1StateSetter,
    originalTemplateGetter,
    customerStateGetter,
    configStateSetter,
    configStateGetter,
    loading,
    setLoading,
    settings,
    integrationType,
    customerIntegrationName,
    messageStateSetter,
    integrationDirections
  } = useIntegrationStates()
  const [schemaPage, setSchemaPage] = useState(Object.keys(configStateGetter.selectedFields)[0])
  const selectedFields = JSON.parse(JSON.stringify(configStateGetter.selectedFields))
  const newSelectedFieldsStructure = !Array.isArray(selectedFields[schemaPage])
  let firstSystem = ''
  if (customerStateGetter.systemName) {
    firstSystem = customerStateGetter.systemName
  }


  else if (!Array.isArray(originalTemplateGetter.fields[schemaPage])) {
    firstSystem = Object.keys(originalTemplateGetter.fields[schemaPage])[0]
  }
  const [selectedSystem, setSelectedSystem] = useState(firstSystem)
  const [editIndex, setEditIndex] = useState(0)
  const [fieldToEdit, setFieldToEdit] = useState(null)

  const hogiaEntities = schemaPage === 'legal_entities'


  const addNewField = () => {
    const fieldTemplate = new FieldTemplateModel()

    if (hogiaEntities) {
      selectedFields[schemaPage].push({ filterField: "", filterValue: "", fileName: '', filePath: '' })
    } else {
      setFieldToEdit(fieldTemplate)
    }

    if (newSelectedFieldsStructure) {
      setEditIndex(selectedFields[schemaPage].fields.length - 1)
    } else {
      setEditIndex(selectedFields[schemaPage].length - 1)
    }
    setfieldEditorVisible(!fieldEditorVisible)
    setNewField(true)
  }

  const editField = (index) => {
    setEditIndex(index)
    if (newSelectedFieldsStructure) {
      setFieldToEdit(selectedFields[schemaPage].fields[index])
    } else (
      setFieldToEdit(selectedFields[schemaPage][index])
    )
    setfieldEditorVisible(!fieldEditorVisible)
  }

  const handleSearchInput = (value) => {
    setSearchInput(value)
  }

  const copyField = (index) => {
    setEditIndex(index)
    if (newSelectedFieldsStructure) {
      selectedFields[schemaPage].fields.push(JSON.parse(JSON.stringify(selectedFields[schemaPage].fields[index])))
    } else {
      selectedFields[schemaPage].push(JSON.parse(JSON.stringify(selectedFields[schemaPage][index])))
    }
    configStateSetter.selectedFields.set(selectedFields)
  }

  const deleteField = (index) => {
    setEditIndex(index)
    if (newSelectedFieldsStructure) {
      selectedFields[schemaPage].fields.splice(index, 1)
    } else {
      selectedFields[schemaPage].splice(index, 1)
    }
    configStateSetter.selectedFields.set(selectedFields)
  }

  const reloadFields = async () => {
    setLoading(true)
    const secretPrefix = customerStateGetter.systemName ? `${customerStateGetter.integrationType}-${customerStateGetter.systemName}` : customerStateGetter.integrationType
    try {
      if (c1StateSetter.clientSecret.get()) {
        await fetchFieldsAndLists(c1StateSetter)
      } else {
        await fetchFieldsAndListsWithSecrets(`${customerStateGetter.customerName}-${process.env.REACT_APP_KEYVAULT_PREFIX}`, secretPrefix, c1StateSetter)
      }
      const template = await backendApiClient.get(`template/integrationtemplates/${integrationType}/template.json`).data
      Object.keys(settings).map(key => {
        if ('info' in template.settings[key]) {
          settings[key].info = template.settings[key].info
        }
        template.settings[key] = settings[key]
      })
      configStateGetter.fields.set(template.fields)
      configStateGetter.settings.set(template.settings)

    } catch {
      setLoading(false)
    }
    setLoading(false)
  }

  const uploadConfig = async () => {
    setLoading(true)
    let config = {
      selectedFields: configStateGetter.selectedFields,
      settings: configStateGetter.settings,
      employeeFilter: configStateGetter.employeeFilter ? configStateGetter.employeeFilter : {},
      notes: configStateGetter.notes ? configStateGetter.notes : "",
      audit: configStateGetter.audit ? JSON.parse(JSON.stringify(configStateGetter.audit)) : { createdBy: user.username, createdDate: new Date().toISOString(), lastUpdatedBy: user.username, lastUpdated: new Date().toISOString(), auditLog: [] }
    }
    config.audit.lastUpdated = new Date().toISOString()
    config.audit.lastUpdatedBy = user.username
    const auditLog = await createAudit(customerStateGetter, user)
    if (auditLog.hasChanges) {
      config.audit.auditLog.push(auditLog.auditLog)
    }

    const configName = customerStateGetter.systemName ? `${customerStateGetter.integrationType}-${customerStateGetter.systemName}-config.json` : `${customerStateGetter.integrationType}-config.json`
    const updateResponse = await backendApiClient.post(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerStateGetter.customerName}/${configName}`, config)
    if (updateResponse.status === 200) {
      messageStateSetter.set("Config uploaded")
    } else {
      messageStateSetter.set(updateResponse.error)
    }
    setLoading(false)
  }

  const statusColor = {
    production: 'contrastGreen',
    development: 'contrastOrange',
    testing: 'contrastYellow'
  }
  const statusSettingsColor = settings.integrationStatus ? statusColor[settings.integrationStatus.value.value] : ''

  if (loading) {
    return <LoadingPage />
  }
  return (
    <OuterContainer >
      <C1Logo onClick={() => navigate("/")} />
      <ErrorPopup />
      <MappingSideNav navigate={navigate} />
      <Container >
        <ArrowClockwiseIcon onClick={() => reloadFields()} />
        <BigHeaderDiv >{integrationType.substring(0, 1).toUpperCase()}{integrationType.substring(1)} mapper </BigHeaderDiv>
        <CustomerName>{customerIntegrationName} <StatusCircle statusColor={statusSettingsColor} /></CustomerName>

        <InnerContainer >
          <HeaderDiv>
            <BasicButton background="primary" label="Add new field" width="110px" height="35px" margin="10px 5px 10px 10px" handleClick={addNewField} />
            <SearchInput
              value={searchInput}
              handleChange={handleSearchInput}
              placeholder="Search fields"
              width="300px"
              margin="auto 10px"
              padding="8px"

            />
            {(integrationType === 'csv' || integrationType === 'hrm' || integrationType == 'populum' || integrationType == 'videnbarometer' || integrationType == 'adonprem' || integrationType == 'lessor') ?

              <BasicButton background="blue1" label="Filter" width="110px" height="35px" margin="10px auto 10px auto" handleClick={e => setEmployeeFilterVisible(!employeeFilterVisible)} /> : null
            }
            

            <SchemaPageSwitch
              size="big"
              schemaPage={schemaPage}
              setSchemaPage={setSchemaPage}
              options={Object.keys(configStateGetter.selectedFields)}
              selectedFields={configStateGetter.selectedFields}
              settings={settings}
              fromDirections={true}
              integrationDirections={integrationDirections}
              integrationDirection={integrationDirection}
            />

          </HeaderDiv>
          {integrationDirections.length > 1 ?
              <SchemaPageSwitch
                size="small"
                schemaPage={integrationDirection}
                setSchemaPage={setIntegrationDirection}
                options={integrationDirections}
                settings={settings}
                fromDirections={true}
              />
              : null}
          {firstSystem ?
            <SchemaPageSwitch
              size="small"
              schemaPage={selectedSystem}
              setSchemaPage={setSelectedSystem}
              options={Object.keys(originalTemplateGetter.fields[schemaPage])}
            />
            : ""
          }

          {hogiaEntities ?
            <HogiaEntities schemaPage={[schemaPage]} setfieldEditorVisible={setfieldEditorVisible} templateType={integrationType} visible={fieldEditorVisible} index={editIndex} />
            :
            <FieldEditor
              selectedSystem={selectedSystem}
              schemaPage={[schemaPage]}
              setfieldEditorVisible={setfieldEditorVisible}
              integrationType={integrationType}
              visible={fieldEditorVisible}
              editIndex={editIndex}
              configStateGetter={configStateGetter}
              configStateSetter={configStateSetter}
              originalTemplateGetter={originalTemplateGetter}
              settings={settings}
              setFieldToEdit={setFieldToEdit}
              fieldToEdit={fieldToEdit}
              newField={newField}
              setNewField={setNewField}
            />
          }
          <EmployeeFilter
            configStateSetter={configStateSetter}
            visible={employeeFilterVisible}
            schemaPage={schemaPage}
            setEmployeeFilterVisible={setEmployeeFilterVisible} />

          <DragNDropTable
            editIndex={editIndex}
            setFieldToEdit={setFieldToEdit}
            setEditIndex={setEditIndex}
            originalTemplateGetter={originalTemplateGetter}
            selectedFields={configStateGetter.selectedFields}
            configStateSetter={configStateSetter}
            schemaPage={schemaPage}
            setfieldEditorVisible={setfieldEditorVisible}
            editField={editField}
            deleteField={deleteField}
            hogiaEntities={hogiaEntities}
            searchInput={searchInput}
            copyField={copyField}
            selectedSystem={selectedSystem}
          />
        </InnerContainer>
        <ButtonDiv>
          <BasicButton background="primary" label="Back" width="7em" height="30px" margin="auto 20px auto auto" handleClick={() => navigate('/integrationoverview')} />
          <BasicButton background="primary" label="Save config" width="7em" height="30px" margin="auto auto auto auto" handleClick={() => uploadConfig()} inactive={!user.roles.includes("admin")} />
        </ButtonDiv>
      </Container>

    </OuterContainer>
  )
}

const Container = styled.div`

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
`
const OuterContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`

const InnerContainer = styled.div`
  height: 83%;
  width: 96%;
  margin: auto auto auto 15px;
  border: 0.01em solid ${props => props.theme.colors.borderColor};
  background: ${props => props.theme.colors.elevation1};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  ::-webkit-scrollbar{width:7px;height: 10px;};
  ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  min-height: 60px;
  margin: auto;
`
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const BigHeaderDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin: 20px 0 0 0;
  font-size: 2em;
`

const ArrowClockwiseIcon = styled(ArrowClockwise)`
    width: 2em ;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 25px;
    margin-right: 60px;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1.4em;
`
const C1Logo = styled.img.attrs({
  src: c1_logo
})`
width: 60px;
object-fit: contain;
position: absolute;
margin: 20px 0 0 20px;
cursor: pointer;
`
const StatusCircle = styled.div`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin: auto 5px auto 5px;
  display: inline-block;
  background: ${props => props.theme.colors[props.statusColor]};
`

import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import MappingSideNav from '../MappingSideNav/MappingSideNav';
import { useNavigate } from "react-router-dom";
import TemplateEditOptions from './TemplateEditOptions';
import FieldsList from './FieldsList';
import SettingsList from './SettingsList';
import EditField from './EditField';
import BasicButton from '../../SharedComponents/BasicButton';
import AddSystem from './AddSystem';
import EditSetting from './EditSetting';
import AddSetting from './AddSetting';
import BackendApiClient from '../../../services/backendApiClient';
import LoadingPage from '../../SharedComponents/LoadingPage';
import useIntegrationStates from '../../../hooks/useIntegrationStates';
import ErrorPopup from '../../SharedComponents/ErrorPopUp';
import useLoggedInUser from '../../../hooks/useLoggedInUser';

export default function TemplateEditor() {
    const {
        originalTemplateGetter,
        originalTemplateSetter,
        customerStateGetter,
        customerStateSetter,
        configStateSetter, 
        loading,
        setLoading,
        customerIntegrationName,
        messageStateSetter
        } = useIntegrationStates()
    const { user } = useLoggedInUser()
    const backendApiClient = new BackendApiClient()
    const [editSetting, setEditSetting] = useState(Object.keys(customerStateGetter.config.settings)[0])
    const fields = JSON.parse(JSON.stringify(originalTemplateGetter.fields))
    const navigate = useNavigate()
    const [option, setOption] = useState('Fields')
    const [fieldsType, setFieldsType] = useState(Object.keys(fields)[0])
    const selectedFieldsList = fields[fieldsType]
    const [system, setSystem] = useState(Object.keys(selectedFieldsList)[0])
    const [editActive, setEditActive] = useState(false)
    const [addSystemActive, setAddSystemActive] = useState(false)
    const [addSettingActive, setAddSettingActive] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    let selectedSystemFields = selectedFieldsList[system]
    const settings = JSON.parse(JSON.stringify(originalTemplateGetter.settings))
    const customerSettings = JSON.parse(JSON.stringify(customerStateGetter.config.settings))

    if (fieldsType === 'eventTypes' || Array.isArray(selectedFieldsList)) {
        selectedSystemFields = selectedFieldsList
    }

    const setField = (choice, index, attName) => {
        selectedSystemFields[editIndex][attName] = choice
        if (Array.isArray(selectedFieldsList)) {
            originalTemplateSetter.fields[fieldsType].set(selectedSystemFields)
        } else {
            originalTemplateSetter.fields[fieldsType][system].set(selectedSystemFields)
        }

    }

    const setEdit = (index) => {
        setEditActive(true)
        setEditIndex(index)
    }

    const chooseSetting = (setting) => {
        setEditActive(true)
        setEditSetting(setting)
    }

    const setSetting = (choice,index, attName) => { 
        if (attName === 'type') {
            configStateSetter.settings[editSetting][attName].set(choice.value)
        originalTemplateSetter.settings[editSetting][attName].set(choice.value)
        } else {
            const setting = settings[editSetting]
            setting[attName] = choice
            configStateSetter.settings[editSetting].set(setting)
            originalTemplateSetter.settings[editSetting].set(setting)
        }
    }

    const addField = () => {
        let fieldTemplate = selectedSystemFields[0] ? JSON.parse(JSON.stringify(selectedSystemFields[0])) : null
        if (fieldTemplate) {
            for (var key in fieldTemplate) {
                if (fieldTemplate.hasOwnProperty(key)) {
                    fieldTemplate[key] = null;
                }
            }
        } else {
            fieldTemplate = {
                label: null,
                value: null,
                parent: null,
                parentType: null
            }
        }
        fieldTemplate.required = false
        selectedSystemFields.push(fieldTemplate)
        if (Array.isArray(selectedFieldsList)) {
            originalTemplateSetter.fields[fieldsType].set(selectedSystemFields)
        } else {
            originalTemplateSetter.fields[fieldsType][system].set(selectedSystemFields)
        }
        setEditActive(true)
        setEditIndex(selectedSystemFields.length - 1)
    }

    const addSystem = (systemName) => {
        originalTemplateSetter.fields[fieldsType][systemName].set([])
        setAddSystemActive(false)
    }

    const addSetting = (settingName, type) => {
        customerSettings[settingName] = {
            label: null,
            type: type,
            value: null,
            info: null,
        } 
        settings[settingName] = {
            label: null,
            type: type,
            value: null,
            info: null,
        }
        if (type === 'select') {
            settings[settingName].list = []
            settings[settingName].value = {value : "", label: ""}
            customerSettings[settingName].list = []
            customerSettings[settingName].value = {value : "", label: ""}
        }
        customerStateSetter.config.settings.set(JSON.parse(JSON.stringify(customerSettings)))
        originalTemplateSetter.settings.set(JSON.parse(JSON.stringify(settings)))
        setEditSetting(settingName)
        setAddSettingActive(false)
        setEditActive(true)
    }
    const activeAddSetting = (settingName) => {
        setAddSettingActive(true)
    }

    const getSettingFields = useCallback(() => {
        const settingsList = []
        Object.keys(settings).map(setting => {
            settingsList.push({
                name: setting,
                label: customerStateGetter.config.settings[setting].label,
                type: customerStateGetter.config.settings[setting].type,
                section: customerStateGetter.config.settings[setting].section
            })
        })
        return settingsList
    }, [option,settings])

    const uploadToBlob = async () => {
        setLoading(true)
        const updateResponse = await backendApiClient.post(`template/integrationtemplates/${customerStateGetter.integrationType}/template.json`, originalTemplateGetter)
        if (updateResponse.status === 200) {
            messageStateSetter.set('Template uploaded to blob')
        } else {
            messageStateSetter.set('Failed to upload template to blob: ' + updateResponse.error)
        }
        setLoading(false)
    }

    if (loading) {
        return (
            <LoadingPage />
        )
    }

    return (
        <OuterContainer >
            <ErrorPopup />
            <MappingSideNav  navigate={navigate} />
            <Container >
            <HeaderDiv >Template editor</HeaderDiv>
            <CustomerName >{customerIntegrationName}</CustomerName>
            <InnerContainer >
                <TemplateEditOptions
                    
                    option={option}
                    setOption={setOption}
                    options={[
                        'Fields',
                        'Settings'
                    ]}
                />
                {option === 'Fields' ?
                    <TemplateEditOptions
                        option={fieldsType}
                        setOption={setFieldsType}
                        options={Object.keys(fields)}
                    /> : null}

                {option === 'Fields' && !Array.isArray(selectedFieldsList) ?
                    <TemplateEditOptions
                        
                        option={system}
                        setOption={setSystem}
                        addEnabled={true}
                        options={Object.keys(selectedFieldsList)}
                        setAddSystemActive={setAddSystemActive}
                    /> : null}
                {option === 'Fields' ?
                    <>
                        <BasicButton background="primary" label="Add field" width="90px" height="35px" margin="5px auto 0px 10px" handleClick={() => addField()} />
                        <FieldsList  fields={selectedSystemFields} setField={setField} setEdit={setEdit} />
                        <EditField  isActive={editActive} fieldIndex={editIndex} setField={setField} fields={selectedSystemFields} setEditActive={setEditActive} />
                        <AddSystem  addSystem={addSystem} addSystemActive={addSystemActive} setAddSystemActive={setAddSystemActive} />
                    </>

                    : null}

                {option === 'Settings' ?
                    <>  <AddSetting  addSetting={addSetting} addSettingActive={addSettingActive}/>
                        <BasicButton background="primary" label="Add setting" width="90px" height="35px" margin="5px auto 0px 10px" handleClick={() => activeAddSetting()} />
                        <SettingsList  settings={getSettingFields()} chooseSetting={chooseSetting} />
                        <EditSetting setSetting={setSetting} editSetting={editSetting}  isActive={editActive} setting={settings[editSetting]} setEditActive={setEditActive} />

                    </>

                    : null}


            </InnerContainer>
            <ButtonDiv>
        <BasicButton background="primary" label="Back" width="110px" height="30px" margin="auto 10px auto 10px" handleClick={() => navigate('/')} />
        <BasicButton background="primary" label="Save template" width="120px" height="30px" margin="auto auto auto 10px" handleClick={() => uploadToBlob()} inactive={!user.roles.includes("admin")}/>
        </ButtonDiv>
            </Container>

        </OuterContainer>
    )
}


const Container = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
justify-content: center;
   
`
const InnerContainer = styled.div`
height: 83%;
width: 96%;
margin: auto auto auto 15px;
border: 0.01em solid ${props => props.theme.colors.borderColor};
background: ${props => props.theme.colors.elevation1};
border-radius: 30px;
display: flex;
flex-direction: column;
overflow: overlay;
::-webkit-scrollbar{width:10px;height: 10px;};
::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
   
`
const OuterContainer = styled.div`
background: ${props => props.theme.colors.background};
width: 100%;
height: 100%;
display: flex;
flex-direction: row;
justify-content: flex-start;
   
`

const HeaderDiv = styled.div`
text-align: center;
color: ${props => props.theme.colors.primary};
margin: 20px 0 0 0;
font-size: 2em;
`
const ButtonDiv = styled.div`
display: flex;
flex-direction: row;
align-self: center;
justify-content: center;
margin: auto;

`
const CustomerName = styled.div`
  color: ${props => props.theme.colors.contrastBlue};
  text-align: center;
  margin: 4px 0 0 0;
  font-size: 1em;
`
import React from 'react'
import styled from 'styled-components'
import TableField from './TableField';
import TableFieldHogiaEntities from './TableFieldHogiaEntities';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default function DragNDropTable({ selectedFields, editField, deleteField, hogiaEntities, configStateSetter, schemaPage, searchInput, copyField, selectedSystem, originalTemplateGetter }) {
  const newSelectedFieldsStructure = !Array.isArray(selectedFields[schemaPage])
  const draggableColumns =
  {
    fields: {
      name: 'fields',
      items: newSelectedFieldsStructure ? selectedFields[schemaPage].fields : selectedFields[schemaPage]
    }
  }


  const onDrop = (result) => {
    try {
      selectedFields = JSON.parse(JSON.stringify(selectedFields))
      const sourceIndex = result.source.index
      const index = result.destination.index
      if (newSelectedFieldsStructure) {
        const field = selectedFields[schemaPage].fields[sourceIndex]
        selectedFields[schemaPage].fields.splice(sourceIndex, 1)
        selectedFields[schemaPage].fields.splice(index, 0, field)
      } else {
        const field = selectedFields[schemaPage][sourceIndex]
        selectedFields[schemaPage].splice(sourceIndex, 1)
        selectedFields[schemaPage].splice(index, 0, field)
      }
      configStateSetter.selectedFields.set(selectedFields)
    } catch {

    }

  }

  const setFieldBoolean = (fieldObj, fieldName, index) => {
    selectedFields = JSON.parse(JSON.stringify(selectedFields))
    fieldObj = JSON.parse(JSON.stringify(fieldObj))
    fieldObj[fieldName] = !fieldObj[fieldName]
    if (newSelectedFieldsStructure) {
      selectedFields[schemaPage].fields.splice(index, 1, fieldObj)
    } else {
      selectedFields[schemaPage].splice(index, 1, fieldObj)
    }
    configStateSetter.selectedFields.set(selectedFields)
  }

  const searchFields = (searchInput, item) => {

    const c1FieldLabel = item.c1Field && item.c1Field.label ? item.c1Field.label.toLowerCase() : ""
    const c1FielValue = item.c1Field && item.c1Field.value  ? item.c1Field.value.toLowerCase() : ""
    const sourceFieldLabel = item.sourceField && item.sourceField.label ? item.sourceField.label.toLowerCase() : ""
    const sourceFieldValue = item.sourceField && item.sourceField.value ? item.sourceField.value.toLowerCase() : ""

    if (!searchInput) {
      return true
    } else if (c1FieldLabel.includes(searchInput.toLowerCase()) || c1FielValue.includes(searchInput.toLowerCase()) || sourceFieldValue.includes(searchInput.toLowerCase() || sourceFieldLabel.includes(searchInput.toLowerCase()))) {
      return true
    } else {
      return false
    }

  }


  return (
    <InnerContainer >
      <DragDropContext onDragEnd={(result) => onDrop(result)}>
        {Object.entries(draggableColumns).map(([id, column]) => {
          const importMapping = !Array.isArray(selectedFields[schemaPage]) && selectedFields[schemaPage].direction === 'import'
          return (
            <Droppable droppableId={id} key={`droppable-${id}`}>
              {(provided, snapshot) => {
                return (

                  <FieldsToExportContainer
                    {...provided.droppableProps}
                    ref={provided.innerRef}

                    isDraggingOver={snapshot.isDraggingOver}
                    key={`droppableDiv-${id}`}
                  >
                    {column.items.map((item, index) => {
                      let c1FieldLabel = ''
                      let sourceField = ''
                      try {
                        if (item.c1Field.label) {
                          c1FieldLabel = item.c1Field.label ? item.c1Field.label.toLowerCase() : ""
                        }
                      } catch {

                      }
                      try {
                        if (item.sourceField.label) {
                          sourceField = item.sourceField.label ? item.sourceField.label.toLowerCase() : ""
                        }
                      } catch {

                      }
                      const isInSearch = searchFields(searchInput, item)

                      return (
                        <Draggable key={index} draggableId={`${index}+id`} index={index}>
                          {(provided, snapshot) => {
                            const tableField = hogiaEntities ?
                              <TableFieldContainer

                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}

                              >
                                <TableFieldHogiaEntities
                                  refProp={provided.innerRef}
                                  draggableProps={{ ...provided.draggableProps }}
                                  dragHandleProps={{ ...provided.dragHandleProps }}
                                  item={item}
                                  index={index}
                                  editField={editField}
                                  deleteField={deleteField}
                                  copyField={copyField}
                                  key={`item-${index}`} />

                              </TableFieldContainer> :

                              <TableFieldContainer
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}

                              >
                                <TableField
                                  item={item}
                                  index={index}
                                  editField={editField}
                                  deleteField={deleteField}
                                  key={`item-${index}`}
                                  schemaPage={schemaPage}
                                  copyField={copyField}
                                  setFieldBoolean={setFieldBoolean}
                                  selectedSystem={selectedSystem}
                                  originalTemplateGetter={originalTemplateGetter}
                                  importMapping={importMapping}
                                ></TableField>
                              </TableFieldContainer>



                            if (isInSearch) {
                              return (
                                tableField
                              )
                            }

                          }}

                        </Draggable>
                      )
                    })}
                    {provided.placeholder}

                  </FieldsToExportContainer>
                )



              }}
            </Droppable>
          )
        })}
      </DragDropContext>
    </InnerContainer>


  )
}



const InnerContainer = styled.div`
  height: 100%;
  width: 100%;
  margin: 0.8% auto auto auto;
  border-top: 1px solid ${props => props.theme.colors.contrastBlue};

  display: flex;
  flex-direction: column;
  overflow: overlay;
  ::-webkit-scrollbar{width:10px;height: 10px;};
  ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.bluelevation2};border-radius: 30px};

`

const FieldsToExportContainer = styled.div`

    
`

const TableFieldContainer = styled.div`
width: 100%;
height: 6.4vh;

margin: 5px auto 5px auto;
border-radius: 30px;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
z-index: 2;
`
import React, {useState} from 'react'
import styled from 'styled-components'
import InputField from '../SharedComponents/InputField'
import BasicButton from '../SharedComponents/BasicButton'
import CheckboxField from '../SharedComponents/Checkbox'

export default function AddSecretPopUp({visible, setVisible, templateOptions, setTemplateOptions}) {
    const [secret, setSecret] = useState({})

    const addSecretAttribute = (value, index, attName) => {
      const newSecret = { ...secret }
      secret[attName] = value
      setSecret(secret)
    }

    const saveSecret = () => {
      const newOptions = { ...templateOptions }
      if (secret) 
        {
          secret.value= ""
          newOptions.secrets.push(secret)
        }
      setTemplateOptions(newOptions)
      setSecret({})
      setVisible(false)
    }

    if (!visible) {
        return null
    }
  return (
    <Container>
      <HeaderText>Add secret</HeaderText>
      <InputField label="Secret name" placeholder="Integration name" margin="0px auto auto auto" attName="name" value={secret.name} handleChange={addSecretAttribute} />
      <CheckboxField
                    label="Hide secret in UI" 
                    checked={secret.secret}
                    attName="secret"
                    setOption={addSecretAttribute}
                    margin="0px auto auto auto"
                />
      <BasicButton background="primary" label="Save" width="90px" height="30px" margin="10px auto 10px auto" handleClick={saveSecret} />
    </Container>
  )
}

const Container = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    width: 20%;
    height: 20%;
    left: 40%;
    top: 40%;
    background: ${props => props.theme.colors.grey};
    z-index: 100;
`
const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.2em;
    margin: 10px auto 15px auto;
`

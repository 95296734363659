import React, { useState } from 'react'
import styled from 'styled-components';
import { Page, Text, View, Document, StyleSheet, PDFViewer, Image } from '@react-pdf/renderer';
import { colorScheme } from '../../../globalConfig/globalConfig';
import { useNavigate } from 'react-router-dom';
import BasicButton from '../../SharedComponents/BasicButton';
import c1logo from '../../../assets/c1logo.png'
import DocumentationTextInput from './DocumentationTextInput';
import Html from 'react-pdf-html';
import useIntegrationStates from '../../../hooks/useIntegrationStates';
import { firstLetterUppercase } from '../../../services/functions';

export default function GeneratePdfDocumentation({ }) {
  const {configStateGetter, settings, customerName, integrationType} = useIntegrationStates()
  const colors = colorScheme.night
  const navigate = useNavigate()
  const selectedFields = configStateGetter.selectedFields
  const [content, setContent] = useState("")

  const integratedSystem = firstLetterUppercase(integrationType)
  let date = new Date().toUTCString().slice(5, 16);

  const createObjectFields = (objectFields) => {
    const objectFieldsArray = []
    objectFields.map((objectField) => {
      if (objectField.fieldType === 'c1Field') {
        objectFieldsArray.push({
          sourceField: objectField.sourceField.value,
          fieldType: objectField.fieldType,
          value: objectField.c1Field.label,
          valueType: objectField.valueType.label
        })
      } else if (objectField.fieldType === 'hardCoded') {
        objectFieldsArray.push({
          fieldType: objectField.fieldType,
          value: objectField.hardcodedValue,
          sourceField: objectField.sourceField.value,
          valueType: "n/a"
        })
      }
    })
    return objectFieldsArray
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: '#E4E4E4'
    },
    section: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 5,
      padding: 7,
      fontSize: 10,
      backgroundColor: colors.primary,
    },
    section2: {
      flexDirection: 'row',
      justifyContent: 'flex-start',

    },
    conditionRowSection: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

    },
    sectionColumn: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginBottom: 5,
      padding: 7,
      fontSize: 10,
      backgroundColor: colors.primary
    },
    logicSection: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginBottom: 5,
      padding: 7,
      fontSize: 10,
      backgroundColor: colors.primary,
    },
    conditionSection: {
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: 9,
      backgroundColor: colors.primary,
      padding: '2',
      minHeight: '45px',
      marginBottom: 4,
      borderRadius: '10px',
      border: '1px solid black'
    },
    objectSection: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
      marginBottom: 5,
      padding: 7,
      fontSize: 10,
      backgroundColor: colors.primary,
      width: '50%'
    },
    conditionFlexBox: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      margin: 'auto 0 auto 0',
    },
    smallHeader: {
      fontSize: 8,
      color: colors.blue3,
      marginBottom: 1,
    },
    schemaName: {
      fontSize: 20,
      marginBottom: 10,
      marginTop: 4,
      marginLeft: 4,
      color: colors.blue3,
      borderBottom: '1px solid black',
    },
    technicalNotes: {
      fontSize: 12,
      marginBottom: 10,
      marginTop: 4,
      marginLeft: 4,
    },
    headerBanner: {
      backgroundColor: colors.blue4,
      height: '60px',
      flexDirection: 'row',
      color: colors.primary,
      alignItems: 'center'

    },
    text: {
      margin: 'auto 0 auto 0',
    },
    conditionFieldText: {
      margin: 'auto 0 auto 0',
      fontSize: 9,
    },
    operatorText: {
      margin: 'auto 3px auto 3px',
      fontSize: 8,
      color: colors.blue4,
      fontStyle: 'italic'
    },
    fieldSection: {
      flexDirection: 'column',
      minWidth: '140px',
    },
    fieldSectionLogic: {
      flexDirection: 'column',
      minWidth: '60px',
    },
    conditionHeader: {
      fontSize: 7,
      padding: 3,
      marginBottom: 3,
      position: 'absolute',
      top: 0,
    },
    fieldSectionValueType: {
      flexDirection: 'column',
      minWidth: '113px',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 10,
      width: '40px',
    },
    conditionText: {
      fontSize: 8,
      margin: 'auto 3px auto 3px',
      maxWidth: '200px',
    },
    ifText: {
      fontSize: 8,
      margin: 'auto 3px auto 0px',
      color: colors.blue3,
    },
    footer: {
      position: 'absolute',
      right: 20,
      bottom: 5,
      fontSize: 8,
    }
  });

  return (
    <Container colors={colors}>
      <InnerContainer>
        <DocumentationTextInput content={content} setContent={setContent} />
        <PDFViewer style={{ height: '90%', width: '59%' }}>
          <Document>
            <Page wrap size="A4" orientation='landscape' style={styles.page}>
              <View fixed style={styles.footer}><Text>Last revision date: {date}</Text> </View>
              <View style={styles.headerBanner}>
                <Image
                  style={styles.image}
                  src={c1logo}
                />
                <Text>{firstLetterUppercase(customerName)} - {integratedSystem} integration specification</Text>
              </View>
              {content === "" ? null :
                <View >
                  <Text style={styles.schemaName}>Technical notes</Text>
                  <Html style={styles.technicalNotes}>{content}</Html>
                  <Text break></Text>
                </View>
              }
              {Object.keys(selectedFields).map((schemaName, schemaIndex) => {
                let schemaFileName = schemaName
                if (schemaName === 'employee') {
                  try {
                    schemaFileName = settings["csvEmpFileName"].value

                  } catch { }
                }
                if (schemaName === 'organization') {
                  try {
                    schemaFileName = settings["csvOrgFileName"].value

                  } catch { }
                }

                if (schemaName === 'extraFile1') {
                  try {
                    schemaFileName = settings["extraFile1FileName"].value

                  } catch { }
                }
                if (schemaName === 'extraFile2') {
                  try {
                    schemaFileName = settings["extraFile2FileName"].value

                  } catch { }
                }
                if (schemaName === 'extraFile3') {
                  try {
                    schemaFileName = settings["extraFile3FileName"].value

                  } catch { }
                }
                const employeeFields = !Array.isArray(selectedFields[schemaName]) ? selectedFields[schemaName].fields :  selectedFields[schemaName]
                if (employeeFields.length === 0) {
                  return null
                }
                return (
                  <>
                    <View style={styles.schemaName}>
                      <Text>{`${schemaFileName.substring(0, 1).toUpperCase()}${schemaFileName.substring(1)}`}</Text>
                    </View>
                    {
                      employeeFields.map(field => {
                        const sourceField = field.sourceField.value
                        const valueType = field.fieldType === 'c1Field' || field.fieldType == "dataProfile" || field.fieldType == "manager" ? field.valueType.label : 'n/a'
                        const c1Field = field.fieldType === 'c1Field' ? field.c1Field.label + " (" + field.c1Field.value + ")" : field.fieldType === 'hardCoded' ? field.hardCodedValue : field.fieldType === 'manager' ? field.c1Field.label : field.fieldType == "dataProfile" ? field.dataProfileField.label : 'n/a'
                        const hardCodedValue = field.hardCodedValue
                        const fieldType = field.fieldType === 'c1Field' ? 'C1 field' : field.fieldType === 'hardCoded' ? 'Hardcoded value' : field.fieldType === 'manager' ? "Manager field" : 'DataProfile'
                        let concatedFields = ''
                        const validFrom = field.validFrom ? 'Valid from' : ''
                        const plannedChange = field.plannedChange ? 'Planned change' : ''
                        if (field.fieldType === 'concat') {

                          field.concatedOutput.map((concatedField, index) => {
                            const plusSign = index > 0 ? ' + ' : ''
                            if (concatedField.type === 'field') {
                              concatedFields += plusSign + '#' + concatedField.value.label
                              if (concatedField.substringStart || concatedField.substringEnd) {
                                concatedFields += `.substring(${concatedField.substringStart},${concatedField.substringEnd})`
                              }
                            } else {
                              concatedFields += plusSign + `"${concatedField.value}"`
                            }
                          })
                        }

                        if (field.fieldType === 'hardCoded' || field.fieldType === 'c1Field' || field.fieldType === 'manager' || field.fieldType === 'dataProfile') {
                          return (
                            <View wrap={false} style={styles.section}>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                <Text>{sourceField}</Text>
                              </View>
                              <View style={styles.fieldSectionValueType}>
                                <Text style={styles.smallHeader}>Value type</Text>
                                <Text>{valueType}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{fieldType}</Text>
                                <Text>{c1Field}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{validFrom}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{plannedChange}</Text>
                              </View>
                            </View>
                          )
                        }
                        else if (field.fieldType === 'logic') {
                          return (
                            <View style={styles.sectionColumn}>
                              <View style={styles.section2}>
                                <View style={styles.fieldSection}>
                                  <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                  <Text>{sourceField}</Text>
                                </View>
                                <View style={styles.fieldSectionValueType}>
                                  <Text>Field with logic</Text>
                                </View>
                                <View style={styles.fieldSection}>
                                  <Text style={styles.smallHeader}></Text>
                                </View>
                                <View style={styles.fieldSection}>
                                  <Text style={styles.smallHeader}>{validFrom}</Text>
                                </View>
                                <View style={styles.fieldSection}>
                                  <Text style={styles.smallHeader}>{plannedChange}</Text>
                                </View>
                              </View>
                              <View style={styles.logicSection}>
                                {field.customLogic.map((logic, index) => {
                                  let outPut = logic.fieldType === 'c1Field' ? logic.fieldValue.label : logic.fieldType === 'hardCoded' ? logic.textValue : logic.fieldType === 'calculated' ? logic.calculatedValue : ''
                                  let outPutType = logic.fieldType === 'c1Field' ? 'C1 field' : logic.fieldType === 'hardCoded' ? 'Hardcoded value' : logic.fieldType === 'calculated' ? 'Calculation formula' : ''
                                  const ifText = logic.else ? '' : 'IF'
                                  const objectFields = logic.fieldType === 'object' ? createObjectFields(logic.objectField.fields) : []
                                  return (
                                    <View wrap={false} style={styles.conditionSection}>
                                      <Text style={styles.conditionHeader}>Condition {index + 1}</Text>
                                      <View style={styles.section2}>
                                        <Text style={styles.text}>Output  -     </Text>
                                        {logic.fieldType === "object" ?
                                        <View wrap={false} style={styles.sectionColumn}>
                                          {
                                            objectFields.map((objectField, index) => {
                                              const fieldTypeHeader = objectField.fieldType === 'c1Field' ? 'C1 field' : objectField.fieldType === 'hardCoded' ? 'Hardcoded value' : 'n/a'
                                              return (
                                                
                                                <View wrap={false} style={styles.objectSection}>
                                                  <View style={styles.fieldSection}>
                                                    <Text style={styles.conditionHeader}>Object field {index + 1}</Text>
                                                  </View>
                                                  <View style={styles.fieldSection}>
                                                    <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                                    <Text>{objectField.sourceField}</Text>
                                                  </View>
                                                  <View style={styles.fieldSectionValueType}>
                                                    <Text style={styles.smallHeader}>Value type</Text>
                                                    <Text>{objectField.valueType}</Text>
                                                  </View>
                                                  <View style={styles.fieldSection}>
                                                    <Text style={styles.smallHeader}>{fieldTypeHeader}</Text>
                                                    <Text>{objectField.value}</Text>
                                                  </View>

                                                </View>
                                                
                                              )
                                            })
                                          }
                                          </View>
                                          :
                                          <View style={styles.fieldSectionLogic}>
                                            <Text style={styles.smallHeader}>{outPutType}</Text>
                                            <Text>{outPut}</Text>
                                          </View>
                                        }
                                        <Text style={styles.ifText}>{ifText}</Text>
                                        {logic.conditionFields.map((conditionField, index) => {
                                          const conditionC1Field = conditionField.field.label
                                          const andText = index > 0 ? ' and ' : ''
                                          let condtionValues = ''
                                          if (conditionField.hasValueFromList) {
                                            conditionField.listValues.map((value, index) => {
                                              const orText = index > 0 ? ' or ' : ''
                                              condtionValues += orText + value.label
                                            })
                                          } else {
                                            condtionValues = conditionField.value.replace(',', ' or ')
                                          }
                                          if (!logic.else) {
                                            return (
                                              <View style={styles.conditionRowSection}>
                                                <Text style={styles.ifText}>{andText}</Text>
                                                <View style={styles.conditionFlexBox}>
                                                  <Text style={styles.conditionFieldText}> {conditionC1Field}</Text>
                                                  <Text style={styles.operatorText}> {conditionField.operator.label.toLowerCase()}</Text>
                                                  <Text style={styles.conditionText}> {condtionValues}</Text>
                                                </View>
                                              </View>
                                            )

                                          } else {
                                            return (
                                              <View style={styles.section2}>
                                                <Text style={styles.text}>default value</Text>
                                              </View>
                                            )
                                          }

                                        })}
                                      </View>
                                    </View>
                                  )
                                })}
                              </View>
                            </View>
                          )
                        }
                        else if (field.fieldType === 'concat') {
                          return (
                            <View wrap={false} style={styles.section}>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                <Text>{sourceField}</Text>

                              </View>
                              <View style={styles.fieldSectionValueType}>
                                <Text style={styles.smallHeader}>Value type</Text>
                                <Text>{valueType}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>Concated fields</Text>
                                <Text>{concatedFields}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{validFrom}</Text>
                              </View>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{plannedChange}</Text>
                              </View>

                            </View>
                          )
                        } else if (field.fieldType === 'object') {
                          const objectFields = createObjectFields(field.objectField.fields)
                          return (
                            <View style={styles.logicSection}>
                              <View style={styles.fieldSection}>
                                <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                <Text>Object</Text>
                              </View>

                              {objectFields.map((objectField, index) => {
                                const fieldTypeHeader = objectField.fieldType === 'c1Field' ? 'C1 field' : objectField.fieldType === 'hardCoded' ? 'Hardcoded value' : 'n/a'
                                return (
                                  <View wrap={false} style={styles.objectSection}>
                                    <View style={styles.fieldSection}>
                                      <Text style={styles.conditionHeader}>Object field {index + 1}</Text>
                                    </View>
                                    <View style={styles.fieldSection}>
                                      <Text style={styles.smallHeader}>{integratedSystem} field</Text>
                                      <Text>{objectField.sourceField}</Text>
                                    </View>
                                    <View style={styles.fieldSectionValueType}>
                                      <Text style={styles.smallHeader}>Value type</Text>
                                      <Text>{objectField.valueType}</Text>
                                    </View>
                                    <View style={styles.fieldSection}>
                                      <Text style={styles.smallHeader}>{fieldTypeHeader}</Text>
                                      <Text>{objectField.value}</Text>
                                    </View>


                                  </View>
                                )
                              })}

                            </View>
                          )
                        }

                      })
                    }
                    {schemaIndex === employeeFields.length - 1 ? null : <Text break></Text>}
                  </>)
              })}
            </Page>
          </Document>
        </PDFViewer>
      </InnerContainer>
      <BasicButton background="primary" label="Back" width="170px" height="35px" margin="15px auto 10px auto" handleClick={() => navigate('/mapping')} />
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    margin: auto auto auto auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: overlay;

`
const InnerContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 97%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: overlay;

`

const C1Logo = styled.img.attrs({
  src: c1logo
})`
width: 90px;

`

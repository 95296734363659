import {useState, useEffect} from 'react'
import useIntegrationStates from './useIntegrationStates'
import BackendApiClient from '../services/backendApiClient'

export default function useSchedules() {
  const backendApiClient = new BackendApiClient()
  const {
    originalTemplateGetter, 
    loading,
    setLoading,
    integrationType,
    customerIntegrationName,
    messageStateSetter,
    customerName,
    systemName
    } = useIntegrationStates()


    const configNameOptions = {}
    if (originalTemplateGetter.configName) {
        
      originalTemplateGetter.configName.map( item => {
            configNameOptions[item.label] = { lastSuccessfulRun: 'No runs yet', lastRun: 'No runs yet' }
        })
    }
    const initialLastRun = originalTemplateGetter.configName ? configNameOptions : { lastSuccessfulRun: 'No runs yet', lastRun: 'No runs yet' }
    const [schedule, setSchedule] = useState([])
    const [lastRun, setLastRun] = useState(initialLastRun)
    const [editActive, setEditActive] = useState(false)
    const [editIndex, setEditIndex] = useState(0)
    const [showUpdate, setShowUpdate] = useState(false)
    const [showTestRun, setShowTestRun] = useState(false)
    const integrationTypeName = systemName ? `${integrationType}-${systemName}` : integrationType

    const getSchedules = async () => {
      setLoading(true)
      const schedule = await backendApiClient.get(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/scheduler/integrationrunner.json`)
      if (schedule.status !== 200) {
        messageStateSetter.set('Could not fetch schedules')
          return setLoading(false)
      }

      if (originalTemplateGetter.configName) {
        originalTemplateGetter.configName.map(async item => {

              const lastRunRequest = await backendApiClient.get(`azuretables/integrationinfo/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerName}/${integrationType}-${item.value}`)
              
              if (lastRunRequest.status === 200) {
                  lastRun[item.label].lastSuccessfulRun = lastRunRequest.data.lastSuccessfulRun
                  lastRun[item.label].lastRun = lastRunRequest.data.lastRun
                  setLastRun({...lastRun})
              }
          })

      } else {
          const lastRunRequest = await backendApiClient.get(`azuretables/integrationinfo/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerName}/${integrationTypeName}`)

          if (lastRunRequest.status === 200) {
              setLastRun(lastRunRequest.data)
          }
      }
      setSchedule(schedule.data)
      setLoading(false)
  }

  useEffect(() => {
      getSchedules()
  }, [])

  return {
    configNameOptions,
    initialLastRun,
    schedule,
    setSchedule,
    lastRun,
    setLastRun,
    editActive,
    setEditActive,
    editIndex,
    setEditIndex,
    showUpdate,
    setShowUpdate,
    showTestRun,
    setShowTestRun,
    messageStateSetter,
    customerName,
    systemName,
    integrationType,
    customerIntegrationName,
    loading,
    setLoading,
    originalTemplateGetter,
    integrationTypeName
  }
}

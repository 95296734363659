import { getAccessToken } from "./accessToken";
const BASEURL = process.env.REACT_APP_BACKEND_URL;


export const getConfigFromBlob  = async (connectionString,containerName) => {

    try {
        const accessToken = await getAccessToken();
        const body = {}
        const rawResponse = await fetch( BASEURL + `/template/${containerName}/config.json`,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'containerName': containerName,
                'connectionString': connectionString,
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (rawResponse.status !== 200) {
            const message = await rawResponse.json()
            
            return false
        }

        const response = await rawResponse.json()
        if (response) {

            return response
        } else {
            return false
        }
        

    }catch(err){
        console.log(err)
        return false
    }
}


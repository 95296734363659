
import { getAccessToken } from "./accessToken";
const BASEURL = process.env.REACT_APP_BACKEND_URL;

export const fetchFieldsAndLists = async (stateRaw) => {


    try {
        const accessToken = await getAccessToken();
        const rawResponse = await fetch( BASEURL + '/fields',{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Tenant': stateRaw.clientUrl.get(),
                'Client-Id': stateRaw.clientId.get(),
                'Client-Secret': stateRaw.clientSecret.get(),
                'ReferenceUser': "",
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (rawResponse.status !== 200) {
            const error = await rawResponse.json()
            
            return [false,error.error]
        }
        
        let fields = await rawResponse.json()
        if (!fields) {
            
            return [false, fields]
        } else {
            stateRaw.c1Fields.set(fields.c1Fields)
            stateRaw.absenceFields.set(stateRaw.absenceFields.set(fields.absenceFields.concat([{label: "Profile id", value: "profileId"}, {label: "Employee id", value: "employeeId"}])))
            if (fields.lists) {
                const fieldsList = fields.lists
                fieldsList["8"] = fields.orgs
                fieldsList.dataProfiles = fields.dataProfiles
                stateRaw.lists.set(fields.lists)
            } else {
                stateRaw.lists.set({})
            }         
            return [true, ""]
        }
        

    }catch(err){
        return [false, "Unable to connect to backend, make sure you are on Sandefjord VPN"]
    }

};

export const fetchFieldsAndListsWithSecrets = async (keyVaultName, systemName,stateRaw) => {


    try {
        const accessToken = await getAccessToken();
        const rawResponse = await fetch( BASEURL + `/fields/${keyVaultName}/${systemName}`,{
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        });

        if (rawResponse.status !== 200) {
            const error = await rawResponse.json()
            
            return [false,error.error]
        }
        
        let fields = await rawResponse.json()
        if (!fields) {
            
            return [false, fields]
        } else {
            stateRaw.c1Fields.set(fields.c1Fields)
            stateRaw.absenceFields.set(fields.absenceFields.concat([{label: "Profile id", value: "profileId"}, {label: "Employee id", value: "employeeId"}]))
            if (fields.lists) {
                const fieldsList = fields.lists
                fieldsList["8"] = fields.orgs
                fieldsList.dataProfiles = fields.dataProfiles
                stateRaw.lists.set(fields.lists)
            } else {
                stateRaw.lists.set({})
            }         
            return [true, ""]
        }
        

    }catch(err){
        return [false, "Unable to connect to backend, make sure you are on Sandefjord VPN"]
    }

};
import React from 'react'
import styled from 'styled-components'
import {Gear} from '@styled-icons/bootstrap/Gear'
import {Map} from  '@styled-icons/bootstrap/Map'
import {Edit} from "@styled-icons/fluentui-system-regular/Edit"
import {CalendarRange} from "@styled-icons/bootstrap/CalendarRange"
import {Key} from "@styled-icons/bootstrap/Key"
import {PencilSquare} from "@styled-icons/bootstrap/PencilSquare"
import { useLocation } from 'react-router-dom';


export default function DeltaReportSideNav({setSideNav, colors, sideNav, navigate}) {
    const location = useLocation();

  return (
    <Container>
        <NavLink active={sideNav === 'mapping'} colors={colors} onClick={() => setSideNav('mapping')}>
        <MapIcon/>
            Mapping
            </NavLink>
        <NavLink active={sideNav === 'settings'} colors={colors} onClick={() => setSideNav('settings')}>
            <GearIcon/>
            Settings
            </NavLink>
            <NavLink active={location.pathname === '/scheduler'}  onClick={() => navigate('/scheduler')}>
                <CalendarIcon />
                Schedule
            </NavLink>
            <NavLink active={location.pathname === '/editsecrets'}  onClick={() => navigate('/editsecrets')}>
                <KeyIcon />
                Secrets
            </NavLink>
            <NavLink active={location.pathname === '/notes'}  onClick={() => navigate('/notes')}>
                <NotesIcon />
                Notes
            </NavLink>
    </Container>
  )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto 10px 300px 16px;
    width: 60px;
`
const NavLink = styled.div`
    color: ${props => props.active ?  props.theme.colors.contrastBlue : props.theme.colors.primary};
    display: flex;
    flex-direction: column;
    margin: 20px auto auto auto;
    text-align: left;
    font-size: ${props => props.active ? "1.1em" : "1em"};
    &:hover {
        cursor: pointer;
        font-weight: 600;
    }`

const GearIcon = styled(Gear) `
width: 1.8em ;
margin: auto auto auto auto;

`
const MapIcon = styled(Map) `
width: 1.8em ;
margin: auto auto auto auto;

`
const CalendarIcon = styled(CalendarRange)`
width: 1.8em ;
margin: auto auto auto auto;

`

const EditIcon = styled(Edit)`
width: 1.8em ;
margin: auto auto auto auto;

`
const KeyIcon = styled(Key)`
width: 1.8em ;
margin: auto auto auto auto;

`
const NotesIcon = styled(PencilSquare)`
width: 1.8em ;
margin: auto auto auto auto;

`
import React from 'react'
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useContext } from 'react'
import { ThemeContext } from 'styled-components'

export default function PagninationComponent({pageOptions, pageIndex, handlePageChange, classes}) {
    const themeContext = useContext(ThemeContext)
    const StyledPagination = styled(Pagination)(({ theme }) => ({
        "& .MuiPaginationItem-root": {
          color: themeContext.colors.primary,
          backgroundColor: themeContext.colors.elevation1,
        },
        "& .MuiPaginationItem-root.Mui-selected": {
          backgroundColor: themeContext.colors.contrastBlue,
          color: themeContext.colors.contrast,
        },
        "& .MuiPaginationItem-root.Mui-selected:hover": {
            backgroundColor: themeContext.colors.blue3,
            color: themeContext.colors.primary,
          },
      }));

      const theme = createTheme({
        // Define your MUI theme settings here
      });
  return (
    <div>
    <StyledPagination themeContext={themeContext} count={pageOptions.length} page={pageIndex+1} onChange={handlePageChange}/>
    </div>
  )
}

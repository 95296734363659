import React, {useState} from 'react'
import styled from 'styled-components'
import { Cron } from 'react-js-cron'
import './styles.css'
import BasicButton from '../../SharedComponents/BasicButton'
import InputField from '../../SharedComponents/InputField'


export default function CronEditor({cronExpression, editSchedule, index, configNameOptionValue, showCronEditor, setShowCronEditor}) {


    const [cronValue, setCronValue] = useState(cronExpression)


    const setValue = (value) => {
        editSchedule(cronValue, index,"cronExpression." + configNameOptionValue)
        setShowCronEditor(false)
    }

    const editCron = (value) => {
        setCronValue(value)

    }
    if (!showCronEditor) {
        return null
    }
  return (
    <Container>
        <InputField width="100px" margin="auto auto 15px auto"  value={cronValue} handleChange={editCron} />
        <Cron value={cronValue} setValue={setCronValue}/>
        <BasicButton fontSize="0.7em" background="primary" label="Save" width="100px" height="20px" margin="20px auto 0px auto" handleClick={() => setValue()} />
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px;
    font-size: 1em;
    color: ${props => props.theme.colors.primary};
    border-radius: 30px;
    z-index: 100;
`
const CronText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1em;
    text-align: center;
    margin: 0px auto 15px auto;
    border-radius: 30px;
    padding: 10px 10px;
    background: ${props => props.theme.colors.elevation1};
`
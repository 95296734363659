import React from 'react'
import styled from 'styled-components'

export default function InputFieldSubstring({ value, handleChange, index, label,attName}) {
  return (
    <InputContainer>
    <StyledLabel >{label}</StyledLabel>
    <ConditionValueInput onChange={e => handleChange(e.target.value,index,attName)} 
    value={value} 
    type="text" />
    </InputContainer>
  )
}

const ConditionValueInput = styled.input`
    display: flex;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.grey1};
    outline: none;
    border-style:solid;
    border:none;
    margin:0 0 0 0x;
    border-radius: 5px;
    text-align: left;
    font-size: 1em;
    width: 20px;
    padding: 10px;
`
const StyledLabel = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.primary};
    font-size: 1.1em;
    margin: 0 0 2px 0;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin: 5px;
    font-size: 0.8em;
`
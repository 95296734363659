import React, {useState} from 'react'
import styled  from 'styled-components'
import {Edit} from "@styled-icons/fluentui-system-regular/Edit"
import {Trash} from "@styled-icons/bootstrap/Trash"
import {Copy} from "@styled-icons/fluentui-system-regular/Copy"
import TableFieldHover from './TableFieldHover';
import useIntegrationStates from '../../../hooks/useIntegrationStates'

export default function TableField({item,editField, index, deleteField, schemaPage, copyField, setFieldBoolean,selectedSystem, originalTemplateGetter, importMapping}) {
    const {integrationType} = useIntegrationStates()
    const sourceField = item.sourceField ? item.sourceField.label : ""
    const sourceFieldRef = item.sourceField ? item.sourceField.value : ""
    const requiredField = item.sourceField &&  item.sourceField.required ? "*" : ""
    const valueType = item.valueType ? item.valueType.label : ""
    const c1FieldName =item.fieldType === 'c1Field' ? 'C1 field' : 'Manager field'
    const c1Headers = selectedSystem === "c1headers"
    const eventTypeHeader = integrationType.includes("sdworx") ? "Entity" : "EventType"
    const [active, setActive] = useState(false)
    const c1Field = Array.isArray(item.c1Field) ? item.c1Field.map(obj => obj.label).join(', ') : item.c1Field.label + "(" + item.c1Field.value + ")"

  return (
    <TableFieldContainer  
    >
      <RowField  width="0.8%">
      {index + 1}
    </RowField> 
      {item.fieldType === 'logic' ? 
      <RowField  width="24%">
      C1 field
    <FieldName >Custom logic</FieldName>
    </RowField> : 
      item.fieldType === 'hardCoded' ? 
        <RowField  width="24%">
        Hardcoded value
      <FieldName >{item.hardCodedValue}</FieldName>
      </RowField> : 
      item.fieldType === 'concat' ? 
      <RowField  width="24%">
      C1 field
    <FieldName >Concated fields</FieldName>
    </RowField> 
    :
    item.fieldType === 'calculated' ?
    <RowField  width="24%">
      C1 field
    <FieldName >Calculated field</FieldName>
    </RowField>
    : item.fieldType === 'object' ?
    <RowField  width="24%">
      Object field
    <FieldName >{item.objectField.objectName.label}</FieldName>
    </RowField>
    : item.fieldType === 'dataProfile' ?
    <RowField  width="24%">
      Data profile
    <FieldName >{item.dataProfileField.label}</FieldName>
    </RowField>
    :
      <RowField  width="24%">
      {c1FieldName}
    <FieldName >{c1Field.slice(0, 70)}</FieldName>
    </RowField>
    }
    {!importMapping ? 
    (item.fieldType !== "c1Field") ? 
      <RowFieldValueType >
        Value type
      <FieldName >n/a</FieldName>
      </RowFieldValueType> : 
      <RowFieldValueType >
      Value type
    <FieldName >{valueType}</FieldName>
    </RowFieldValueType>
   : null }
      
      
      <RowField  width="20%">
      {c1Headers ? "Csv header" : importMapping ? "Source field" : "Target field"}
      <FieldName  onMouseEnter={() => setActive(true)} onMouseLeave={() => setActive(false)} >
        {c1Headers ? item.c1Field.label : sourceField}
        <ReqiuredField>{requiredField}</ReqiuredField>
        <TableFieldHover active={active} fieldName={sourceFieldRef} />
        </FieldName>
      </RowField>
      {'eventType' in originalTemplateGetter.fieldTemplate && schemaPage !== "organization" && !importMapping ? 
      <RowField  width="14%">
        {eventTypeHeader}
      <FieldName >{item.eventType.label}</FieldName>
      </RowField> :""}
      {!importMapping ? <SettingBox visible={'alwaysInclude' in originalTemplateGetter.fieldTemplate} active={item.alwaysInclude}  onClick={() => setFieldBoolean(item, 'alwaysInclude', index)}>Always incl.</SettingBox> : null}
      <SettingBox visible={'validFrom' in originalTemplateGetter.fieldTemplate} active={item.validFrom}  onClick={() => setFieldBoolean(item, 'validFrom', index)}>Valid from</SettingBox>
      {!importMapping ? <SettingBox visible={'plannedChange' in originalTemplateGetter.fieldTemplate} active={item.plannedChange}  onClick={() => setFieldBoolean(item, 'plannedChange', index)}>Planned change</SettingBox> : null}
      {importMapping ? <SettingBox visible={true} active={item.identifier}  onClick={() => setFieldBoolean(item, 'identifier', index)}>Identifier</SettingBox> : null}
      {('payrollInfo' in item && schemaPage === 'employees') ?
    <SettingBox active={item.payrollInfo}  onClick={() => setFieldBoolean(item, 'payrollInfo', index)}>Info-lart</SettingBox> : ""  
    }
      
      <EditIcon onClick={() => editField(index)} />
      <CopyIcon onClick={() => copyField(index)} />
      <DeleteIcon onClick={() => deleteField(index)} />
    </TableFieldContainer>
  )
}

const TableFieldContainer = styled.div`
width: 100%;
height: 100%;
background: ${props => props.theme.colors.elevation1};
margin: 5px auto 5px auto;
border-radius: 30px;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
z-index: 2;
`

const FieldName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.5em;
    position: relative;
`
const EditIcon = styled(Edit) `
   width: 2em ;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`
const DeleteIcon = styled(Trash) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`

const CopyIcon = styled(Copy) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`

const RowField = styled.div`
  display: flex; 
  flex-direction: column;
  color: ${props => props.theme.colors.contrastBlue};
  font-size: 0.8em;
  width: ${props => props.width};
`
const RowFieldValueType = styled.div`
  display: flex; 
  flex-direction: column;
  color: ${props => props.theme.colors.contrastBlue};
  font-size: 0.8em;
  width: 14%;
`

const SettingBox = styled.div`
  background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation3};
  padding: 7px;
  border-radius: 30px;
  color: ${props => props.theme.colors.primary};
  visibility: ${props => props.visible ? "visible" : "hidden"};
  font-size: 0.75em;
  &:hover {
    cursor:pointer;
  }
`
const ReqiuredField = styled.div`
  color: ${props => props.theme.colors.contrastRed};
  font-size: 1.2em;
  display: inline;
`
import React from 'react'
import styled from 'styled-components'
import InfoHoverBox from '../SharedComponents/InfoHoverBox'

export default function InputField({value, handleChange, index, label,attName, width, info, margin}) {
  return (
    <InputContainer margin={margin}>
    <StyledLabel >{label}
    {info ? <InfoHoverBox text={info}/> : ""}
    </StyledLabel>
    <ConditionValueInput onChange={e => handleChange(e.target.value,index,attName)} 
    value={value} 
    type="text" 
    width={width}/>
    </InputContainer>
  )
}

const ConditionValueInput = styled.input`
    display: flex;
    color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.grey1};
    outline: none;
    border-style:solid;
    border:none;
    margin:0 0 0 0x;
    border-radius: 30px;
    text-align: left;
    font-size: 1em;
    width: ${props => props.width ? props.width : "180px"};
    padding: 10px;
`
const StyledLabel = styled.div`
    display: flex;
    flex-direction: row;
    color: ${props => props.theme.colors.primary};
    font-size: 1.1em;
    margin: 0 0 2px 10px;
    align-items: center;
`
const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    margin: ${props => props.margin ? props.margin : "5px"};
`
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig } from "../auth-config";

export const getAccessToken = async () => {

        const msalInstance = new PublicClientApplication(msalConfig)
        await msalInstance.initialize()

        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account');
        }

        const tokenRequest = {
            scopes: [process.env.REACT_APP_SCOPES],
            account: account
        };

        const response = await msalInstance.acquireTokenSilent(tokenRequest);
        return response.accessToken;
};

import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import LoadingPage from '../SharedComponents/LoadingPage'
import InputField from '../SharedComponents/InputField'
import { useNavigate } from 'react-router-dom'
import BasicButton from '../SharedComponents/BasicButton'
import { fetchFieldsAndLists } from '../../services/getC1Data';
import ErrorPopup from '../SharedComponents/ErrorPopUp'
import useIntegrationStates from '../../hooks/useIntegrationStates'
import useLoggedInUser from '../../hooks/useLoggedInUser'
import BackendApiClient from '../../services/backendApiClient'


export default function NewIntegration() {
    const [customerlist, setCustomersList] = useState(["frejtest", "rejlers", "customer1", "cusomete2", "Cusomer4", "cusokf2", "ciosko", "vusoiad", "fudisu", "sdfjsk"])
    const [integrationTypes, setIntegrationTypes] = useState({ integrationTypes: ["agda", "csv", "sdworx"], csv: ["protime", "medhelp"] })
    const [customer, setCustomer] = useState('')
    const navigate = useNavigate()
    const [integrationType, setIntegrationType] = useState('')
    const [systemName, setSystemName] = useState('')
    const [loading, setLoading] = useState(false)
    const [emptyIntegrationType, setEmptyIntegrationType] = useState(integrationType === '')
    const [emptySystemName, setEmptySystemName] = useState(systemName === '')
    const { c1StateSetter, c1StateGetter, originalTemplateSetter, messageStateSetter, customerStateSetter } = useIntegrationStates()
    const emptyCustomer = customer === ''
    const inactiveNextButton = emptyCustomer || emptyIntegrationType  || c1StateGetter.clientUrl === '' || c1StateGetter.clientId === '' || c1StateGetter.clientSecret === ''
    const { user } = useLoggedInUser()
    const backendApiClient = new BackendApiClient()

    const fetchCustomerList = async () => {
        setLoading(true)
        const customersResponse = await backendApiClient.get(`listblob/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}`)
        if (customersResponse.status === 200) {
            setCustomersList(Object.keys(customersResponse.data))
        }
        const integrationTypesResponse = await backendApiClient.get('listintegrationtypes/integrationtemplates')
        if (integrationTypesResponse.status === 200) { 
            setIntegrationTypes(integrationTypesResponse.data)
        }
        setLoading(false)
    }

    const setIntegrationTypeFunction = (integrationType) => {
        setIntegrationType(integrationType)
        setEmptyIntegrationType(false)
    }
    const setSystemNameFunction = (systemName) => {
        setSystemName(systemName)
        setEmptySystemName(false)
    }

    const setValues = (value, integrationType, valueName) => {
        if (valueName === 'customer') {
            setCustomer(value)
            
        }else if (valueName === 'systemName'){
            setSystemName(value)
            if (!value) {
                setEmptySystemName(true)
            }
        }
         else {
            c1StateSetter[valueName].set(value)
        }
    }


    const handleNext = async () => {
        setLoading(true)
        customerStateSetter.customerName.set(customer.toLowerCase())
        customerStateSetter.integrationType.set(integrationType)
        customerStateSetter.systemName.set(systemName.toLowerCase())
        const fieldsResult = await fetchFieldsAndLists(c1StateSetter)
        if (fieldsResult[0]) {
 
        } else {
            setLoading(false)
           return messageStateSetter.set(fieldsResult[1])
        }
        // Get integration template from blob
        const templateResponse = await backendApiClient.get(`template/integrationtemplates/${integrationType}/template.json`)

        if (templateResponse.status !== 200) {
            messageStateSetter.set(`Failed to get template: ${templateResponse.error}`)
        } else {
            customerStateSetter.config.set(JSON.parse(JSON.stringify(templateResponse.data)))
            customerStateSetter.config.audit.set({ createdBy: user.username, createdDate: new Date().toISOString(), lastUpdatedBy: user.username, lastUpdated: new Date().toISOString(), auditLog: [] })
            originalTemplateSetter.set(JSON.parse(JSON.stringify(templateResponse.data)))
        }

        // Create keyvault if not exist
        const creataKeyVaultResponse = await backendApiClient.get('secrets/' + customer)
        if (creataKeyVaultResponse.status !== 200) {
            messageStateSetter.set(creataKeyVaultResponse.error)
        }
        setLoading(false)
        navigate('/newintegration/options')
    }

    useEffect(() => {
        fetchCustomerList()
    }, [])

    if (loading) {
        return <LoadingPage />
    }
    return (

        <Container>
            <ErrorPopup />
            <HeaderText>Add new integration</HeaderText>
            <FlowNumberText><b>1.</b> Customer name (choose excisting, or type to add new)</FlowNumberText>
            <CustomerList isUnset={emptyCustomer} >
                {customerlist.map((customerFromList) => {
                    const isActiveCustomer = customerFromList === customer
                    
                    const customerName = customerFromList.substring(0, 1).toUpperCase() + customerFromList.substring(1)
                    return (
                        <CustomerName isUnset={emptyCustomer}  active={isActiveCustomer} onClick={() => setCustomer(customerFromList)} >{customerName}</CustomerName>
                    )
                })
                }
            </CustomerList>
            <InputField margin="17px auto 10px auto" label="Customer name" value={customer} attName="customer" handleChange={setValues} />
            {customer ?
                <>
                    <FlowNumberText><b>2.</b> Integration type</FlowNumberText>
                    <CustomerList onMouseEnter={() => setEmptyIntegrationType(true)} isUnset={emptyIntegrationType}  >
                        {integrationTypes.integrationTypes.map((integrationTypeFromList) => {
                            const isActiveCustomer = integrationTypeFromList === integrationType
                            const integrationTypeName = integrationTypeFromList.substring(0, 1).toUpperCase() + integrationTypeFromList.substring(1)
                            
                            return (
                                <CustomerName isUnset={emptyIntegrationType} active={isActiveCustomer} onClick={() => setIntegrationTypeFunction(integrationTypeFromList)} >{integrationTypeName}</CustomerName>
                            )
                        })
                        }
                    </CustomerList>
                    <ActiveChoiceText fontSize="1.6em" onMouseEnter={() => setEmptyIntegrationType(true)} onMouseLeave={() => setEmptyIntegrationType(false)} >{integrationType.substring(0, 1).toUpperCase() + integrationType.substring(1)}</ActiveChoiceText>
                    {integrationType === "deltareport" ? 
                   <InputField margin="17px auto 10px auto" label="Report name" value={systemName} attName="systemName" handleChange={setValues} /> : null}
                    {integrationType === "csv" ? 
                        <>
                            <FlowNumberText fontSize="1.2em"><b>2.1</b> System name (choose excisting, or type to add new)</FlowNumberText>
                            <CustomerList onMouseEnter={() => setEmptySystemName(true)} isUnset={emptySystemName}>
                                {integrationTypes.csv.sort().map((systemNameFromList) => {
                                    const isActiveCustomer = systemNameFromList === systemName
                                    const systemNameText = systemNameFromList.substring(0, 1).toUpperCase() + systemNameFromList.substring(1)
                                    return (
                                        <CustomerName isUnset={emptySystemName} fontSize="1.1em" active={isActiveCustomer} onClick={() => setSystemNameFunction(systemNameFromList)} >{systemNameText}</CustomerName>
                                    )
                                })
                                }
                            </CustomerList>
                            {(!integrationTypes.csv.includes(systemName) && systemName )|| (systemName === "custom" )  ? <InputField margin="17px auto 10px auto" label="System name" value={systemName} attName="systemName" handleChange={setValues} /> : 
                            <ActiveChoiceText fontSize="1.2em" onMouseEnter={() => setEmptySystemName(true)} onMouseLeave={() => setEmptySystemName(false)} >{systemName.substring(0, 1).toUpperCase() + systemName.substring(1)}</ActiveChoiceText> }
                        </>
                        : null}
                </>

                :
                null
            }
            {integrationType ?
                <>
                    <FlowNumberText ><b>3.</b> C1 credentials</FlowNumberText>
                    <InputField width="270px" margin="10px auto 10px auto" label="C1 tenant" value={c1StateGetter.clientUrl} attName="clientUrl" handleChange={setValues} />
                    <InputField width="270px" margin="10px auto 10px auto" label="Client id" value={c1StateGetter.clientId} attName="clientId" handleChange={setValues} />
                    <InputField width="270px" margin="10px auto 10px auto" label="Client secret" value={c1StateGetter.clientSecret} attName="clientSecret" handleChange={setValues} />
                </>
                : null
            }
            <ButtonDiv>
                <BasicButton background="primary" label="Back" width="90px" height="30px" margin="auto 10px 20px 10px" handleClick={() => navigate("/optionsmenu")} />
                <BasicButton inactive={inactiveNextButton} background="primary" label="Next" width="90px" height="30px" margin="auto 10px 20px 10px" handleClick={() => handleNext()} />
            </ButtonDiv>

        </Container>
    )
}
const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ::-webkit-scrollbar{width:4px;height: 2px;};
    ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};

`
const HeaderText = styled.div`
    font-size: 2.9em;
    color: ${props => props.theme.colors.primary};
    margin: 30px auto 30px auto;
    text-align: center;
`
const FlowNumberText = styled.div`
    font-size: 1.8em;
    color: ${props => props.theme.colors.primary};
    font-size: ${props => props.fontSize ? props.fontSize : "1.6em"};
    margin: 5px auto 5px auto;
    text-align: center;
`
const CustomerList = styled.div`
    display: flex;
    flex-direction: column;
    color: ${props =>  props.theme.colors.primary};
    align-items: center;
    margin: 5px auto 5px auto;
    padding: 0px 30px;
    max-height: ${props => props.isUnset ? "300px" : "0px"};
    font-size: 1.2em;
    overflow-y: scroll;
    transition: max-height 0.7s ease-in-out;
`
const CustomerName = styled.div`
    color: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.primary};
    opacity: ${props => props.isUnset ? "1" : "0"};
    transition: opacity 0.7s ease-in-out;
    font-size: ${props => props.fontSize ? props.fontSize : "1.4em"};
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.contrastBlue};
        font-weight: bold;
    }
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: auto auto 20px auto;
`
const ActiveChoiceText = styled.div`
    color: ${props => props.theme.colors.contrastBlue};
    font-size: 1.6em;
    margin: 0px auto 0px auto;
    padding: 15px 0px;
    font-size: ${props => props.fontSize ? props.fontSize : "1.4em"};
    
`
import React from 'react'
import styled from 'styled-components'
import rings from '../../assets/rings.svg'
import { useHookstate } from '@hookstate/core'
import { messageState } from '../../States/messageState'

export default function BasicButton({background, width, height, size, color, label, margin, handleClick, loading, fontSize, inactive}) {
  const messageStateSetter = useHookstate(messageState)
  const setMessageInactiveButton = () => {
    messageStateSetter.set("Logged in user lacks admin permission needed to perform this action")
  }

  const onClickAction = inactive ? setMessageInactiveButton : handleClick

  
  if (loading) {
    return (
      <GifContainer background={background} width={width} height={height} margin={margin}>

        <LoaderGif/>
  
      </GifContainer>
    )

  } else {
    return (
      <ButtonContainer inactive={inactive} fontSize={fontSize} onClick={() => onClickAction()} background={background} width={width} height={height} margin={margin}>
         {label}
      </ButtonContainer>
    )
  }
  
}



const ButtonContainer = styled.div`
background: ${props => props.inactive ? props.theme.colors.disabledButton :  props.theme.colors[props.background]};
width: ${props => props.width};
border-radius: 30px;
font-size: ${props => props.fontSize};
max-height: 25px;
align-items: center;
color: ${props => props.theme.colors.contrast};
text-align: center;
vertical-align: middle;
padding: 5px;
font-weight: 600;
margin: ${props => props.margin};
box-shadow: ${props => props.inactive ? "" : "0px 0px 5px 2px rgba(0,0,0,0.13)"} ;
transition: background 0.3s;
&:hover {
  cursor: pointer;
  background: ${props => props.theme.colors.primaryLighter};
}
`
const LoaderGif = styled.img.attrs({
  src: rings
  })`
  width: 30px;
  object-fit: contain;
  
  `

const GifContainer = styled.div`
width: ${props => props.width};
border-radius: 30px;
max-height: 25px;
align-items: center;
text-align: center;
vertical-align: middle;
padding: 5px;
margin: ${props => props.margin};
`
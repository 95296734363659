import React, { useState } from 'react'
import { styled } from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig'
import { Copy } from "@styled-icons/fluentui-system-regular/Copy"
import { Plus } from "@styled-icons/bootstrap/Plus"
import { Trash } from "@styled-icons/bootstrap/Trash"


export default function ReportPageSwitch({ options, reportPage, setReportPage, size, flex, copyReport, addReport, setSheetPage, deleteReport }) {
    const colors = colorScheme.night
    const [showOptions, setShowOptions] = useState(false)

    const setNewReportPage = (index) => {
        setSheetPage(0)
        setReportPage(index)
    }
    const onHover = (index) => {
        setShowOptions(index)
    }

    const onLeave = () => {
        setShowOptions(false);
    };

    return (
        <Container flex={flex} >
            {
                options.map((option, index) => {

                    const active = option.index === reportPage
                    const name = option.name.substring(0, 1).toUpperCase() + option.name.substring(1)
                    const optionButton = flex === 'column' ?
                        <ButtonDiv>
                            <OptionButtonColumn
                                onMouseEnter={e => onHover(index)}
                                onMouseLeave={onLeave}
                                size={size}
                                onClick={() => setNewReportPage(option.index)}
                                active={active}
                                colors={colors}>
                                {name.replace('_', ' ')}
                            </OptionButtonColumn>
                            {showOptions === index ?

                                <OptionsDivColumn
                                    margin='-24% 0 0 94%'
                                    colors={colors}
                                    onMouseEnter={e => onHover(index)}
                                    onMouseLeave={onLeave}
                                >
                                    <CopyIcon colors={colors} onClick={() => copyReport()} />
                                    <DeleteIcon colors={colors} onClick={() => deleteReport('report',index)} />
                                </OptionsDivColumn>
                                : null
                            }
                        </ButtonDiv> :
                        <ButtonDiv>
                            <OptionButton
                                onMouseEnter={e => onHover(index)}
                                onMouseLeave={onLeave}
                                size={size}
                                onClick={() => setReportPage(option.index)}
                                active={active}
                                colors={colors}>
                                {name.replace('_', ' ')}

                            </OptionButton>
                            {showOptions === index ?

                                <OptionsDiv
                                    margin='0px auto 0px 7px'
                                    colors={colors}
                                    onMouseEnter={e => onHover(index)}
                                    onMouseLeave={onLeave}
                                >
                                    <CopyIcon colors={colors} onClick={() => copyReport()} />
                                    <DeleteIcon colors={colors} onClick={() => deleteReport('sheet',index)} />
                                </OptionsDiv>
                                : null
                            }
                        </ButtonDiv>
                    return (
                        optionButton
                    )
                })
            }
            <PlusIcon colors={colors} onClick={() => addReport()} />

        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: ${props => props.flex};
    width: ${props => props.flex === 'column' ? '180px' : 'auto'};
    align-self: center;
    margin: 10px 10px 0 0;
    margin-left: auto;
    z-index: 100;
`

const OptionButton = styled.div`
    padding: ${props => props.size === 'small' ? "4px 8px" : "7px 12px"};
    font-size: ${props => props.size === 'small' ? "0.9em" : "1.2em"};
    border-radius: 30px;
    margin: 0 10px 0 10px;
    background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation3};
    text-align: center;
    color: ${props => props.theme.colors.primary};
    &:hover {
        cursor: pointer;
    }
`
const OptionButtonColumn = styled.div`
    padding: ${props => props.size === 'small' ? "4px 8px" : "7px 12px"};
    font-size: ${props => props.size === 'small' ? "0.9em" : "1.2em"};
    border-radius: 30px;
    margin: 10px 10px 10px 10px;
    background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation3};
    text-align: center;
    color: ${props => props.theme.colors.primary};
    &:hover {
        cursor: pointer;
    }
`
const CopyIcon = styled(Copy)`
   width: 1.2em ;
   font-size: 1.2em;
   margin: auto;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
      color: ${props => props.theme.colors.primary};
    }
`
const DeleteIcon = styled(Trash)`
   width: 1.2em ;
   font-size: 1.2em;
   margin: auto;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
      color: ${props => props.theme.colors.primary};
    }
`

const PlusIcon = styled(Plus)`
   width: 1.2em ;
   font-size: 1.4em;
   margin: auto;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
      color: ${props => props.theme.colors.primary};
    }
`
const OptionsDiv = styled.div`
    position: absolute;
    margin: ${props => props.margin};
    background: ${props => props.theme.colors.background};
    border-radius: 30px;
    width: 70px;
    padding: 4px;
    z-index: 100000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`
const OptionsDivColumn = styled.div`
    position: absolute;
    margin: ${props => props.margin};
    background: ${props => props.theme.colors.background};
    border-radius: 30px;
    width: 50%;
    padding: 5px;
    z-index: 100000;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const ButtonDiv = styled.div`
    position: relative;

    
`
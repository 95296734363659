import React, { useState } from 'react'
import styled from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import { useHookstate } from '@hookstate/core';
import BasicButton from '../SharedComponents/BasicButton';
import DeltaReportPopUp from './DeltaReportFieldPopUp';
import { customerState } from '../../States/customerState';
import { useNavigate } from "react-router-dom";
import LoadingPage from '../SharedComponents/LoadingPage';
import ReportPageSwitch from './ReportPageSwitch';
import DeltaReportDragNDropTable from './DeltaReportDragnDropTable';
import DeltaReportSideNav from './DeltaReportSideNav';
import DeltaReportSettings from './DeltaReportSettings';
import { messageState } from '../../States/messageState';
import CopyFields from './CopyFields';
import { originalTemplate } from '../../States/originalTemplate';
import ErrorPopup from '../SharedComponents/ErrorPopUp';
import BackendApiClient from '../../services/backendApiClient';

export default function DeltaReportBuilder() {
  const backendApiClient = new BackendApiClient()
  const navigate = useNavigate()
  const colors = colorScheme.night
  const [newFieldPopUpVisible, setNewFieldPopUpVisible] = useState(false)
  const [reportPage, setReportPage] = useState(0)
  const [sideNav, setSideNav] = useState('mapping')
  const [sheetPage, setSheetPage] = useState(0)
  const [loading, setLoading] = useState(false)
  const [editIndex, setEditIndex] = useState(0)
  const [searchInput, setSearchInput] = useState('')
  const [showCopyFields, setShowCopyFields] = useState(false)
  const customerStateGetter = useHookstate(customerState).get()
  const configStateGetter = customerStateGetter.config
  const configStateSetter = useHookstate(customerState).config
  const messageStateRaw = useHookstate(messageState)
  const originalTemplateGetter = useHookstate(originalTemplate).get()
  const overallSettings = JSON.parse(JSON.stringify(configStateGetter.settings))
  const reports = JSON.parse(JSON.stringify(configStateGetter.reports))
  const sheetSettings = reports[reportPage].sheets[sheetPage].settings

  const getReportPages = () => {
    const reportPages = []
    reports.map((report, index) => {
      reportPages.push({
        name: report.reportName,
        index: index
      })
    })
    return reportPages

  }


  const getReportSheets = () => {
    const reportSheets = []
    reports[reportPage].sheets.map((sheet, index) => {
      reportSheets.push({
        name: sheet.sheetName,
        index: index
      })
    })
    return reportSheets

  }

  const copyReport = () => {
    const reportCopy = JSON.parse(JSON.stringify(reports[reportPage]))
    reportCopy.reportName = reportCopy.reportName + " - Copy"
    reports.push(reportCopy)
    configStateSetter.reports.set(reports)

  }
  const copySheet = () => {
    const sheetCopy = JSON.parse(JSON.stringify(reports[reportPage].sheets[sheetPage]))
    sheetCopy.sheetName = sheetCopy.sheetName + " - Copy"
    reports[reportPage].sheets.push(sheetCopy)
    configStateSetter.reports.set(reports)

  }

  const addSheet = () => {
    const sheetCopy = JSON.parse(JSON.stringify(originalTemplateGetter.reportTemplate.sheets[0]))
    sheetCopy.sheetName = 'Sheet' + (reports[reportPage].sheets.length + 1)
    reports[reportPage].sheets.push(sheetCopy)
    configStateSetter.reports.set(reports)

  }
  const addReport = () => {
    const reportCopy = JSON.parse(JSON.stringify(originalTemplateGetter.reportTemplate))
    reportCopy.reportName = 'Report ' + (reports.length + 1)
    reports.push(reportCopy)
    configStateSetter.reports.set(reports)

  }

  const addField = () => {
    const fieldTemplate = JSON.parse(JSON.stringify(originalTemplateGetter.fieldTemplate))
    reports[reportPage].sheets[sheetPage].selectedFields.push(fieldTemplate)
    configStateSetter.reports.set(reports)
    setNewFieldPopUpVisible(!newFieldPopUpVisible)
    setEditIndex(reports[reportPage].sheets[sheetPage].selectedFields.length - 1)

  }

  const copyField = (index) => {
    const copiedField = JSON.parse(JSON.stringify(reports[reportPage].sheets[sheetPage].selectedFields[index]))
    reports[reportPage].sheets[sheetPage].selectedFields.push(copiedField)
    configStateSetter.reports.set(reports)

  }

  const deleteReport = (type, index) => {
    if (type === 'report') {
      if (reports.length !== 1) {
        reports.splice(index, 1)
        setReportPage(0)
        configStateSetter.reports.set(reports)
      }
    } else {
      if (reports[reportPage].sheets.length !== 1) {
        reports[reportPage].sheets.splice(index, 1)
        setSheetPage(0)
        configStateSetter.reports.set(reports)
      }
    }
  }
  const editField = (index) => {
    setEditIndex(index)
    setNewFieldPopUpVisible(!newFieldPopUpVisible)
  }

  const copyFieldsFromSheet = (fromSheetIndex, toSheetIndex) => {
    const fields = JSON.parse(JSON.stringify(reports[reportPage].sheets[fromSheetIndex].selectedFields))
    reports[reportPage].sheets[toSheetIndex].selectedFields = fields
    configStateSetter.reports.set(reports)
    setShowCopyFields(false)
  }

  const uploadConfig = async () => {
    setLoading(true)
    let config = {
      reports: configStateSetter.reports.get(),
      settings: configStateSetter.settings.get(),
      notes: configStateSetter.notes.get() ? configStateSetter.notes.get() : ""
    }
    const configName = `${customerStateGetter.integrationType}-${customerStateGetter.systemName}-config.json`

    const updateResponse = await backendApiClient.post(`template/${process.env.REACT_APP_CONFIG_STORAGE_ACCOUNT_NAME}/${customerStateGetter.customerName}/${configName}`, config)
    if (updateResponse.status === 200) {
      messageStateRaw.set("Config uploaded")
    } else {
      messageStateRaw.set(updateResponse.error)
    }
    setLoading(false)
  }

  if (loading) {
    return <LoadingPage />
  }
  if (sideNav === 'settings') {
    return (
      <OuterContainer colors={colors}>
        <ErrorPopup />
        <SideNav>
          <HeaderDiv colors={colors}> Reports</HeaderDiv>
          <ReportPageSwitch
            options={getReportPages()}
            reportPage={reportPage}
            setReportPage={setReportPage}
            setSheetPage={setSheetPage}
            size="big"
            flex="column"
            copyReport={copyReport}
            addReport={addReport}
            deleteReport={deleteReport}

          ></ReportPageSwitch>
          <DeltaReportSideNav setSideNav={setSideNav} sideNav={sideNav} colors={colors} navigate={navigate} />
        </SideNav>
        <Container>
          <ReportPageSwitch
            options={getReportSheets()}
            reportPage={sheetPage}
            setReportPage={setSheetPage}
            size="small"
            flex="row"
            copyReport={copySheet}
            addReport={addSheet}
            deleteReport={deleteReport}
          ></ReportPageSwitch>
          <DeltaReportSettings
            settings={sheetSettings}
            reportPage={reportPage}
            sheetPage={sheetPage}
            setSideNav={setSideNav}
            reportName={reports[reportPage].reportName}
            sheetName={reports[reportPage].sheets[sheetPage].sheetName}
            employeeFilter={reports[reportPage].sheets[sheetPage].employeeFilter}
            overallSettings={overallSettings}
            colors={colors}
            language={reports[reportPage].language}
          />
          <ButtonDiv>
            <BasicButton background="primary" label="Back" width="170px" height="35px" margin="0px 20px 20px auto" handleClick={() => navigate('/')} />
            <BasicButton background="primary" label="Save config to blob" width="170px" height="35px" margin="0px auto 20px auto" handleClick={() => uploadConfig()} />
          </ButtonDiv>
        </Container>
      </OuterContainer>
    )


  }
  return (
    <OuterContainer colors={colors}>
      <ErrorPopup />

      <CopyFields sheets={getReportSheets()} copyFieldsFromSheet={copyFieldsFromSheet} showCopyFields={showCopyFields} />
      <SideNav>

        <HeaderDiv colors={colors}> Reports</HeaderDiv>
        <ReportPageSwitch
          options={getReportPages()}
          reportPage={reportPage}
          setReportPage={setReportPage}
          setSheetPage={setSheetPage}
          size="big"
          flex="column"
          copyReport={copyReport}
          addReport={addReport}
          deleteReport={deleteReport}
        ></ReportPageSwitch>
        <DeltaReportSideNav setSideNav={setSideNav} sideNav={sideNav} colors={colors} navigate={navigate} />
      </SideNav>
      <Container colors={colors}>
        <BigHeaderDiv colors={colors}>Delta report</BigHeaderDiv>
        <InnerContainer colors={colors}>

          <ReportPageSwitch
            options={getReportSheets()}
            reportPage={sheetPage}
            setReportPage={setSheetPage}
            size="small"
            flex="row"
            copyReport={copySheet}
            addReport={addSheet}
            deleteReport={deleteReport}
          ></ReportPageSwitch>
          <HeaderButtonDiv>
            <BasicButton background="primary" label="Add field" width="120px" height="40px" margin="0px 20px 0px 10px" handleClick={() => addField()} />
            <SearchInput colors={colors} placeholder='Search fields' onChange={e => setSearchInput(e.target.value)} value={searchInput} />
            <BasicButton background="contrastBlue" label="Copy fields" width="120px" height="40px" margin="0px auto 0px 40px" handleClick={() => setShowCopyFields(!showCopyFields)} />
          </HeaderButtonDiv>
          <DeltaReportPopUp setNewFieldPopUpVisible={setNewFieldPopUpVisible} editIndex={editIndex} fieldObj={reports[reportPage].sheets[sheetPage].selectedFields[editIndex]} selectedFields={reports[reportPage].sheets[sheetPage].selectedFields} reportPage={reportPage} sheetPage={sheetPage} visible={newFieldPopUpVisible} />
          <DeltaReportDragNDropTable
            reports={reports}
            selectedFields={reports[reportPage].sheets[sheetPage].selectedFields}
            schemaPage="deltareport"
            reportPage={reportPage}
            sheetPage={sheetPage}
            configStateSetter={configStateSetter}
            editField={editField}
            searchInput={searchInput}
            copyField={copyField}
          />
        </InnerContainer>
        <ButtonDiv>
          <BasicButton background="primary" label="Back" width="170px" height="35px" margin="0px 20px 20px auto" handleClick={() => navigate('/')} />
          <BasicButton background="primary" label="Save config to blob" width="170px" height="35px" margin="0px auto 20px auto" handleClick={() => uploadConfig()} />
        </ButtonDiv>
      </Container>
    </OuterContainer>
  )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
`
const OuterContainer = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
   
`
const SideNav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 6%;
`

const InnerContainer = styled.div`
  height: 86%;
  width: 98%;
  margin: 0.8% auto auto 1px;
  padding: 0px;
  background: ${props => props.theme.colors.elevation1};
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  overflow: overlay;
  ::-webkit-scrollbar{width:10px;height: 10px;};
  ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`
const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
`

const HeaderButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-self: left;
  align-items: flex-start;
  width: 52%;
`
const HeaderDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};

  font-size: 1.6em;
`
const SheetHeaderDiv = styled.div`
  text-align: right;
  color: ${props => props.theme.colors.primary};
  font-size: 1.1em;
  margin: 5px 30px 0px 0px;
`

const BigHeaderDiv = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.primary};
  margin: 20px 0 0 0;
  font-size: 2em;
`
const SearchInput = styled.input`
  width: 80%;
  color: ${props => props.theme.colors.primary};
    background: ${props => props.theme.colors.elevation3};
    outline: none;
    border-style:solid;
    border:none;
    margin:auto auto;
    border-radius: 30px;
    text-align: left;
    font-size: 1em;
    padding: 6px;

`

import React, { useState } from 'react'
import styled from 'styled-components'
import { Edit } from "@styled-icons/fluentui-system-regular/Edit"
import { Check2 } from "@styled-icons/bootstrap/Check2"
import InputField from '../../SharedComponents/InputField'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import BasicButton from '../../SharedComponents/BasicButton'
import { isValidCron } from 'cron-validator'
import { Trash } from "@styled-icons/bootstrap/Trash"
import cronstrue from 'cronstrue'
import CronEditor from './CronEditor'

export default function ScheduleList({ schedules, setEditActive, setEditIndex, editActive, editIndex, addSchedule, editSchedule, deleteSchedule, originalTemplateGetter }) {

    const configNameOptions = originalTemplateGetter.configName ? originalTemplateGetter.configName : [{ label: "", value: "" }]
    const [showCronEditor, setShowCronEditor] = useState(false)
    const [editConfigName, setEditConfigName] = useState("")

    const activateEdit = (index,configName) => {
        setEditActive(true)
        setEditConfigName(configName)
        setEditIndex(index)
    }
    const deactivateEdit = () => {

        setEditActive(false)
        setEditIndex(0)
    }

    const parseCron = (cron) => {
        let parsedCron = ""
        try {
            parsedCron = cronstrue.toString(cron)
        } catch (error) {
            parsedCron = "Invalid cron"
        }
        return parsedCron
    }

    return (
        <Container>
            {configNameOptions.map(configNameOption => {
                const schedulable = Object.keys(configNameOption).includes("schedulable") && !configNameOption.scheduable ? false : true
                if (!schedulable) {
                    return null
                }
                return (
                    <ScheduleListDiv>
                        <ScheduleConfigName>{configNameOption.label}</ScheduleConfigName>
                        {schedules.map(item => {
                            const emptySchedule = item.schedule.length === 0 ? "No schedules, click add schedule to add one" : ""
                            if (item.configName === configNameOption.value || configNameOption.value === "") {
                                return (
                                    item.schedule.map((schedule, index) => {
                                        const isActive = schedule.isActive ? "Yes" : "No"
                                        const isEditActive = editActive && editIndex === index && (configNameOption.value === editConfigName || editConfigName === "")
                                        const invalidCron = !isValidCron(schedule.cronExpression)

                                        return (

                                            <>
                                                {isEditActive ?
                                                    <ScheduleObjectEdit>
                                                        {showCronEditor && index === editIndex ?

                                                            <CronEditor
                                                                cronExpression={schedule.cronExpression}
                                                                editSchedule={editSchedule}
                                                                index={index}
                                                                configNameOptionValue={configNameOption.value}
                                                                showCronEditor={showCronEditor}
                                                                setShowCronEditor={setShowCronEditor}
                                                            />
                                                            :
                                                            <>
                                                                <ScheduleText>
                                                                    <ScheduleHeader>Cron</ScheduleHeader>
                                                                    <InputField

                                                                        label=''
                                                                        value={schedule.cronExpression}
                                                                        attName={`cronExpression.${configNameOption.value}`}
                                                                        handleChange={editSchedule}
                                                                        width="110px"
                                                                        index={index}
                                                                        margin="auto auto auto auto"
                                                                    />
                                                                </ScheduleText>
                                                                <EditIcon onClick={() => setShowCronEditor(true)} margin="auto auto 17px 0px"/>

                                                                <ScheduleText>
                                                                    <ScheduleHeader>Is active</ScheduleHeader>
                                                                    <SelectField
                                                                        width="130px"
                                                                        label=""
                                                                        options={[{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }]}
                                                                        fieldValue={{ label: isActive, value: isActive }}
                                                                        handleSelect={editSchedule}
                                                                        attName={`isActive.${configNameOption.value}`}
                                                                        index={index}
                                                                        margin="6px 10px 0 10px" />
                                                                </ScheduleText>
                                                                <ScheduleText>
                                                                    <ScheduleHeader>Timezone</ScheduleHeader>
                                                                    <InputField

                                                                        label=''
                                                                        value={schedule.timeZoneId}
                                                                        attName={`timeZoneId.${configNameOption.value}`}
                                                                        handleChange={editSchedule}
                                                                        width="140px"
                                                                        index={index}
                                                                    />
                                                                </ScheduleText>
                                                                <CheckIcon onClick={() => deactivateEdit()} />
                                                            </>
                                                        }
                                                    </ScheduleObjectEdit>
                                                    :
                                                    <ScheduleObject>
                                                        <ScheduleText isValidCron={invalidCron}>
                                                            <ScheduleHeader>Schedule</ScheduleHeader>
                                                            {parseCron(schedule.cronExpression)}
                                                        </ScheduleText>
                                                        <ScheduleText>
                                                            <ScheduleHeader>Is active</ScheduleHeader>
                                                            {isActive}
                                                        </ScheduleText>
                                                        <ScheduleText>
                                                            <ScheduleHeader>Timezone</ScheduleHeader>
                                                            {schedule.timeZoneId}
                                                        </ScheduleText>
                                                        <EditIcon onClick={() => activateEdit(index, configNameOption.value)} />
                                                        <DeleteIcon onClick={() => deleteSchedule(index, configNameOption.value)} />
                                                    </ScheduleObject>
                                                }

                                            </>
                                        )
                                    })
                                )
                            }

                        })}
                        <BasicButton fontSize="1.1em" background="primary" label={`Add schedule`} width="130px" height="30px" margin="20px auto 20px auto" handleClick={() => addSchedule(configNameOption.value)} />
                    </ScheduleListDiv>
                )
            })
            }
        </Container>
    )
}
const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: auto auto auto auto;
`

const ScheduleObject = styled.div`
    display: flex;
    font-size: 1.4em;
    flex-direction: row;
    border-radius: 30px;
    margin: 10px auto;
    max-width: 90%;
    padding: 10px;
    background: ${props => props.theme.colors.elevation1};

`
const ScheduleObjectEdit = styled.div`
    display: flex;
    font-size: 1.2em;
    flex-direction: row;
    border-radius: 30px;
    margin: 10px auto;

    padding: 8px 4px;
    background: ${props => props.theme.colors.elevation1};

`
const ScheduleText = styled.div`
    margin: 0 10px;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    color: ${props => props.isValidCron ? props.theme.colors.contrastRed : props.theme.colors.primary};

`
const ScheduleHeader = styled.div`
    font-size: 0.8em;
    min-width: 70px;
    color: ${props => props.theme.colors.contrastBlue};

`
const EditIcon = styled(Edit)`
color: ${props => props.theme.colors.contrastBlue};
margin:${props => props.margin ? props.margin : "auto 15px"} ;
width: 1.3em;
&:hover {
    cursor:pointer;
}
`

const CheckIcon = styled(Check2)`
color: ${props => props.theme.colors.contrastGreen};
margin: auto 15px;
width: 1.3em;
&:hover {
    cursor:pointer;
}
`
const EmptyScheduleText = styled.div`
    margin: auto auto 10px auto;
    font-size: 1.4em;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.primary};
`

const DeleteIcon = styled(Trash)`
   width: 1.1em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
const ScheduleListDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto auto auto 10px;
    width: 100%;
`
const ScheduleConfigName = styled.div`
    font-size: 1.2em;
    color: ${props => props.theme.colors.primary};

`
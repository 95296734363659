import {useState, useEffect} from 'react'
import useIntegrationStates from './useIntegrationStates'
import BackendApiClient from '../services/backendApiClient'

export default function useSecrets() {
    const backendApiClient = new BackendApiClient()
    const {
        originalTemplateGetter, 
        c1StateGetter,
        systemName,
        integrationType,
        customerName,
        customerIntegrationName,
        messageStateSetter
    
    } = useIntegrationStates()

    const secretsFromTemplate = "secrets" in originalTemplateGetter ? JSON.parse(JSON.stringify(originalTemplateGetter.secrets)) : []
    const [secrets, setSecrets] = useState(secretsFromTemplate)
    const secretPrefix = systemName ? `${integrationType}-${systemName}` : integrationType
    const [loading, setLoading] = useState(false)


    const getSecrets = async () => {
        setLoading(true)
        const secretsRequest = await backendApiClient.get(`secrets/${customerName}-${process.env.REACT_APP_KEYVAULT_PREFIX}/${secretPrefix}`)

        if (secretsRequest.status !== 200) {
            messageStateSetter.set('Failed to get secrets')
        } else {
            const secretFromRequest = secretsRequest.data

            const tenantIndex = secretFromRequest.findIndex(x => x.name === 'c1Tenant')
            const clientIdIntex = secretFromRequest.findIndex(x => x.name === 'c1ClientId')
            const clientSecretIndex = secretFromRequest.findIndex(x => x.name === 'c1ClientSecret')
            if (!secretFromRequest[tenantIndex].value) {
                secretFromRequest[tenantIndex].value = c1StateGetter.clientUrl
                secretFromRequest[tenantIndex].updated = true
            }
            if (!secretFromRequest[clientIdIntex].value) {
                secretFromRequest[clientIdIntex].value = c1StateGetter.clientId
                secretFromRequest[clientIdIntex].updated = true
            }
            if (!secretFromRequest[clientSecretIndex].value) {
                secretFromRequest[clientSecretIndex].value = c1StateGetter.clientSecret
                secretFromRequest[clientSecretIndex].updated = true
            }
            setSecrets(secretsRequest.data)
        }
        setLoading(false)
    }

    useEffect(() => {
        getSecrets()
    }, [])

  return {
    secrets,
    setSecrets,
    loading,
    setLoading,
    customerIntegrationName,
    customerName,
    secretPrefix,
    messageStateSetter
  }
}

import React from 'react'
import styled from 'styled-components'
import { useNavigate } from "react-router-dom";
import { customerState } from '../../States/customerState';
import { useHookstate } from '@hookstate/core';
import BasicButton from '../SharedComponents/BasicButton';

export default function Options() {
    const integrationType = useHookstate(customerState).get().integrationType
    const mappingPath = integrationType === 'deltareport' ? '/deltareport' : '/mapping'
    const navigate = useNavigate()
    const integrationList = [
        {name: 'New config', path: mappingPath},
        {name: 'Import from schema', path: '/importschema'},
        {name: 'Import from blob', path: '/getconfig'}
    ]

  return (
    <Container >
        <Header >Start a new or pick an excisting config</Header>
    <IntegrationsListContainer>
        {
            integrationList.map( (item) => {
                return (
                    <ListOptionBox onClick={() => navigate(item.path)} >
                        <ListOptionText >
                            {item.name}
                        </ListOptionText>
                    </ListOptionBox>
                )
            })
        }
    </IntegrationsListContainer>
    <BasicButton background="primary" label="Back" width="90px" height="30px" margin="10px auto 15px auto" handleClick={() => navigate('/c1data')} />
    </Container>
  )
}

const IntegrationsListContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 160px;
    justify-content: center;
    margin: 20px auto auto auto;
`

const ListOptionBox = styled.div`
    background: ${props => props.theme.colors.elevation2};
    width: 190px;
    height: 100%;
    margin: 0 10px 0 10px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
        cursor:pointer;
        background: ${props => props.theme.colors.elevation3};
        font-size: 1.1em;
    }
`
const ListOptionText = styled.div`

    color: ${props => props.theme.colors.contrastBlue};
    text-align: center;
    font-size: 1.5em;
    padding: 20px;
    margin: auto;
`
const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

`
const Header = styled.div`
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin: auto auto 10px auto;
  font-size: 2.5em;
`
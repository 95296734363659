import { hookstate } from '@hookstate/core';

export const c1State = hookstate({
    c1Fields: [],
    absenceFields: [],
    lists: {
        },
    clientId: "",
    clientSecret: "",
    clientUrl: "",
    refUser: "",
    connectionString: ""
    })
    
import React from 'react'
import { styled } from 'styled-components'
import { useHookstate } from '@hookstate/core'
import SelectFieldMulti from '../SharedComponents/SelectFieldMulti'
import { c1State } from '../../States/c1State'
import SelectField from '../SharedComponents/SelectlFieldToSplit'
import { colorScheme } from '../../globalConfig/globalConfig'
import {Trash} from "@styled-icons/bootstrap/Trash"
import InputField from '../SharedComponents/InputField'
import { operatorOptions } from '../../globalConfig/fieldOptions'

export default function DeltaReportConditionLogic({conditionField, fieldIndex, conditionIndex, conditionFieldIndex,configStateSetter, conditionFields, reportPage, sheetPage}) {
    
    const c1StateRaw = useHookstate(c1State)
    const c1StateGet = c1StateRaw.get()
    const colors = colorScheme.night
    const updateConditionField = (choice, index, attName) => {
        if (choice) {
            conditionField[attName] = choice
        } else {
            conditionField[attName] = ""
        }
        if (conditionField.field.value in c1StateGet.lists && !(attName === 'operator' && (choice.value === 'con' || choice.value === '!con')) ) {
            conditionField.hasValueFromList = true
        } else {
            conditionField.hasValueFromList = false
        }
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].conditionFields[conditionFieldIndex].set(conditionField)
    }

    const deleteCondition = () => {
        const newConditionFields = conditionFields
        newConditionFields.splice(conditionFieldIndex,1)
        configStateSetter.reports[reportPage].sheets[sheetPage].selectedFields[fieldIndex].customLogic[conditionIndex].conditionFields.set(newConditionFields)
    }


    const operators = conditionField.field.type === 'DATE' ? operatorOptions.DATE : operatorOptions.DEFAULT
    
    const emptyOperator = conditionField.operator.value === "!0" || conditionField.operator.value === "0" ? true : false
    const hasList =  (conditionField.field.value in c1StateGet.lists && conditionField.operator.value !== 'con' &&  conditionField.operator.value !== '!con' && !emptyOperator) ? true : false


  return (
    <ConditionLogicContainer colors={colors}>
        <LogicDiv>
        <SelectField width="220px" color={colors} label="Field" options={c1StateGet.c1Fields} attName="field" fieldValue={conditionField.field} handleSelect={updateConditionField} margin="0 10px 0 10px" />
        <SelectField width="180px" color={colors} label="Operator" options={operators} attName="operator" fieldValue={conditionField.operator} handleSelect={updateConditionField} margin="0 10px 0 10px" />
        {hasList ? 
           <SelectFieldMulti width="290px" color={colors} label="Field" options={c1StateGet.lists[conditionField.field.value]} attName="listValues" fieldValue={conditionField.listValues} mulit={true} handleSelect={updateConditionField} margin="0 10px 0 10px" /> 
           :  !emptyOperator ? 
            <InputField colors={colors} value={conditionField.value} handleChange={updateConditionField} attName="value" label="Value" />
            : ""
    }
        </LogicDiv>
        <DeleteIcon colors={colors} onClick={deleteCondition}/>
    </ConditionLogicContainer>
  )
}

const ConditionLogicContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 8px 7px 8px;
    background: ${props => props.theme.colors.elevation3};
    margin: 7px 0 7px 0px;
    border-radius: 10px;
`
const LogicDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 7px 0 0px 0px;
    align-items: center;
`
const DeleteIcon = styled(Trash) `
   width: 1.2em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
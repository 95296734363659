import React from 'react'
import { styled } from 'styled-components'
import { useHookstate } from '@hookstate/core'
import SelectFieldMulti from '../../SharedComponents/SelectFieldMulti'
import { c1State } from '../../../States/c1State'
import SelectField from '../../SharedComponents/SelectlFieldToSplit'
import { Trash } from "@styled-icons/bootstrap/Trash"
import InputField from '../../SharedComponents/InputField'
import { operatorOptions, dateRollingOptions } from '../../../globalConfig/fieldOptions'
import CheckboxField from '../../SharedComponents/Checkbox'

export default function ConditionLogic({ conditionField, conditionIndex, conditionFieldIndex,setFieldToEdit, fieldObj }) {

    const c1StateGetter = useHookstate(c1State).get()

    const updateConditionField = (choice, index, attName) => {
        if (choice !== null) {
            conditionField[attName] = choice
        } else {
            conditionField[attName] = { label: 'Choose field', value: 'value' }
        }
        if (conditionField.field.value in c1StateGetter.lists && !(attName === 'operator' && (choice.value === 'con' || choice.value === '!con'))) {
            conditionField.hasValueFromList = true
        } else {
            conditionField.hasValueFromList = false
        }

        fieldObj.customLogic[conditionIndex].conditionFields.splice(conditionFieldIndex, 1, conditionField)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))

    }

    const deleteCondition = () => {
        fieldObj.customLogic[conditionIndex].conditionFields.splice(conditionFieldIndex, 1)
        setFieldToEdit(JSON.parse(JSON.stringify(fieldObj)))
    }

    const operators = conditionField.field.type === 'DATE' ? operatorOptions.DATE : operatorOptions.DEFAULT
    const isDateField = conditionField.field.type === 'DATE'

    const emptyOperator = conditionField.operator.value === "!0" || conditionField.operator.value === "0" || conditionField.operator.value.includes("change") ? true : false
    const hasList = (conditionField.field.value in c1StateGetter.lists && conditionField.operator.value !== 'con' && conditionField.operator.value !== '!con' && !emptyOperator) ? true : false


    return (
        <ConditionLogicContainer >
            <LogicDiv>
                <SelectField width="17em" label="Field" options={c1StateGetter.c1Fields} attName="field" fieldValue={conditionField.field} handleSelect={updateConditionField} margin="0 10px 0 10px" />
                <SelectField width="14em" label="Operator" options={operators} attName="operator" fieldValue={conditionField.operator} handleSelect={updateConditionField} margin="0 10px 0 10px" />
                {hasList ?
                    <SelectFieldMulti width="23em" label="Field" options={c1StateGetter.lists[conditionField.field.value]} attName="listValues" fieldValue={conditionField.listValues} mulit={true} handleSelect={updateConditionField} margin="0 10px 0 10px" />
                    : !emptyOperator && !conditionField.dateRolling ?
                        <InputField value={conditionField.value} handleChange={updateConditionField} attName="value" label={isDateField ? "Date (yyyy-MM-dd)" : "Value"} />
                        : ""
                }
                {isDateField && conditionField.dateRolling ?
                    <>
                        <InputField width="10em" value={conditionField.value} handleChange={updateConditionField} attName="value" label={isDateField ? "Offset from today" : "Value"} />
                        <SelectField width="14em" label="Offset option (Days, weeks...)" options={dateRollingOptions} attName="dateRollingValueType" fieldValue={conditionField.dateRollingValueType} handleSelect={updateConditionField} margin="0 10px 0 10px" />
                    </> : ""}
                {isDateField ?
                    <CheckboxField
                        label="Rolling timeframe"
                        checked={conditionField.dateRolling}
                        attName="dateRolling"
                        setOption={updateConditionField}
                    />
                    : ""}
            </LogicDiv>
            <DeleteIcon onClick={deleteCondition} />
        </ConditionLogicContainer>
    )
}

const ConditionLogicContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 7px 8px 7px 8px;
    background: ${props => props.theme.colors.elevation3};
    margin: 7px 0 7px 0px;
    border-radius: 10px;
`
const LogicDiv = styled.div`
    display: flex;
    flex-direction: row;
    margin: 7px 0 0px 0px;
    align-items: center;
`
const DeleteIcon = styled(Trash)`
   width: 1.2em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`
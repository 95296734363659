import React from 'react'
import styled from 'styled-components'
import AuditFieldOutput from './AuditFieldOutput'
import useIntegrationStates from '../../../hooks/useIntegrationStates'

export default function AuditField({ field, index, setChangeType, setFieldIndex, setShowAuditField, fieldChangeType, showField, auditListIndex, setAuditIndex }) {
    const fieldName = field.new ? field.new.sourceField.label : field.sourceField.label
    const newField = field.new ? field.new : field
    const oldField = field.old ? field.old : null
    const { originalTemplateGetter } = useIntegrationStates()
    const fieldTemplate = originalTemplateGetter.fieldTemplate

    if (!showField) {
        return (
            <Field onClick={() => {
                setChangeType(fieldChangeType)
                setFieldIndex(index)
                setShowAuditField(true)
                setAuditIndex(auditListIndex)
            }}>
                {fieldName}
            </Field>
        )
    }

    return (
        <FieldsDiv>
            {oldField ?
                <Container>
                    <Header>Old field</Header>
                    <FieldInfo>
                        <FieldHeader>
                            <SmallHeader>Target field</SmallHeader>
                            {oldField.sourceField.label}
                        </FieldHeader>
                        <FieldHeader>
                            <SmallHeader>Field type</SmallHeader>
                            {oldField.fieldType}
                        </FieldHeader>
                        {"validFrom" in fieldTemplate ?
                            <FieldHeader>
                                <SmallHeader>Valid from</SmallHeader>
                                {oldField.validFrom.toString()}
                            </FieldHeader>
                            : null}
                        {"plannedChange" in fieldTemplate ?
                            <FieldHeader>
                                <SmallHeader>Planned change</SmallHeader>
                                {oldField.plannedChange.toString()}
                            </FieldHeader>
                            : null}
                    </FieldInfo>
                    <AuditFieldOutput field={oldField} />
                </Container> : null
            }
            <Container>
                <Header>New field</Header>
                <FieldInfo>
                    <FieldHeader>
                        <SmallHeader>Target field</SmallHeader>
                        {newField.sourceField.label}
                    </FieldHeader>
                    <FieldHeader>
                        <SmallHeader>Field type</SmallHeader>
                        {newField.fieldType}
                    </FieldHeader>
                    {"validFrom" in fieldTemplate ?
                        <FieldHeader>
                            <SmallHeader>Valid from</SmallHeader>
                            {newField.validFrom.toString()}
                        </FieldHeader>
                        : null}
                    {"plannedChange" in fieldTemplate ?
                        <FieldHeader>
                            <SmallHeader>Planned change</SmallHeader>
                            {newField.plannedChange.toString()}
                        </FieldHeader>
                        : null}
                </FieldInfo>
                <AuditFieldOutput field={newField} />
            </Container>

        </FieldsDiv>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 10px;
    background: ${props => props.theme.colors.grey};
    border-radius: 30px;
    margin: 10px;
    z-index: 1000001;
    `
const Field = styled.div`
    padding: 10px;
    background: ${props => props.theme.colors.elevation1};
    color: ${props => props.theme.colors.primary};
    width: fit-content;
    border-radius: 30px;
    margin: 7px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.elevation2};
    }
`
const FieldHeader = styled.div`
    font-size: 1.2em;
    margin: 5px 7px;
    display: inline;
    color: ${props => props.theme.colors.primary};
    
`
const SmallHeader = styled.div`
    font-size: 0.9em;
    color: ${props => props.theme.colors.contrastBlue};
    
`
const Header = styled.div`
    color: ${props => props.theme.colors.primary};
    text-align: center;
    margin: 0px auto 5px auto;
    font-size: 2em;
    display: inline;
    `
const FieldsDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: ${props => props.theme.colors.elevation1};
    border-radius: 30px;
    width: fit-content;
    margin: 10px auto;

`
const FieldInfo = styled.div`
    display: flex;
    flex-direction: row;
`
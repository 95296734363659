import styled from 'styled-components'
import React, { useState} from 'react'
import LoadingPage from '../SharedComponents/LoadingPage'
import SearchBar from './SearchBar';
import { setEditIntegration } from '../../services/functions';
import { useNavigate } from 'react-router-dom';
import BasicButton from '../SharedComponents/BasicButton';
import IntegrationOverviewTable from './IntegrationOverviewTable';
import cronstrue from 'cronstrue';
import moment from 'moment'
import useIntegrationOverview from '../../hooks/useIntegrationOverview';
import { firstLetterUppercase } from '../../services/functions';
import useIntegrationStates from '../../hooks/useIntegrationStates';
import useLoggedInUser from '../../hooks/useLoggedInUser';

export default function IntegrationsOverview() {
    
    const [lastSorted, setLastSorted] = useState({ column: "", direction: "" })
    const [statusFilter, setStatusFilter] = useState([{ label: "All", value: "" }])
    const [integrationTypeFilter, setIntegrationTypeFilter] = useState([{ label: "All", value: "" }])
    const [searchInput, setSearchInput] = useState("")
    const { integrations, filteredIntegrations, integrationTypeOptions, setFilteredIntegrations,loading,setLoading } = useIntegrationOverview()
    const {originalTemplateSetter, customerStateSetter, c1StateSetter, messageStateSetter} = useIntegrationStates()
    const navigate = useNavigate()
    const {user} = useLoggedInUser()

    const prepareData = (data) => {
        const rowData = []
        data.map(integration => {
            const row = {
                customerName: firstLetterUppercase(integration.CustomerName),
                integrationType: integration.IntegrationName ? firstLetterUppercase(integration.IntegrationType) + " - " + firstLetterUppercase(integration.IntegrationName) : firstLetterUppercase(integration.IntegrationType),
                status: integration.Status,
                lastRun: getLastRunFromArray(integration.RunStats, "LastRun", integration.IntegrationName),
                lastSuccessfullRun: getLastRunFromArray(integration.RunStats, "LastSuccessfulRun", integration.IntegrationName),
                schedule: getSchedules(integration.Schedules, integration.IntegrationName)
            }
            rowData.push(row)
        })
        
        return rowData
    }

    const getLastRunFromArray = (runStats, key, configName) => {
        let lastRun = ""
        const lastRunArray = []
        runStats.map((element, index) => {
            const lastRunObject = { name: "", value: "" }
            if (element.ConfigName !== configName && element.ConfigName !== null) {
                lastRunObject.name = firstLetterUppercase(element.ConfigName) + ": "
                lastRun += firstLetterUppercase(element.ConfigName) + ": "
            }
            lastRunObject.value = moment(element[key]).format("YYYY-MM-DD HH:mm") === "Invalid date" ?  "" : moment(element[key]).format("YYYY-MM-DD HH:mm")
            lastRun += element[key].substring(0, 16)
            if (index !== runStats.length - 1) {
                lastRun += ", "
            }
            lastRunArray.push(lastRunObject)
        })
        return lastRunArray
    }

    const parseCron = (cron) => {
        let parsedCron = ""
        try {
            parsedCron = cronstrue.toString(cron)
        } catch (error) {
            parsedCron = "Invalid cron"
        }
        return parsedCron
    }

    const getSchedules = (schedules, configName) => {
        let scheduleString = ""
        const scheduleArray = []
        schedules.map((schedule, index) => {
            const scheduleObject = { name: "", value: "" }
            if (schedule.ConfigName !== configName && schedule.ConfigName !== null) {
                scheduleString += firstLetterUppercase(schedule.ConfigName) + ": "
                scheduleObject.name = firstLetterUppercase(schedule.ConfigName) + ": "
            }
            scheduleString += parseCron(schedule.Cron)
            scheduleObject.value = parseCron(schedule.Cron)
            if (index !== schedules.length - 1) {
                scheduleString += ", "
            }
            scheduleArray.push(scheduleObject)
        })
        return scheduleArray
    }

    const sortTable = (column, direction) => {
        setLastSorted({ column: column, direction: direction })
        console.log(integrations)
        const sortedIntegrations = integrations.sort((a, b) => {
            if (direction === "up") {
                return a[column] < b[column] ? 1 : -1
            } else {
                return a[column] > b[column] ? 1 : -1
            }
        })
        setFilteredIntegrations(sortedIntegrations)

    }

    const filterIntegrations = (filterValues, column) => {
        let filteredIntegrations = [...integrations]
        if (column === "status") {
            const statusValuesArray = filterValues.map(filter => filter.label);
            filteredIntegrations = filteredIntegrations.filter(integration => statusValuesArray.includes(integration.Status) || statusValuesArray.includes("All") || statusValuesArray.length === 0)
            const integrationTypeValuesArray = integrationTypeFilter.map(filter => filter.value);
            filteredIntegrations = filteredIntegrations.filter(integration => integrationTypeValuesArray.includes(integration.IntegrationType) || integrationTypeValuesArray.includes("") || integrationTypeValuesArray.length === 0)
        } else if (column === "integrationType") {
            const integrationTypeValuesArray = filterValues.map(filter => filter.value);
            filteredIntegrations = filteredIntegrations.filter(integration => integrationTypeValuesArray.includes(integration.IntegrationType) || integrationTypeValuesArray.includes("") || integrationTypeValuesArray.length === 0)
            const statusValuesArray = statusFilter.map(filter => filter.label);
            filteredIntegrations = filteredIntegrations.filter(integration => statusValuesArray.includes(integration.Status) || statusValuesArray.includes("All") || statusValuesArray.length === 0)
        } else if (column === "search") {
            filteredIntegrations = filteredIntegrations.filter(integration => integration.CustomerName.toLowerCase().includes(filterValues.toLowerCase()) || integration.IntegrationType.toLowerCase().includes(filterValues.toLowerCase()) || !filterValues)
            const integrationTypeValuesArray = integrationTypeFilter.map(filter => filter.value);
            filteredIntegrations = filteredIntegrations.filter(integration => integrationTypeValuesArray.includes(integration.IntegrationType) || integrationTypeValuesArray.includes("") || integrationTypeValuesArray.length === 0)
            const statusValuesArray = statusFilter.map(filter => filter.label);
            filteredIntegrations = filteredIntegrations.filter(integration => statusValuesArray.includes(integration.Status) || statusValuesArray.includes("All") || statusValuesArray.length === 0)
        }
        setFilteredIntegrations(filteredIntegrations)
    }

    const editIntegration = async (index, pageIndex, pageSize) => {
        const integrationIndex = (pageIndex * pageSize) + index

        
        setLoading(true)
        const selectedIntegration = filteredIntegrations[integrationIndex]
        const result = await setEditIntegration(selectedIntegration.CustomerName, selectedIntegration.IntegrationType, selectedIntegration.IntegrationName, selectedIntegration.BlobName, c1StateSetter, messageStateSetter, user)
        customerStateSetter.customerName.set(selectedIntegration.CustomerName)
        customerStateSetter.integrationType.set(selectedIntegration.IntegrationType)
        customerStateSetter.systemName.set(selectedIntegration.IntegrationName)
        customerStateSetter.config.set(result.config)
        originalTemplateSetter.set(result.originalTemplate)
        setLoading(false)
        if (result.missingKeyVault) {
            return navigate('/keyvault')
        }
        if (selectedIntegration.IntegrationType === "deltareport") {
            navigate('/deltareport')
        } else {
            navigate('/mapping')
        }
    
        setLoading(false)
    }

    const columns = [
        {  accessor: 'customerName', Header:  "Customer name",filterAttribute : "CustomerName",},
        {  accessor: 'integrationType', Header: 'Integration', filterAttribute : "IntegrationType"},
        {  accessor: 'status', Header: 'Status', filterAttribute : "Status"},
        {  accessor: 'lastRun', Header: 'Last run' },
        {  accessor: 'lastSuccessfullRun', Header: 'Last successful run' },
        {  accessor: 'schedule', Header: 'Schedule' },
        {  accessor: 'edit', Header: '' }
    ]


    if (loading) {
        return (
            <LoadingPage />
        )
    }
    return (
        <Container>
            <HeaderText>Integrations overview</HeaderText>
            <SearchBar
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                filterIntegrations={filterIntegrations}
                integrationTypeFilter={integrationTypeFilter}
                setIntegrationTypeFilter={setIntegrationTypeFilter}
                integrationTypeOptions={integrationTypeOptions}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
            />
            <IntegrationOverviewTable columns={columns} data={prepareData(filteredIntegrations)} sortTable={sortTable} lastSorted={lastSorted} editIntegration={editIntegration}/>
            <BasicButton fontSize="1em" background="primary" label={`Back`} width="130px" height="30px" margin="auto auto 20px auto" handleClick={() => navigate('/optionsmenu')} />
        </Container>
    )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ::-webkit-scrollbar{width:8px;height: 4px;};
    ::-webkit-scrollbar-thumb{background-color: ${props => props.theme.colors.primary};border-radius: 30px};
`

const HeaderText = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 2.2em;
    margin: 20px auto 20px auto;
`

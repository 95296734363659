import React from 'react'
import { styled } from 'styled-components'
import { FormatSize } from '@styled-icons/material'


export default function SchemaPageSwitch({options, setSchemaPage, schemaPage, size,settings,fromDirections,selectedFields, integrationDirections=[], integrationDirection}) {

    let showOrgs = false
    try {
      showOrgs = settings.sendOrgFile.value
    } catch {
  
    }
  
    let showExtraFile1 = false
    try {
      showExtraFile1 = settings.sendExtraFile1.value
    } catch {
  
    }
  
    let showExtraFile2 = false
    try {
      showExtraFile2 = settings.sendExtraFile2.value
    } catch {
  
    }
  
    let showExtraFile3 = false
    try {
      showExtraFile3 = settings.sendExtraFile3.value
    } catch {
  
    }
  
    let showImport = false
    try {
      showImport = settings.importToC1.value
    } catch {
  
    }
  
    let showImport1 = false
    try {
      showImport1 = settings.importFile1.value
    } catch {
  
    }
  
    let showImport2 = false
    try {
      showImport2 = settings.importFile2.value
    } catch {
  
    }
    
  return (
    <Container>
        {
            options.map(option => {
                if (option.includes('organization') && !showOrgs) {
                    return null
                }
                if (option === 'import' && !showImport && !fromDirections) {
                    return null
                }

                if (option === 'import1' && !showImport1) {
                    return null
                }

                if (option === 'import2' && !showImport2) {
                    return null
                }

                if (option.includes('extraFile1') && !showExtraFile1) {
                    return null
                }

                if (option.includes('extraFile2') && !showExtraFile2) {
                    return null
                }

                if (option.includes('extraFile3') && !showExtraFile3) {
                    return null
                }

                if (fromDirections && integrationDirections.length > 1 && selectedFields[option].direction !== integrationDirection) {
                    return null
                }

                const active = option === schemaPage
                const name = option.substring(0,1).toUpperCase() + option.substring(1)
                return (
                    <OptionButton size={size} onClick={() => setSchemaPage(option)} active={active} >{name.replace('_', ' ')}</OptionButton>
                )
            })
        }
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: auto;
    z-index: 1;
`

const OptionButton = styled.div`
    padding: ${props => props.size === 'small' ? "4px 8px": "7px 12px"};
    font-size: ${props => props.size === 'small' ? "0.9em": "1em"};
    border-radius: 30px;
    margin: 0 10px 0 10px;
    background: ${props => props.active ? props.theme.colors.blue3 : props.theme.colors.elevation3};
    text-align: center;
    color: ${props => props.active? props.theme.colors.primaryButtonText : props.theme.colors.primary};
    &:hover {
        cursor: pointer;
    }
`
import React from 'react'
import styled from 'styled-components';
import { useHookstate } from '@hookstate/core';
import { messageState } from '../../States/messageState';
import BasicButton from './BasicButton';


export default function ErrorPopup(props) {
    const messageStateRaw = useHookstate(messageState)

    const handleClick = () => {
        messageStateRaw.set('')
    }

    if (!messageStateRaw.get()) {
        return (
            <div></div>
        )
    }

  return (
    <Container >
        <ErrorMessage >{messageStateRaw.get()}</ErrorMessage>
        <ButtonDiv>
        <BasicButton background="primary" label="Close" width="90px" height="30px" margin="20px auto 15px auto" handleClick={handleClick} />
        </ButtonDiv>
    </Container>
  )
}

const Container = styled.div`
    position: absolute; 
    top: 36%;
    left: 38%;
    width: 24%;
    background-color: ${props => props.theme.colors.grey};
    border-radius: 10px;
    backdrop-filter: blur(15px);
    border:${props => `1px solid ${props.theme.colors.elevation1}`} ;
    box-shadow: 0 0 40px rgba(8,7,16,0.6);
    z-index: 10000;
    vertical-align: center;
`
const ErrorMessage = styled.div`
    color: ${props => props.theme.colors.primary};
    margin-top: 5%;
    font-size: 1.3em;
    padding: 0 20px 0 20px;
    
`
const ButtonDiv = styled.div`
    
`
import { hookstate } from '@hookstate/core';
import { localstored } from '@hookstate/localstored';

export const selectedTemplate = hookstate(
    {
        templateState: "",
        originalTemplateState: ""
    },
    localstored({
        key: 'state-key'
    })
)

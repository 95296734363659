import { hookstate } from '@hookstate/core';

export const importSchemaState = hookstate({
    data: [],
    fieldsFromExcel: [],
    fieldOptions: [
        {'label': 'CatalystOne field id', value: 'c1Field'},
        {'label': 'Field in integrated system', value: 'sourceField'},
        {'label': 'Always include', value: 'alwaysInclude'},
        {'label': 'Valid from', value: 'validFrom'},
        {'label': 'Planned change', value: 'plannedChange'},
        {'label': 'Value type', value: 'valueType'},
        {'label': 'Payload type', value: 'eventType'},
        {'label': 'Field type', value: 'fieldType'},
        {'label': 'Default value', value: 'hardCodedValue'},
    ]   
    })
import React from 'react'
import styled from 'styled-components'
import { Edit } from "@styled-icons/fluentui-system-regular/Edit"

export default function SettingsList({ settings, chooseSetting }) {

    return (
        <Container  >
            {settings.map((setting, index) => {
                console.log(setting)
                return (
                    <Setting >
                        <SettingRow >
                            Name
                            <SettingLabel >{setting.name}</SettingLabel>
                        </SettingRow>
                        <SettingRow >
                            Label
                            <SettingLabel >{setting.label}</SettingLabel>
                        </SettingRow>
                        <SettingRow >
                            Type
                            <SettingLabel >{setting.type}</SettingLabel>
                        </SettingRow>
                        <SettingRow >
                            Section
                            <SettingLabel >{setting.section}</SettingLabel>
                        </SettingRow>

                        <EditIcon onClick={() => chooseSetting(setting.name)} />
                    </Setting>
                )
            })}
        </Container>
    )

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    height: 100%;
    overflow: auto;
    padding: 10px;

    border-radius: 10px;
    margin: 10px;

    `
const Setting = styled.div`
    padding: 10px;
    background: ${props => props.theme.colors.elevation2};
    color: ${props => props.theme.colors.primary};
    border-radius: 30px;
    margin: 7px;
    display: flex;
    flex-direction: row;
    &:hover {
        cursor: pointer;
    }
`
const SettingLabel = styled.div`
    flex: 2;
    font-size: 1.3em;

    color: ${props => props.theme.colors.primary};
`
const SettingRow = styled.div`
    flex: 2;
    display:flex;
    flex-direction: column;
    font-size: 0.8em;
    color: ${props => props.theme.colors.contrastBlue};
`

const EditIcon = styled(Edit)`
font-size: 1.1em;
margin: auto 7px auto 0px;
flex: 0.07;
color: ${props => props.theme.colors.contrastBlue};

`
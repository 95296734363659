import React from 'react'
import styled  from 'styled-components'

export default function TableFieldHover({fieldName, active}) {

if (!active) return null
  
return (
    <TableFieldContainer >
        {fieldName}
    </TableFieldContainer>
  )
}

const TableFieldContainer = styled.div`
    position: absolute;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.primary};
    z-index: 100000;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.13);
    padding: 5px;
    left: 0;
    font-size: 0.8em;

`

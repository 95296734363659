import React from 'react'
import styled  from 'styled-components'
import {Edit} from "@styled-icons/fluentui-system-regular/Edit"
import {Trash} from "@styled-icons/bootstrap/Trash"
import {Copy} from "@styled-icons/fluentui-system-regular/Copy"

export default function TableFieldHogiaEntities({item,editField, index, deleteField, copyField}) {

  return (
    <TableFieldContainer >
      <RowField >
      Filter field
      <FieldName >{item.filterField.label}</FieldName>
      </RowField>
      <RowField >
      Filter value
      <FieldName >{item.filterValue}</FieldName>
      </RowField> 
      <RowField >
      File path
      <FieldName >{item.filePath}</FieldName>
      </RowField>
      <RowField >
      File name
      <FieldName >{item.fileName}</FieldName>
      </RowField>
      
      <EditIcon onClick={() => editField(index)} />
      <CopyIcon onClick={() => copyField(index)} />
      <DeleteIcon onClick={() => deleteField(index)} />
    </TableFieldContainer>
  )
}

const TableFieldContainer = styled.div`
width: 100%;
height: 70px;
background: ${props => props.theme.colors.elevation2};
margin: 5px auto 5px auto;
border-radius: 30px;
display:flex;
flex-direction: row;
align-items: center;
justify-content: space-around;
z-index: 2;
`

const FieldName = styled.div`
    color: ${props => props.theme.colors.primary};
    font-size: 1.7em;
`
const EditIcon = styled(Edit) `
   width: 2em ;
    color: ${props => props.theme.colors.contrastBlue};
    &:hover {
      cursor:pointer;
    }
`


const DeleteIcon = styled(Trash) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastRed};
    &:hover {
      cursor:pointer;
    }
`

const CopyIcon = styled(Copy) `
   width: 1.5em ;
    color: ${props => props.theme.colors.contrastGreen};
    &:hover {
      cursor:pointer;
    }
`

const RowField = styled.div`
  display: flex; 
  flex-direction: column;
  color: ${props => props.theme.colors.primary};
  font-size: 0.8em;
  width: 20%;
`
const SettingBox = styled.div`
  background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation3};
  padding: 7px;
  border-radius: 30px;
  color: ${props => props.theme.colors.primary};
  font-size: 0.75em;
`
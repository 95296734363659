import React from 'react'
import styled from 'styled-components'


export default function TemplateEditOptions({options, setOption,option,addEnabled, addSystem,setAddSystemActive}) {
  return (
    <Container>

        {options.map( (item) => {
            const active = item === option
            return (
                <Option onClick={() => setOption(item)} active={active} >{item}</Option>
            )
        })}
        {addEnabled ? <AddButton onClick={() => setAddSystemActive(true)} >+</AddButton> : null}
    </Container>
  )
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    widht: 100%;
    justify-content: center;
    margin: 10px;
    
    `

const Option = styled.div`
    padding: 6px;
    background: ${props => props.active ? props.theme.colors.contrastBlue : props.theme.colors.elevation1};
    color: ${props => props.theme.colors.primary};
    border-radius: 30px;
    height: auto;
    margin: auto 4px;
    z-index: 1000001;
    &:hover {
        cursor: pointer;
    }
`
const AddButton = styled.div`
    color: ${props => props.theme.colors.contrastGreen};
    margin: auto 9px;
    font-size: 1.8em;
    &:hover {
        cursor: pointer;
    }
`

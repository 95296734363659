import React, {useState} from 'react'
import { styled } from 'styled-components'
import { colorScheme } from '../../globalConfig/globalConfig';
import { useHookstate } from '@hookstate/core';
import { c1State } from '../../States/c1State';
import BasicButton from '../SharedComponents/BasicButton';
import { useNavigate } from "react-router-dom";
import { getConfigFromBlob } from '../../services/blobData';
import { customerState } from '../../States/customerState';

export default function GetConfigFromBlob() {
    const colors = colorScheme.night

    const navigate = useNavigate()

    const c1StateRaw = useHookstate(c1State)
    const connectionString = c1StateRaw.connectionString.get()
    const integrationType = useHookstate(customerState).integrationType.get()
    const configStateSetter = useHookstate(customerState).config
    let config = {
    }

    if (integrationType === 'deltareport') {
       config = {
        reports: JSON.parse(JSON.stringify(configStateSetter.reports.get())),
        settings: JSON.parse(JSON.stringify(configStateSetter.settings.get())),
      }
    } else 
    {
      config = {
        selectedFields : JSON.parse(JSON.stringify(configStateSetter.selectedFields.get())),
        settings: JSON.parse(JSON.stringify(configStateSetter.settings.get())),
        employeeFilter: []
    }
    }

    const [blobName, setBlobName] = useState('')
    const [containerName, setContainerName] = useState('')

    const setConnectionString = (value) => {
      c1StateRaw.connectionString.set(value)
    }

    let containerNameDefault = containerName ? containerName : integrationType


    const saveToBlob = async () => {
      const result = await getConfigFromBlob(connectionString,containerNameDefault )
      if (result) {
        if (integrationType === 'deltareport') {
          configStateSetter.reports.set(result.reports)
          configStateSetter.settings.set(result.settings)
          navigate('/deltareport')
        } else {
        Object.keys(result.settings).map(key => {
          try {
            if ('info' in config.settings[key]) {
              result.settings[key].info = config.settings[key].info
            }
          } catch {}
          
          config.settings[key] = result.settings[key]
        })
        Object.keys(result.selectedFields).map(key => {
          config.selectedFields[key] = result.selectedFields[key]
        })
        if ('employeeFilter' in result) { 
          configStateSetter.employeeFilter.set(result.employeeFilter)
        }
        configStateSetter.selectedFields.set(config.selectedFields)
        configStateSetter.settings.set(config.settings)
        navigate('/mapping')
      }
      }
      
    }

  return (
    <Container colors={colors}>
      <CredentialsBox colors={colors}>
        <HeaderDiv colors={colors}>Import config from blob</HeaderDiv>
        <InputLabel colors={colors} >Container name</InputLabel>
        <InputField colors={colors} value={containerNameDefault} onChange={(e) => setContainerName(e.target.value)} placeholder='container name' />
        <InputLabel colors={colors} >Connection string</InputLabel>
        <InputField colors={colors} value={connectionString} onChange={(e) => setConnectionString(e.target.value)} placeholder='Connection string' />
        <BasicButton background="primary" label="Next" width="90px" height="30px" margin="50px auto 15px auto" handleClick={saveToBlob} />
        </CredentialsBox>  
        <BasicButton background="primary" label="Back" width="90px" height="30px" margin="10px auto 15px auto" handleClick={() => navigate('/options')} />
    </Container>
  )
}

const Container = styled.div`
    background: ${props => props.theme.colors.background};
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
`
const CredentialsBox = styled.div`
    width: 28%;
    border-radius: 30px;
    background: ${props => props.theme.colors.elevation1};
    margin: auto;
`
const HeaderDiv = styled.div`
    color: ${props => props.theme.colors.primary};
    text-align: center;
    margin-top: 10px;
    font-size: 1.3em;
`
const InputLabel = styled.label`
  display: block;
    margin: 30px 0px 0 10px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: ${props => props.theme.colors.primary};
`

const InputField = styled.input`
  display: block;
    height: 50px;
    width: 93%;
    background-color: ${props => props.theme.colors.elevation2};
    border-radius: 3px;
    padding: 0 10px;
    margin: 8px 0px 0 10px;
    font-size: 1.1em;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    outline: none;
    border: none;

  &::placeholder {
    color: 'white'
  }

`
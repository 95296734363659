import React from 'react'
import styled from 'styled-components'

export default function AuditFieldOutput({field}) {
    if (field.fieldType === "c1Field")
    {
        return (
            <Container>
                <Header>Output</Header>
                <FieldInfo>
                <FieldHeader>
                    <SmallHeader>C1 field</SmallHeader>
                    {field.c1Field.label}({field.c1Field.value})
                </FieldHeader>
                <FieldHeader>
                    <SmallHeader>Value type</SmallHeader>
                    {field.valueType.label}
                </FieldHeader>
                </FieldInfo>
            </Container>
          )
    }

}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
const Header = styled.div`
    font-size: 1.3em;
    margin: 4px auto;
    color: ${props => props.theme.colors.primary};
    
`
const SmallHeader = styled.div`
    font-size: 0.9em;
    color: ${props => props.theme.colors.contrastBlue};
    
`

const FieldInfo = styled.div`
    display: flex;
    flex-direction: row;
`
const FieldHeader = styled.div`
    font-size: 1.2em;
    margin: 5px;
    display: inline;
    color: ${props => props.theme.colors.primary};
    
`
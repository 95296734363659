import React from 'react'
import { styled } from 'styled-components'
import Condition from './Condition'


export default function ConditionsList({ conditions, fieldIndex, configStateSetter,schemaPage, fieldObj, selectedFields, systemFields,setFieldToEdit,originalFieldObj }) {

  return (
    <ConditionsListContainer>
      {
        conditions.map((condition, index) => {
          const lastCondition = index === (conditions.length - 1) && index !== 0
          return (
            <Condition
              condition={condition}
              fieldIndex={fieldIndex}
              conditionIndex={index}
              configStateSetter={configStateSetter}
              schemaPage={schemaPage}
              conditions={conditions} 
              lastCondition={lastCondition}
              fieldObj={fieldObj}
              selectedFields={selectedFields}
              systemFields={systemFields}
              setFieldToEdit={setFieldToEdit}
              originalFieldObj={originalFieldObj}
              key={`Condition-${fieldIndex}-${index}`}
              />
              
          )
        })
      }
    </ConditionsListContainer>
  )
}

const ConditionsListContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 0.9em;

`
